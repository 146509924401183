import { createSpecies } from '@pixelwelders/tlh-universe-data';
import { nameToDisplayName, displayNameToName } from '@pixelwelders/tlh-universe-util';

import { ADD_SPECIES, CLEAR_SPECIES } from '../actions/speciesActions';
import { DEFAULT_SPECIES, DEFAULT_UID } from '../../constants/DefaultValues';

const initialState = {
  // species-name: species
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_SPECIES: {
      const { payload: map } = action;
      return { ...state, ...map };
    }

    case CLEAR_SPECIES: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
