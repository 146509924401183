import * as PRODUCERS from './producers';
import * as CURRENCIES from './currencies';
import { STRUCT_01 } from './producers';

// 00 (Space Program)
export const UPGRADE_00_00 = 'upgrade00-00';
export const UPGRADE_00_01 = 'upgrade00-01';
export const UPGRADE_00_02 = 'upgrade00-02';
export const UPGRADE_00_03 = 'upgrade00-03';

// 01 (Orbital Platform)
export const UPGRADE_01_00 = 'upgrade01-00';
export const UPGRADE_01_01 = 'upgrade01-01';
export const UPGRADE_01_02 = 'upgrade01-02';
export const UPGRADE_01_03 = 'upgrade01-03';

// 02 (Space Elevator)
export const UPGRADE_02_00 = 'upgrade02-00';
export const UPGRADE_02_01 = 'upgrade02-01';
export const UPGRADE_02_02 = 'upgrade02-02';
export const UPGRADE_02_03 = 'upgrade02-03';

const createIncrementCurrencyBy = (key, num) => (engine) => {
  engine.currency(key).incrementBy(num);
};

const createMultiplyCurrencyBy = (key, num) => (engine) => {
  const currency = engine.currency(key);
  currency.multiplyBy(num);
};

// eslint-disable-next-line no-unused-vars
const createIncrementOutputBy = (key, num) => (engine) => {
  console.log('INCREMENT', key, num);
  const producer = engine.producer(key);
  const output = producer.getOutput('currencies', CURRENCIES.PRIMARY);
  output.productionAmount += (num * output.productionTime / 1000);
};

const createMultiplyOutputBy = (key, num) => (engine) => {
  console.log('MULTIPLY', key, num);
  const producer = engine.producer(key);
  const output = producer.getOutput('currencies', CURRENCIES.PRIMARY);
  output.productionAmount *= num;
};

export const upgradeFuncs = {
  [UPGRADE_00_00]: createMultiplyCurrencyBy(CURRENCIES.PRIMARY_PER_CLICK, 2),
  [UPGRADE_00_01]: createIncrementCurrencyBy(CURRENCIES.PRIMARY_PER_CLICK, 1),
  [UPGRADE_00_02]: createIncrementCurrencyBy(CURRENCIES.PRIMARY_PER_CLICK, 2),
  [UPGRADE_00_03]: createIncrementCurrencyBy(CURRENCIES.PRIMARY_PER_CLICK, 3),

  [UPGRADE_01_00]: createMultiplyOutputBy(STRUCT_01, 1.2),
  [UPGRADE_01_01]: createMultiplyOutputBy(STRUCT_01, 1.5),
  [UPGRADE_01_02]: createMultiplyOutputBy(STRUCT_01, 1.8),
  [UPGRADE_01_03]: createMultiplyOutputBy(STRUCT_01, 2),

  [UPGRADE_02_00]: createMultiplyOutputBy(STRUCT_01, 1.2),
  [UPGRADE_02_01]: createMultiplyOutputBy(STRUCT_01, 1.5),
  [UPGRADE_02_02]: createMultiplyOutputBy(STRUCT_01, 1.8),
  [UPGRADE_02_03]: createMultiplyOutputBy(STRUCT_01, 2),
};

export const upgradeConfigs = [
  {
    key: UPGRADE_00_00,
    meta: {
      name: 'Advanced Hygiene',
      description: `x2 ${CURRENCIES.PRIMARY}s per launch`,
      flavor: `Turns out we can seat them even closer together!`,
    },
    isActive: false,
    cost: {
      [CURRENCIES.PRIMARY]: 100
    }
  },

  {
    key: UPGRADE_00_01,
    meta: {
      name: 'Rockets II',
      description: `+1 ${CURRENCIES.PRIMARY}s per launch`,
      flavor: 'This is getting ridiculous.',
    },
    isActive: false,
    cost: {
      [CURRENCIES.PRIMARY]: 200
    }
  },

  {
    key: UPGRADE_00_02,
    meta: {
      name: 'Rockets III',
      description: `+2 ${CURRENCIES.PRIMARY}s per launch`,
      flavor: 'This is getting ridiculous.',
    },
    isActive: false,
    cost: {
      [CURRENCIES.PRIMARY]: 400
    }
  },

  {
    key: UPGRADE_00_03,
    meta: {
      name: 'Acceleration Chamber',
      description: `+3 ${CURRENCIES.PRIMARY}s per launch`,
      flavor: 'The trick is to fill their lungs with fluid *before* you launch.',
    },
    isActive: false,
    cost: {
      [CURRENCIES.PRIMARY]: 1000
    }
  },

  // --------------------- 01 - ORBITAL PLATFORM ---------------------
  {
    key: UPGRADE_01_00,
    meta: {
      name: 'Hydroponics XXTREME',
      description: `x2 ${CURRENCIES.PRIMARY}s per year`,
      flavor: 'Like regular hydroponics, but XXTREME.',
    },
    isActive: false,
    cost: {
      [CURRENCIES.PRIMARY]: 1000
    }
  },

  {
    key: UPGRADE_01_01,
    meta: {
      name: 'Cold Fusion',
      description: `+1 ${CURRENCIES.PRIMARY}s per year`,
      flavor: 'Amazing what we can accomplish when we stop hauling coal up from the surface.',
    },
    isActive: false,
    cost: {
      [CURRENCIES.PRIMARY]: 2000
    }
  },

  {
    key: UPGRADE_01_02,
    meta: {
      name: 'Weaponized Public Relations',
      description: `+2 ${CURRENCIES.PRIMARY}s per year`,
      flavor: 'Why live on an ailing planet when you can live in orbit around that ailing planet?',
    },
    isActive: false,
    cost: {
      [CURRENCIES.PRIMARY]: 4000
    }
  },

  {
    key: UPGRADE_01_03,
    meta: {
      name: 'Adv. Aphrodisacs',
      description: `+3 ${CURRENCIES.PRIMARY}s per year`,
      flavor: `When an astronaut loves another astronaut, something magical happens.`,
    },
    isActive: false,
    cost: {
      [CURRENCIES.PRIMARY]: 10000
    }
  },

  // --------------------- 02 - SPACE ELEVATOR ---------------------
  {
    key: UPGRADE_02_00,
    meta: {
      name: 'Monofilament II',
      description: `x1.2 ${CURRENCIES.PRIMARY}s per year`,
      flavor: 'Turns out the secret was spider silk.',
    },
    isActive: false,
    cost: {
      [CURRENCIES.PRIMARY]: 2000
    }
  },

  {
    key: UPGRADE_02_01,
    meta: {
      name: 'Monofilament III',
      description: `x1.5 ${CURRENCIES.PRIMARY}s per year`,
      flavor: 'This stuff will seriously mess you up.',
    },
    isActive: false,
    cost: {
      [CURRENCIES.PRIMARY]: 4000
    }
  },

  {
    key: UPGRADE_02_02,
    meta: {
      name: 'Weather Control',
      description: `x1.8 ${CURRENCIES.PRIMARY}s per year`,
      flavor: 'No storms!',
    },
    isActive: false,
    cost: {
      [CURRENCIES.PRIMARY]: 10000
    }
  },

  {
    key: UPGRADE_02_03,
    meta: {
      name: 'Weather Control II',
      description: `x2 ${CURRENCIES.PRIMARY}s per year`,
      flavor: `No wind!`,
    },
    isActive: false,
    cost: {
      [CURRENCIES.PRIMARY]: 20000
    }
  }
];

const upgradesByBuilding = {
  [PRODUCERS.STRUCT_00]: [
    UPGRADE_00_00,
    UPGRADE_00_01,
    UPGRADE_00_02,
    UPGRADE_00_03
  ],

  [PRODUCERS.STRUCT_01]: [
    UPGRADE_01_00,
    UPGRADE_01_01,
    UPGRADE_01_02,
    UPGRADE_01_03
  ],

  [PRODUCERS.STRUCT_02]: [
    UPGRADE_02_00,
    UPGRADE_02_01,
    UPGRADE_02_02,
    UPGRADE_02_03
  ]
};

export const getUpgradesForBuilding = (buildingName) => {
  return upgradesByBuilding[buildingName] || [];
}
