import * as CURRENCIES from './currencies';

export const YEAR_PRODUCER = 'year producer';

export const STRUCT_00 = 'struct00';
export const STRUCT_01 = 'struct01';
export const STRUCT_02 = 'struct02';
export const STRUCT_03 = 'struct03';
export const STRUCT_04 = 'struct04';
export const STRUCT_05 = 'struct05';
export const STRUCT_06 = 'struct06';
export const STRUCT_07 = 'struct07';
export const STRUCT_08 = 'struct08';
export const STRUCT_09 = 'struct09';

export const TECH = 'tech';
export const TECH_00 = 'tech00';
export const TECH_01 = 'tech01';
export const TECH_02 = 'tech02';
export const TECH_03 = 'tech03';
export const TECH_04 = 'tech04';

export const STRUCTURES = [
  STRUCT_00,
  STRUCT_01,
  STRUCT_02,
  STRUCT_03,
  STRUCT_04,
  STRUCT_05,
  STRUCT_06,
  STRUCT_07,
  STRUCT_08,
  STRUCT_09
];

export const FINAL_STRUCT = STRUCT_09;

export const TECHS = [
  TECH_00,
  TECH_01,
  TECH_02,
  TECH_03,
  TECH_04
];

const producerConfigs = {
  [YEAR_PRODUCER]: {
    key: YEAR_PRODUCER,
    count: 1,
    baseCost: {
      currency: CURRENCIES.PRIMARY,
      amount: 0
    },
    costCoefficient: 1,
    outputs: {
      currencies: {
        [CURRENCIES.YEARS]: {
          productionTime: 1000,
          productionAmount: 1
        }
      }
    },
    processingEnabled: true,
    unlock: { isLocked: false }
  },

  [STRUCT_00]: {
    key: STRUCT_00,
    count: 1,
    maxCount: 1,
    meta: {
      name: 'Space Program',
      type: 'click',
      flavor: `It's not much, but at least it gets us into orbit.`,
      image: 'space-program',
      unlock: { isLocked: false },
      techProgressRequired: 0
    },
    baseCost: {
      currency: CURRENCIES.PRIMARY,
      amount: 20
    },
    costCoefficient: 1.0,
    outputs: {
      currencies: {
        [CURRENCIES.PRIMARY]: {
          productionTime: 30000,
          productionAmount: 0
        }
      }
    },
    processingEnabled: true
  },

  [STRUCT_01]: {
    key: STRUCT_01,
    meta: {
      name: 'Orbital Platforms',
      flavor: `Turns out we reproduce much more rapidly in zero gravity.`,
      image: 'orbital-platform',
      unlock: {
        currency: CURRENCIES.SECONDARY,
        amount: 1,
        isLocked: false
      },
      techProgressRequired: 0
    },
    count: 0,
    baseCost: {
      currency: CURRENCIES.PRIMARY,
      amount: 15
    },
    costCoefficient: 1.1,
    outputs: {
      currencies: {
        [CURRENCIES.PRIMARY]: {
          productionTime: 1000,
          productionAmount: 1
        }
      }
    },
    processingEnabled: true
  },

  [STRUCT_02]: {
    key: STRUCT_02,
    meta: {
      name: 'Space Elevators',
      flavor: `Perfectly reliable, and almost certainly unrelated to the tsunamis that seem to be happening nonstop these days.`,
      image: 'space-elevator',
      unlock: {
        currency: CURRENCIES.SECONDARY,
        amount: 1,
        isLocked: true
      },
      techProgressRequired: 1
    },
    count: 0,
    baseCost: {
      currency: CURRENCIES.PRIMARY,
      amount: 100
    },
    costCoefficient: 1.1,
    outputs: {
      currencies: {
        [CURRENCIES.PRIMARY]: {
          productionTime: 1000,
          productionAmount: 5
        }
      }
    },
    processingEnabled: true
  },

  // -----------------------------------------------------------------------------------------

  [STRUCT_03]: {
    key: STRUCT_03,
    meta: {
      name: 'Moon Colonies',
      flavor: `It's barren, but it's home.`,
      image: 'moon-colony',
      unlock: {
        currency: CURRENCIES.SECONDARY,
        amount: 1,
        isLocked: true
      },
      techProgressRequired: 2
    },
    count: 0,
    baseCost: {
      currency: CURRENCIES.PRIMARY,
      amount: 600
    },
    costCoefficient: 1.1,
    outputs: {
      currencies: {
        [CURRENCIES.PRIMARY]: {
          productionTime: 1000,
          productionAmount: 12
        }
      }
    },
    processingEnabled: true
  },

  [STRUCT_04]: {
    key: STRUCT_04,
    meta: {
      name: 'Asteroid Colonies',
      flavor: `So many resources, so little time.`,
      image: 'asteroid-colony',
      unlock: {
        currency: CURRENCIES.SECONDARY,
        amount: 1,
        isLocked: true
      },
      techProgressRequired: 3
    },
    count: 0,
    baseCost: {
      currency: CURRENCIES.PRIMARY,
      amount: 4000
    },
    costCoefficient: 1.1,
    outputs: {
      currencies: {
        [CURRENCIES.PRIMARY]: {
          productionTime: 1000,
          productionAmount: 90
        }
      }
    },
    processingEnabled: true
  },

  [STRUCT_05]: {
    key: STRUCT_05,
    meta: {
      name: 'Terrestrial Colonies - Planet #1',
      flavor: `Planets are like children: they each present their own unique challenges, and they sometimes try to kill you.`,
      image: 'terrestrial-colony',
      unlock: {
        currency: CURRENCIES.SECONDARY,
        amount: 1,
        isLocked: true
      },
      techProgressRequired: 4
    },
    count: 0,
    baseCost: {
      currency: CURRENCIES.PRIMARY,
      amount: 20000
    },
    costCoefficient: 1.1,
    outputs: {
      currencies: {
        [CURRENCIES.PRIMARY]: {
          productionTime: 1000,
          productionAmount: 300
        }
      }
    },
    processingEnabled: true
  },

  [STRUCT_06]: {
    key: STRUCT_06,
    meta: {
      name: 'Terrestrial Colonies - Planet #2',
      flavor: `Sure it's a little hot, but it's better than being too cold.'.`,
      image: 'terrestrial-colony2',
      unlock: {
        currency: CURRENCIES.SECONDARY,
        amount: 1,
        isLocked: true
      },
      techProgressRequired: 5
    },
    count: 0,
    baseCost: {
      currency: CURRENCIES.PRIMARY,
      amount: 200000
    },
    costCoefficient: 1.1,
    outputs: {
      currencies: {
        [CURRENCIES.PRIMARY]: {
          productionTime: 1000,
          productionAmount: 1000
        }
      }
    },
    processingEnabled: true
  },

  [STRUCT_07]: {
    key: STRUCT_07,
    meta: {
      name: 'Floating Colonies - Gas Giant #1',
      flavor: `Floating cities take more planning than the ones on the ground.`,
      image: 'gas-base',
      unlock: {
        currency: CURRENCIES.SECONDARY,
        amount: 1,
        isLocked: true
      },
      techProgressRequired: 6
    },
    count: 0,
    baseCost: {
      currency: CURRENCIES.PRIMARY,
      amount: 3000000
    },
    costCoefficient: 1.1,
    outputs: {
      currencies: {
        [CURRENCIES.PRIMARY]: {
          productionTime: 1000,
          productionAmount: 2000
        }
      }
    },
    processingEnabled: true
  },

  [STRUCT_08]: {
    key: STRUCT_08,
    meta: {
      name: 'Floating Colonies - Gas Giant #2',
      flavor: `More gravity, more killer lightning storms, more reward.`,
      image: 'terraformer',
      unlock: {
        currency: CURRENCIES.SECONDARY,
        amount: 1,
        isLocked: true
      },
      techProgressRequired: 7
    },
    count: 0,
    baseCost: {
      currency: CURRENCIES.PRIMARY,
      amount: 70000000
    },
    costCoefficient: 1.1,
    outputs: {
      currencies: {
        [CURRENCIES.PRIMARY]: {
          productionTime: 1000,
          productionAmount: 4000
        }
      }
    },
    processingEnabled: true
  },

  [STRUCT_09]: {
    key: STRUCT_09,
    meta: {
      name: 'Dyson Sphere',
      flavor: `K2 civilization? Yes please.`,
      image: 'dyson-sphere',
      unlock: {
        currency: CURRENCIES.SECONDARY,
        amount: 1,
        isLocked: true
      },
      techProgressRequired: 8
    },
    count: 0,
    baseCost: {
      currency: CURRENCIES.PRIMARY,
      // amount: 300000000
      amount: 30
    },
    costCoefficient: 1.1,
    outputs: {
      currencies: {
        [CURRENCIES.PRIMARY]: {
          productionTime: 1000,
          productionAmount: 10000
        }
      }
    },
    processingEnabled: true
  }
};

const baseTechTime = 500;
export const techProducer = {
  key: TECH,
  count: 1,
  maxCount: 1,
  baseCost: { currency: CURRENCIES.PRIMARY, amount: 0 },
  costCoefficient: 1,
  outputs: {
    currencies: {
      [CURRENCIES.SECONDARY]: {
        productionTime: baseTechTime,
        productionAmount: 1
      },
      [CURRENCIES.TECH_PROGRESS]: {
        productionTime: baseTechTime,
        productionAmount: 1
      },
      [CURRENCIES.PRIMARY_PER_CLICK]: {
        productionTime: baseTechTime,
        productionAmount: 1
      }
    }
  },
  processingEnabled: false
};

export const getConfigForProducer = (key) => {
  return producerConfigs[key];
};

export const allTechs = [
  {
    name: 'Monofilament',
    flavor: '35,000 kilometers of impossibly tough supercable, zero room for mistakes.',
    description: 'This could enable us to build a space elevator!',
    image: 'monofilament',
    time: 10
  },
  {
    name: 'Artificial Intelligence',
    flavor: 'The trick is persuading it not to kill everyone the second you switch it on.',
    description: 'This could enable us to build moon colonies! Or more accurately... to keep them alive.',
    image: 'ai',
    time: 60
  },
  {
    name: 'Railgun Railway',
    flavor: 'Why use rockets when you can use railguns?',
    description: 'This could finally open up the asteroid frontier.',
    image: 'railgun',
    time: 5 * 60
  },
  {
    name: 'Advanced Propulsion',
    flavor: 'Only suckers carry their own fuel with them.',
    description: `We've got our eye on our nearest terrestrial neighbor.`,
    image: 'ion-drive',
    time: 30 * 60
  },
  {
    name: 'Terraforming',
    flavor: 'We can literally move mountains, but it takes awhile.',
    description: 'Cold terrestrial planets are one thing, but what about hot ones?',
    image: 'terraforming',
    time: 60 * 60
  },
  {
    name: 'Nanotech',
    flavor: 'The apocalypse is now one code bug away.',
    description: 'Finally, we can build from the gases of our nearest gas giant!',
    image: 'nanotech',
    time: 60 * 60
  },
  {
    name: 'Artificial Gravity',
    flavor: 'They said it was impossible! It is, actually, but we did it anyway.',
    description: 'Now we can colonize our greatest and most terrifying gas giant!',
    image: 'antigrav',
    time: 60 * 60
  },
  {
    name: 'Mega-Architecture',
    flavor: 'Space stations are one thing. Solar panels the size of planets are quite another.',
    description: 'If anything could enable us to harness the power of our star, this would be it.',
    image: 'mega-architecture',
    time: 60 * 60
  }
];

// Fisher-Yates
function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

let isShuffled = false;
export const getResearchMessage = ({ leader = {}, index }) => {
  const { title = 'your worship' } = leader;
  console.log('INDEX', index);

  let researchMessages = [
    `This could be the one that kills us all, ${title}.`,
    `Have you considered leaving this one alone, ${title}?`,
    `So much to do, ${title}! Are you sure this one should be at the top of the list?`,
    `I'll be honest: I'm terrified of this one, ${title}.`,
    `But what if we set off a chain reaction? Have you thought of that, ${title}?`,
    `Many will tell you this is dangerous, ${title}. They are absolutely correct.`,
    `I'm not sure how we'll use this one without destroying life as we know it, ${title}.`,
    `Quite honestly, this one should be illegal, ${title}.`,
    `Good thing you hold ultimate power, ${title}, because no government would let you get away with this one.`,
    `This one might be a bit painful, ${title}.`,
    `I believe several governments tried this at one time or another, ${title}. There were no survivors.`
  ];

  if (!isShuffled) {
    shuffle(researchMessages);
    isShuffled = true;
  }

  return researchMessages[index];
};
