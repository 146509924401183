import { Modal } from 'react-bootstrap';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FiX as CloseIcon } from 'react-icons/fi';

import { getModal } from '../../store/selectors';
import { clearModal } from '../../store/actions/modalActions';
import * as MODALS from '../../constants/modals';
import Button from 'react-bootstrap/Button';

const NewsModal = ({ modal, onClearModal }) => {
  console.log('modal', modal);
  const { type, content } = modal;

  const show = type === MODALS.NEWS;

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>{content?.title}</Modal.Title>
        <CloseIcon className="close-btn" size={30} onClick={() => onClearModal()} />
      </Modal.Header>
      <Modal.Body>
        {content?.newsLink && (
          <p>
            As reported seconds ago on <img alt="twitter" className="img-icon-inline" src="/images/twitter-50.png" width={20} height={20} />
            <a href={content?.newsLink} target="_blank" rel="noopener noreferrer">Galaxy News Today</a>:
          </p>
        )}
        <p>{content?.body}</p>
      </Modal.Body>
      <Modal.Footer>
        {content?.posButton?.title && (
          <Button onClick={content.posButton.onClick || onClearModal}>{content.posButton.title}</Button>
        )}
        {content?.negButton?.title && (
          <Button onClick={content.negButton.onClick}>{content.negButton.title}</Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

NewsModal.propTypes = {
  onClearModal: PropTypes.func.isRequired
};

NewsModal.defaultProps = {};

const mapStateToProps = state => ({
  modal: getModal(state),
});

const dispatchMap = {
  onClearModal: clearModal
};

export default connect(
  mapStateToProps,
  dispatchMap
)(NewsModal);
