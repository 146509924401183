import React from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Redirect } from 'react-router-dom';

import { getAuthUser } from '../../store/selectors/authSelectors';
import { setCurrentScreen } from '../../store/actions/serverActions';
import * as ROUTES from '../../constants/routes';

const RestrictedRoute = ({
  requirement = authUser => true,
  redirect = ROUTES.SIGN_IN,
  firebase,
  authUser, onSetCurrentScreen,
  ...routeParams
}) => {

  onSetCurrentScreen(routeParams.location.pathname);

  return requirement(authUser)
    ? <Route {...routeParams} />
    : <Redirect to={redirect} />
};

export default compose(
  connect(
    state => ({
      authUser: getAuthUser(state)
    }),
    { onSetCurrentScreen: setCurrentScreen }
  )
)(RestrictedRoute);
