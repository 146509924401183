import { getFirebase } from '../selectors';

export const AUTH_SET_USER = 'auth.setUser';
export const authSetUser = (authUser) => (dispatch, getState) => {
  const firebase = getFirebase(getState());
  firebase.analytics().setUserId(authUser ? authUser.uid : null);

  dispatch({
    type: AUTH_SET_USER,
    payload: { authUser }
  });
};

export const AUTH_CLEAR_USER = 'auth.clearUser';
export const authClearUser = () => ({ type: AUTH_CLEAR_USER });

export const AUTH_SET_IS_CREATING_PLAYER = 'auth.setCreatingPlayer';
export const authSetIsCreatingPlayer = isCreatingPlayer => ({
  type: AUTH_SET_IS_CREATING_PLAYER,
  payload: { isCreatingPlayer }
});

export const AUTH_SET_LAST_ATTEMPT_FAILED = 'auth.setLastAttemptFailed';
export const authSetLastAttemptFailed = (didFail) => ({
  type: AUTH_SET_LAST_ATTEMPT_FAILED,
  payload: didFail
});
