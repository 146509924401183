import React from 'react';
import { connect } from 'react-redux';

import * as CURRENCIES from '../../constants/currencies';
import { PriCurrencyIcon, SecCurrencyIcon } from '../icons';
import { activateUpgrade } from '../../store/actions/spaceRaceActions';

import './upgradeDisplay.scss';
import { getEngine, getPrimaryCurrency, getSecondaryCurrency } from '../../store/selectors/spaceRaceSelectors';
import { formatDictionaryNumber } from 'continuum-engine';

const UpgradeDisplay = ({ upgrade, engine, primaryCurrency, secondaryCurrency, onActivateUpgrade }) => {
  const {
    key,
    meta: { name, description },
    isActive,
    cost: { [CURRENCIES.PRIMARY]: primaryCost = 0, [CURRENCIES.SECONDARY]: secondaryCost = 0 }
  } = upgrade;

  const canBuyUpgrade = !isActive && (primaryCurrency >= primaryCost) && (secondaryCurrency >= secondaryCost);

  return (
    <div className="upgrade-display">
      {/*<div className="item-image" onClick={buy} />*/}
      <div className="upgrade-item-info">
        <span className="upgrade-item-title">{name}</span>
        <span className="upgrade-item-description">{description}</span>
        <button
          type="button"
          className="action-button sub-button"
          disabled={!canBuyUpgrade}
          onClick={() => onActivateUpgrade(key)}
        >
          {!isActive
            ? (
              <>
                BUILD{' '}
                {primaryCost > 0 && (
                  <>
                    <PriCurrencyIcon />x{formatDictionaryNumber(primaryCost, 0)}
                  </>
                )}
                {secondaryCost > 0 && (
                  <>
                    <SecCurrencyIcon />x{formatDictionaryNumber(secondaryCost, 0)}
                  </>
                )}
              </>
            )
            : <>OWNED</>
          }
        </button>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    engine: getEngine(state),
    primaryCurrency: getPrimaryCurrency(state),
    secondaryCurrency: getSecondaryCurrency(state),
  }),
  {
    onActivateUpgrade: activateUpgrade
  }
)(UpgradeDisplay);
