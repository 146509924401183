import React from 'react';

import './logo.scss';

const Logo = ({ variant = '' } = {}) => {
  return (
    <div className={`registry-logo ${variant}`}>
      <div className="squares">
        <div className="background square"></div>
        <div className="foreground square"></div>
      </div>
      <div className="logo-text">
        <span className="the">The</span>
        <span>Galactic</span>
        <span>Registry</span>
      </div>
    </div>
  );
};

Logo.propTypes = {};
Logo.defaultProps = {};

export default Logo;
