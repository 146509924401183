export default (error = {}) => {
  const { code = '' } = error;
  const finalError = {
    'auth/too-many-requests': 'Whoa, slow down there! Give it a few minutes to let the interplanetary relays cool off.',
    'auth/user-not-found': 'Our vast galactic databases show no record of this user. Are you sure the address is correct?',
    'auth/wrong-password': `That password seems to be incorrect. Any other ideas?`,
    'auth/weak-password': 'Please enter at least 6 characters for your password.',
    'auth/email-already-in-use': 'Looks like you already have a species! Use another email address, or log in at the bottom of the page.',
    'auth/requires-recent-login': `If you'd like to do this, please log in again (so we can make sure it's you).`
  }[code] || error.toString();

  return finalError;
}
