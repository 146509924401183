import { MessageTypes, createMessage, clamp } from '@pixelwelders/tlh-universe-util';

import { getEnv } from '../selectors';
import * as ENVIRONMENTS from '../../constants/environments';
import * as MODALS from '../../constants/modals';
import { notifyEnv } from './envActions';
import { setModal } from './modalActions';

export const sendCard = (card) => (dispatch, getState) => {
  const state = getState();
  const { name } = getEnv(state);

  if (name === ENVIRONMENTS.NATIVE_WEBVIEW) {
    dispatch(
      notifyEnv({ type: MessageTypes.SET_CARD, payload: card })
    );
  } else {
    dispatch(
      setModal({ type: MODALS.ID, content: card })
    );
  }
};
