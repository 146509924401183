
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var THREE = require("three");
var Lighting_1 = require("./Lighting");
var Ship_1 = require("./Ship");
var Skybox_1 = require("./Skybox");
var CustomControls =  require('../../third-party/three/controls/Controls').OrbitControls;
var VesselViewer = /** @class */ (function (_super) {
    __extends(VesselViewer, _super);
    function VesselViewer(props) {
        var _this = _super.call(this, props) || this;
        _this.onAnimationFrame = function () {
            requestAnimationFrame(_this.onAnimationFrame);
            if (_this.state.idle) {
                var euler = _this.state.viewEuler.clone();
                euler.y -= 0.0025;
                _this.setState({
                    viewEuler: euler,
                });
            }
            _this._renderer.render(_this._scene, _this._camera);
        };
        _this.state = {
            viewEuler: new THREE.Euler(0, 0, 0),
            viewDist: 3,
            idle: true
        };
        return _this;
    }
    VesselViewer.prototype.componentDidMount = function () {
        var _this = this;
        var aspect = this.props.renderWidth / this.props.renderHeight;
        this._scene = new THREE.Scene();
        this._camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 1000);
        this._camera.position.copy(new THREE.Vector3(0, 1.3, this.state.viewDist));
        this._camera.lookAt(new THREE.Vector3(0, 0, 0));
        this._renderer = new THREE.WebGLRenderer();
        this._renderer.setSize(this.props.renderWidth, this.props.renderHeight);
        this._ref.appendChild(this._renderer.domElement);
        this._gimbal = new THREE.Group();
        this._gimbal.add(this._camera);
        this._scene.add(this._gimbal);
        // Lighting
        var themes = Object.keys(Lighting_1.THEME);
        var theme = Math.min(this.props.color || -1, themes.length - 1);
        if (theme < 0)
            theme = Math.floor(Math.random() * themes.length);
        var lighting = new Lighting_1.default();
        this._scene.add(lighting.StandardPlot(theme));
        // Controls
        const controls = new CustomControls(this._camera, this._renderer.domElement);
        controls.enabled = true;
        controls.enableZoom = true; // false
        controls.minDistance = 1; // outDistance
        controls.maxDistance = 3;
        controls.enableDamping = true;
        controls.enablePan = true;
        controls.enableVerticalPan = false;
        controls.screenSpacePanning = true;
        controls.minPolarAngle = Math.PI * 0.4;
        controls.maxPolarAngle = Math.PI * 0.6;
        controls.enableKeys = true;
        controls.addEventListener('change', VesselViewer.prototype.render);
        controls.addEventListener('start', (event => {
            _this.setState({
                idle: false
            });
            console.log('start', event);
        }));
        controls.addEventListener('end', event => console.log('end', event));

        // Assets
        var environments = Object.keys(Skybox_1.SKYBOX_TYPE);
        var environment = Math.min(this.props.color || -1, environments.length - 1);
        if (environment < 0)
            environment = Math.floor(Math.random() * environments.length);
        var skybox = new Skybox_1.Skybox().LoadTexture(environment);
        this._scene.background = skybox;
        var ship = new Ship_1.Ship();
        ship.LoadShip(Ship_1.SHIP_TYPE.Transcruiser)
            .then(function (obj) {
            for (var _i = 0, _a = obj.children; _i < _a.length; _i++) {
                var child = _a[_i];
                var material = child.material;
                if (material) {
                    console.log(material);
                    material.setValues({
                        // @ts-ignore
                        envMap: skybox,
                        envMapIntensity: 6,
                        roughness: 0.35,
                        metalness: 0.5
                    });
                }
            }
            obj.position.set(0, -0.8, 0);
            _this._scene.add(obj);
        });
        // Go!
        requestAnimationFrame(this.onAnimationFrame);
    };
    VesselViewer.prototype.componentWillUnmount = function () {
        // this._cameraControls.UnhookEvents();
    };
    VesselViewer.prototype.shouldComponentUpdate = function (nextProps, nextState, nextContext) {
        if (nextState.viewDist !== this.state.viewDist) {
            this._camera.position.copy(new THREE.Vector3(0, 0, this.state.viewDist));
        }
        if (!nextState.viewEuler.equals(this.state.viewEuler)) {
            this._gimbal.setRotationFromEuler(nextState.viewEuler);
        }
        return true;
    };
    VesselViewer.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { ref: function (ref) { return _this._ref = ref; } });
    };
    return VesselViewer;
}(React.Component));
exports.VesselViewer = VesselViewer;
