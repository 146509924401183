export const REGISTRY_ROOT = '/registry';
export const REGISTRY = `${REGISTRY_ROOT}/:species`
// export const SPECIES_ROOT = '/species';
// export const SPECIES = `${SPECIES_ROOT}/:species`;
export const INTRO = '/';
export const SIGN_UP = '/scan';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const CLAIM = '/claim';
export const PRIVACY = '/privacy';
export const STORE = '/store';
export const SPACE_RACE = '/space-race';

export const START = INTRO;
