import React from 'react';
import { connect } from 'react-redux';
import { FaPlus as AddIcon } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import * as ROUTES from '../../constants/routes';
import * as MODALS from '../../constants/modals';

import { getPlayerSpeciesArray } from '../../store/selectors/speciesSelectors';
import { setModal } from '../../store/actions/modalActions';
import * as MODES from '../../constants/modes';
import MultiModeBackground from '../common/MultiModeBackground';

import './home.scss';

const Home = ({ species, onSetModal }) => {
  return (
    <MultiModeBackground
      mode={MODES.SUBMITTING}
      className="padded-page species-page-shell common-page home-page-main"
      display={true}
    >
      <h1>My Species</h1>
      <ul className="species-list">
        {species.map((s, index) => {
          console.log('SPECIES', species);
          const { name, displayName } = s;
          return (
            <li
              className="list-item"
              key={index}
            >
              <Link to={`${ROUTES.REGISTRY_ROOT}/${name}`}>{displayName}</Link>
            </li>
          );
        })}
      </ul>
      <Link
        id="add-species"
        className="button-small light"
        to={ROUTES.SIGN_UP}
        // onClick={() => onSetModal({ type: MODALS.NEW_SPECIES, content: {} })}
      >
        <AddIcon />
      </Link>
    </MultiModeBackground>
  );
}

export default connect(
  state => ({
    species: getPlayerSpeciesArray(state)
  }),
  {
    onSetModal: setModal
  }
)(Home);
