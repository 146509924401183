import React from 'react';
import { connect } from 'react-redux';
import Welcome from './Welcome';
import Endgame from './Endgame';
import { srGetModal } from '../../store/selectors/spaceRaceSelectors';
import * as MODALS from '../../constants/modals';

const ModalManager = ({ modal }) => {
  const { type } = modal;

  return (
   <>
     <Welcome show={type === MODALS.WELCOME} />
     <Endgame show={type === MODALS.ENDGAME} />
   </>
  );
};

export default connect(
  state => ({
    modal: srGetModal(state)
  }),
  {}
)(ModalManager);
