import React from 'react';
import { connect } from 'react-redux';

import './storyTab.scss';
import { getPlayerInfo } from '../../store/selectors/spaceRaceSelectors';

const StoryTab = ({ playerInfo }) => {
  const { leader: { title = 'your worship' } = {} } = playerInfo;
  return (
    <div className="story-tab">
      <p className="story-intro">The bards are singing of your exploits, {title}!</p>
    </div>
  );
};

export default connect(
  state => ({
    playerInfo: getPlayerInfo(state)
  }),
  {}
)(StoryTab);
