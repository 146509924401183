import React from 'react';
import { connect } from 'react-redux';
import {
  FaCaretDown as DownIcon,
  FaCaretUp as UpIcon
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import { formatDictionaryNumber } from 'continuum-engine';

import { PriCurrencyIcon, SecCurrencyIcon } from '../icons';
import { buyProducer, unlockProducer } from '../../store/actions/spaceRaceActions';
import {
  getEngine,
  getPrimaryCurrency,
  getSecondaryCurrency,
  getStructures, getTechProgress, getUpgrades
} from '../../store/selectors/spaceRaceSelectors';
import * as CURRENCIES from '../../constants/currencies';

import './buildingDisplay.scss';

const BuildingDisplay = ({
  engine, type, primaryCurrency, secondaryCurrency, onUnlockProducer, onBuyProducer,
  onShowDetails, onSetIsOpen, isOpen, upgrades, techProgress
}) => {
  const producer = engine.producer(type);
  const buyCost = producer.calculateCost(1);
  const { meta: { name, type: producerType, flavor, unlock, image, techProgressRequired } = {} } = producer;
  const { amount: unlockPrice, isLocked } = unlock;
  const { productionTime, productionAmount } = producer.outputs.currencies[producer.baseCost.currency];
  const rate = productionAmount / (productionTime / 1000);
  const canBeUnlocked = isLocked && secondaryCurrency >= unlock.amount;
  const isMaxed = producer.count >= producer.maxCount;
  const currency = engine.currency(producer.baseCost.currency); // TODO
  const cost = producer.calculateCost(1);
  const canBePurchased = currency.value >= cost.price;

  return (
    <div className={`item-display${isLocked ? ' is-locked' : ''}`} onClick={event => {
      // Only allow upgrade events out.
      if (!event.target || !event.target.className || !event.target.className.includes) {
        return event.stopPropagation();
      }

      if (!event.target.className.includes('upgrade-button')) {
        return event.stopPropagation();
      }

      onSetIsOpen(!isOpen);
    }}>
      <motion.div
        className="item-image"
        style={{ backgroundImage: `url("/images/EftSS/structures/${image}-thumb.jpg")` }}
        onClick={() => onShowDetails(producer)}
        initial={{ brightness: 1 }}
        animate={{ brightness: 0 }}
      >
        {producer.count > 0 && (
          <div className="item-count">
            <span className="item-count-x">x</span>
            <span className="item-count-num">{producer.count}</span>
          </div>
        )}
      </motion.div>
      <div className="item-info">
        {isLocked && (<div className="locked-overlay" />)}
        <p className="item-title">
          <span className="item-title-name">{name}</span>
        </p>
        <p className="item-rate">
          {
            producerType === 'click'
              ? (<><PriCurrencyIcon />x{engine.currency(CURRENCIES.PRIMARY_PER_CLICK).value}/click</>)
              : (<><PriCurrencyIcon />x{formatDictionaryNumber(rate, 0)}/year</>)
              // : (<>Produces <PriCurrencyIcon />{formatDictionaryNumber(num, 0)} every {year > 1 ? `${year} years` : 'year'}</>)
          }
        </p>
        <p className="item-flavor muted">
          {flavor}
        </p>
        <div className="item-actions">
          {
            isLocked
              ? (
                <>
                  {techProgress >= techProgressRequired && (
                    <button
                      type="button"
                      disabled={!canBeUnlocked}
                      className="action-button unlock-button"
                      onClick={() => onUnlockProducer(producer.key)}
                    >
                      UNLOCK <SecCurrencyIcon />x{unlockPrice}
                    </button>
                  )}
                </>
              )
              : (
                <>
                  {!isMaxed && (
                    <button
                      type="button" className="action-button" disabled={!canBePurchased}
                      onClick={() => onBuyProducer(producer.key)}
                    >
                      BUILD <PriCurrencyIcon />x{formatDictionaryNumber(buyCost.price, 0)}
                    </button>
                  )}

                  {producer.count > 0 && (
                    <button
                      type="button" className="action-button upgrade-button"
                    >
                      UPGRADE {isOpen ? <UpIcon /> : <DownIcon />}
                    </button>
                  )}
                </>
              )
          }
        </div>
      </div>
    </div>
  );
};

BuildingDisplay.defaultProps = {
  isOpen: false
};

export default connect(
  state => ({
    techProgress: getTechProgress(state),
    engine: getEngine(state),
    primaryCurrency: getPrimaryCurrency(state),
    secondaryCurrency: getSecondaryCurrency(state),
    structures: getStructures(state),
    upgrades: getUpgrades(state)
  }),
  {
    onUnlockProducer: unlockProducer,
    onBuyProducer: buyProducer
  }
)(BuildingDisplay);
