import { SET_FIREBASE } from '../actions/firebaseActions';

// This is an actual reference to the firebase object.
const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FIREBASE: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
