import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';

import Error from '../Session/Error';
import beautifyError from '../../util/beautifyError';
import { getAuthUser } from '../../store/selectors/authSelectors';
import { doEmailUpdate } from '../../store/actions/serverActions';

const EmailChangeForm = ({ authUser, onDoEmailUpdate }) => {
  const [currentEmail, setCurrentEmail] = useState(authUser.email);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const isValid = !!email;

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      setError(null);
      setMessage('');
      await onDoEmailUpdate(email);
      setCurrentEmail(email);
      setMessage('Email updated!');
    } catch (error) {
      setError(error);
    }
  }

  return (
    <Form onSubmit={onSubmit} className="email-change-form">
      <Form.Group>
        <Form.Label>Current Email</Form.Label>
        <input
          name="currentEmail" type="email" value={currentEmail} readOnly
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>New Email</Form.Label>
        <input
          name="email" type="email" placeholder="Enter email"
          onChange={({ target: { name, value } }) => {
            setEmail(value);
          }}
        />
      </Form.Group>

      <Button disabled={!isValid} type="submit" className="button-small light btn btn-primary">
        Change My Email
      </Button>

      {error && <Error error={beautifyError(error)} />}
      {message && <p>{message}</p>}
    </Form>
  );
};

export default compose(
  connect(
    state => ({
      authUser: getAuthUser(state)
    }),
    { onDoEmailUpdate: doEmailUpdate }
  )
)(EmailChangeForm);
