import { createMessage } from '@pixelwelders/tlh-universe-util';

import * as ENVIRONMENTS from '../../constants/environments';
import { getEnv } from '../selectors';

export const SET_ENV = 'env.set';
export const setEnv = () => (dispatch, getState) => {
  const payload = {};
  if (window.ReactNativeWebView) {
    payload.name = ENVIRONMENTS.NATIVE_WEBVIEW;
  } else {
    payload.name = ENVIRONMENTS.WEB;
  }

  dispatch({ type: SET_ENV, payload });
};

export const notifyEnv = ({ type, payload }) => (dispatch, getState) => {
  const state = getState();
  const { name } = getEnv(state);

  if (name === ENVIRONMENTS.NATIVE_WEBVIEW) {
    const message = createMessage({ type, payload });
    window.ReactNativeWebView.postMessage(message);
  }
};
