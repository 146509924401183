import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { getClaimItem } from '../../store/actions/serverActions';
import { claimItem } from '../../store/actions/claimActions';
import Image from '../common/Image';

import './claim.scss';
import DoubleButton from '../common/DoubleButton';
import * as MODES from '../../constants/modes';

// const getClaimId = ({ params: { claimId } = {} } = {}) => claimId;
const imagePath = '/images/inventory/';

const ClaimItem = ({ item, history, onClaimItem }) => {
  const { displayName, description } = item;

  return (
    <>
      <h1>{displayName}</h1>
      <Image image={item.image} path={imagePath} className="claim-image" />
      <p className="claim-description">{description}</p>
      <DoubleButton
        posLabel="Claim this resource"
        posClick={onClaimItem}
      />
    </>
  );
};

ClaimItem.propTypes = {
  item: PropTypes.object.isRequired
}
ClaimItem.defaultProps = {};

class Claim extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      name: '',
      mode: MODES.WAITING
    };
  }

  // async componentDidMount() {
  //   const { match, onGetClaimItem } = this.props;
  //   const claimId = getClaimId(match);
  //
  //   const item = await onGetClaimItem({ claimId });
  //   this.setState({ item });
  // }

  render() {
    const { item, mode, name } = this.state;
    const { onClaimItem, history, location } = this.props;

    // TODO If you're signed in, we can go ahead and claim.
    return (
      <div className="padded-page claim">
        <h1>Claim Resource</h1>
        {mode === MODES.WAITING && (
          <>
          <form>
            <p>Enter the name of the resource you wish to claim.</p>
            <input value={name} onChange={({ target: { value } }) => this.setState({ name: value })}/>
            <button
              onClick={event => {
                event.preventDefault();
                console.log('searching');
              }}
            >
              Go
            </button>
          </form>
          </>
        )}

        {mode === MODES.SUBMITTING && (
          <p>Submitting</p>
        )}

        {mode === MODES.REVIEWING && (
          <ClaimItem
            item={item}
            onClaimItem={() => onClaimItem({ item, history, location })}
          />
        )}
      </div>
    );
  }
};

Claim.propTypes = {
  // withRouter
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,

  // connect
  onGetClaimItem: PropTypes.func.isRequired,
  onClaimItem: PropTypes.func.isRequired,
};
Claim.defaultProps = {};

export default compose(
  withRouter,
  connect(
    state => ({}),
    {
      onGetClaimItem: getClaimItem,
      onClaimItem: claimItem,
    }
  )
)(Claim);
