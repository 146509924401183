import { SET_ENV } from '../actions/envActions';
import * as ENVIRONMENTS from '../../constants/environments';

const initialState = {
  name: ENVIRONMENTS.NOT_SET
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ENV: {
      return { ...state, ...action.payload };
    }

    default: {
      return state;
    }
  }
}
