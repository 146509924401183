import React, { useState } from 'react';
import { connect } from 'react-redux';
import Measure from 'react-measure';

import SpeciesList3D from './SpeciesList3D';
import { motion } from 'framer-motion';

import './registry.scss'

const Registry = (props) => {
  const [{ width, height }, setDimensions] = useState({ width: 0, height: 0 });
  return (
    <div className="registry full-page">
      <Measure
        bounds
        onResize={contentRect => setDimensions(contentRect.bounds)}
      >
        {({ measureRef }) => (
          <motion.div
            ref={measureRef}
            style={{ flex: 1, overflow: 'hidden', display: 'flex' }}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
          >
            <SpeciesList3D width={width} height={height} />
          </motion.div>
        )}
      </Measure>
    </div>
  );
}

Registry.whyDidYouRender = true;
Registry.propTypes = {};
Registry.defaultProps = {};

const mapState = state => ({});
const dispatchMap = dispatch => ({});

export default connect(mapState, dispatchMap)(Registry);
