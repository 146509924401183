import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Image, Button } from 'react-bootstrap';
import { compose } from 'recompose';

import * as ROUTES from '../../constants/routes';
import SignOutButton from '../Session/SignOutButton';
import { getAuthUser } from '../../store/selectors/authSelectors';
import { setIsMenuOpen } from '../../store/actions/uiActions';
import ScanButton from './ScanButton';
import { getRegistryList } from '../../store/selectors/registrySelectors';

import './navigation.scss';


const Navigation = (props) => {
  const {
    onSetIsMenuOpen, authUser, registryList,
    history
  } = props;

  return (
    <div className="navigation">
      <div className="bucket">
        <div className="h1-container">
          <h1>Welcome!</h1>
          {/*{*/}
          {/*  !authUser && (*/}
          {/*    <Button className="button-small light" onClick={() => history.push(ROUTES.SIGN_UP)}>*/}
          {/*      Scan me!*/}
          {/*    </Button>*/}
          {/*  )*/}
          {/*}*/}
          {/*{*/}
          {/*  !authUser && (*/}
          {/*    <ScanButton*/}
          {/*      style={{*/}
          {/*        position: 'absolute',*/}
          {/*        top: 21,*/}
          {/*        right: 27*/}
          {/*      }}*/}
          {/*      onClick={() => history.push(ROUTES.SIGN_UP)}*/}
          {/*    />*/}
          {/*  )*/}
          {/*}*/}
        </div>
        <div className="section-container">
          <div>
            <h2>Identity</h2>
            {
              authUser
                ? (<NavLink onClick={() => onSetIsMenuOpen(false)} to={ROUTES.ACCOUNT}>My Account</NavLink>)
                : <p>Unknown</p>
            }
          </div>
        </div>
        <div className="section-container">
          <div>
            <h2>Species</h2>
            {
              authUser
                ? <NavLink onClick={() => onSetIsMenuOpen(false)} to={ROUTES.HOME}>My Species</NavLink>
                : <p>Unknown</p>
            }
          </div>
        </div>
        {
          authUser
            ? (
              <SignOutButton onClick={() => onSetIsMenuOpen(false)} />
            )
            : (
              <Button className="button-small light" onClick={() => {
                console.log('click');
                onSetIsMenuOpen(false);
                history.push(ROUTES.SIGN_IN);
              }}>
                Sign In
              </Button>
            )
        }
        {/*{authUser && }*/}

        {/*<h2>Mental Assistant</h2>*/}
        {/*<NavLink to={'/'} isActive={() => false}>Uninstalled</NavLink>*/}
      </div>

      <div className="bucket">
        <div className="h1-container">
          <h1>The Registry</h1>
        </div>
        <NavLink onClick={() => onSetIsMenuOpen(false)} to={ROUTES.REGISTRY_ROOT}>
          {
            registryList.length
              ? `${registryList.length} Species and Counting!`
              : 'Find Your Species!'
          }
        </NavLink>
      </div>

      {/*<div className="bucket stretch-bucket">*/}
      {/*  <div className="h1-container">*/}
      {/*    <h1>Lore</h1>*/}
      {/*  </div>*/}
      {/*  <p>Coming soon</p>*/}
      {/*</div>*/}

      <div className="bucket">
        <div className="h1-container">
          <h1>The Source</h1>
        </div>
        <div className="book-container">
          <a href="http://prh.com/TheLastHuman" target="_blank" rel="noopener noreferrer">
            <Image className="book-image" src={'/images/TLHCover-US_90.png'} />
          </a>
          <a href="https://www.hodderscape.co.uk/book/the-last-human-zack-jordan/" target="_blank" rel="noopener noreferrer">
            <Image className="book-image" src={'/images/TLHCover-UK_90.png'} />
          </a>
        </div>
      </div>

      {/*<div className="bucket stretch-bucket"></div>*/}

      {/*<div className="bucket bottom-bucket">*/}
      {/*  {*/}
      {/*    authUser*/}
      {/*      ? (*/}
      {/*        <SignOutButton />*/}
      {/*      )*/}
      {/*      : (*/}
      {/*        <Button className="button-small light" onClick={() => {*/}
      {/*          console.log('click');*/}
      {/*          history.push(ROUTES.SIGN_IN);*/}
      {/*        }}>*/}
      {/*          Sign In*/}
      {/*        </Button>*/}
      {/*      )*/}
      {/*  }*/}
      {/*</div>*/}
    </div>
  );
}

Navigation.propTypes = {
  authUser: PropTypes.object,
  leader: PropTypes.object,
  species: PropTypes.object,
  registryList: PropTypes.array.isRequired,
  onSetIsMenuOpen: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

Navigation.defaultProps = {};

export default compose(
  connect(
    state => ({
      authUser: getAuthUser(state),
      registryList: getRegistryList(state)
    }),
    { onSetIsMenuOpen: setIsMenuOpen }
  ),
  withRouter
)(Navigation);
