import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/storage';

import firebaseConfig from '../../../__config__/firebase';

import { handleSignIn, handleSignOut } from './serverActions';

export const SET_FIREBASE = 'firebase.set';
export const setFirebase = firebase => ({
  type: SET_FIREBASE,
  payload: firebase
});

// At the moment does not allow Firebase to be destroyed or recreated.
export const initFirebase = () => (dispatch, getState) => {
  app.initializeApp(firebaseConfig);
  app.auth().onAuthStateChanged(
    authUser => {
      console.log('onAuthStateChanged', authUser);

      if (authUser) {
        dispatch(handleSignIn(authUser));
      } else {
        dispatch(handleSignOut())
      }
    }
  );
  dispatch(setFirebase(app));
};
