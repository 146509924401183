import React from 'react';

import './icons.scss';

const priImport = require('../img/astronaut-white-20.png');
const secImport = require('../img/tech-20.png');

export const astronaut = priImport;
export const tech = secImport;

export const PriCurrencyIcon = () => <img className="currency-icon currency-icon-primary" src={astronaut}  alt="astronaut" />;
export const SecCurrencyIcon = () => <img className="currency-icon currency-icon-secondary" src={tech} alt="tech" />
