import React, { useReducer } from 'react';
import { connect } from 'react-redux';
import { Drawer } from 'antd';
import { FiX as CloseIcon } from 'react-icons/fi';

import TabButton from './TabButton';
import { Modal } from 'react-bootstrap';
import * as TUTORIAL_STAGES from '../../constants/tutorialStages';
import './thingsDrawer.scss';
import StructuresTab from './StructuresTab';
import {
  executeDebugAction,
  resetGame,
  uiSetExpanded,
  uiSetModal,
  uiSetTab
} from '../../store/actions/spaceRaceActions';
import {
  getEngine, getPrimaryCurrency, getSecondaryCurrency,
  getStructures,
  getTechProducer, getTechProgress,
  getTutorial,
  getUI
} from '../../store/selectors/spaceRaceSelectors';
import { TABS } from '../../constants/ui';
import TechTab from './TechTab';
import StoryTab from './StoryTab';
import { TECH } from '../../constants/producers';
import ActionPanel from './ActionPanel';
// import StructuresTab from './StructuresTab';
// import StructuresTab from './StructuresTab';

const LargeModal = ({ item, show, onClose }) => {
  let image, flavor, name;
  if (item) {
    ({ image, flavor, name } = item.meta);
  }

  return (
    <Modal className="large-modal" show={show}>
      <div className="large-modal-info modal-top">
        <p className="modal-title">{name}</p>
        <CloseIcon className="large-modal-close" onClick={onClose}/>
      </div>
      <img className="large-modal-img" src={`/images/EftSS/structures/${image}.jpg`}  alt={name}/>
      <div className="large-modal-info modal-bottom">
        <p className="modal-description">{flavor}</p>
        <p className="modal-attribution">Art by <a href="https://www.artstation.com/karlpoyzer" target="_blank" rel="noopener noreferrer">Karl Poyzer</a></p>
      </div>
    </Modal>
  );
};

const ThingsDrawer = ({
  onResetGame, structures, engine, tutorial, ui, primaryCurrency, secondaryCurrency, techProgress,
  onUISetExpanded, onUISetTab, onUISetModal
}) => {
  const { tab, isExpanded, modal } = ui;

  const TabHeader = ({ tab, onSelect }) => {
    return (
      <div className="tab-header">
        {[TABS.STRUCTURES, TABS.TECH, TABS.STORY, TABS.ACTIONS].map((tabName, index) => {
          let numAlerts = 0;
          if (tabName === TABS.STRUCTURES) {
            if ([TUTORIAL_STAGES.BUILD, TUTORIAL_STAGES.RESEARCH2].includes(tutorial.stage)) {
              numAlerts += 1;
            } else if (tutorial.stage >= TUTORIAL_STAGES.DONE) {
              // Check for buildings that can be unlocked.
              numAlerts += Object.values(engine.producers).reduce((accum, producer) => {
                let returnVal = accum;
                const { meta: { unlock: { isLocked = false, amount = 0 } = {}, techProgressRequired } } = producer;
                // If the highest available tech level is affordable.
                if (techProgressRequired === techProgress) {
                  const cost = producer.calculateCost(1);
                  if (primaryCurrency >= cost.price) {
                    returnVal ++;
                  }
                }

                // If something new is unlockable...
                if (isLocked && (techProgress >= techProgressRequired) && (secondaryCurrency >= amount)) {
                  returnVal ++;
                }
                return returnVal;
              }, 0);
            }
            // structures.reduce((accum, ) => {}, numAlerts);
          } else if (tabName === TABS.TECH) {
            if (tutorial.stage === TUTORIAL_STAGES.RESEARCH) {
              numAlerts += 1;
            } else if (tutorial.stage >= TUTORIAL_STAGES.DONE) {
              // Resume normal operation.
              // We show an alert if we've finished researching.
              const techProducer = engine.producer(TECH);
              if (!techProducer.processingEnabled) {
                numAlerts += 1;
              }
            }
          }

          return (
            <TabButton
              name={tabName} isSelected={tab === tabName} onClick={() => onSelect(tabName)} key={index}
              numAlerts={numAlerts}
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      <LargeModal item={modal} show={!!modal} onClose={() => onUISetModal(false)} />
      <Drawer
        title="Things"
        placement="bottom"
        closable={true}
        maskClosable={true}
        onClose={() => onUISetExpanded(false)}
        visible={isExpanded}
        forceRender={true}
      >
        <TabHeader tab={tab} onSelect={(tabName) => onUISetTab(tabName)} />
        <div className="tab-content">
          {tab === TABS.STRUCTURES && (
            <StructuresTab
              onShowDetails={item => onUISetModal(item)}
            />
          )}

          {tab === TABS.TECH && (
            <TechTab />
          )}

          {tab === TABS.STORY && (
            <StoryTab />
          )}

          {tab === TABS.ACTIONS && (
            <ActionPanel />
          )}
        </div>
      </Drawer>
    </>
  );
}

export default connect(
  state => ({
    primaryCurrency: getPrimaryCurrency(state),
    secondaryCurrency: getSecondaryCurrency(state),
    techProgress: getTechProgress(state),
    structures: getStructures(state),
    engine: getEngine(state),
    tutorial: getTutorial(state),
    ui: getUI(state)
  }),
  {
    onResetGame: resetGame,
    onUISetExpanded: uiSetExpanded,
    onUISetTab: uiSetTab,
    onUISetModal: uiSetModal
  }
)(ThingsDrawer);
