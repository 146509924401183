import React from 'react';
import { connect } from 'react-redux';
import { startResearch } from '../../store/actions/spaceRaceActions';

import { getEngine, getPlayerInfo, getTechProgress, getYears } from '../../store/selectors/spaceRaceSelectors';
import { TECH, allTechs, getResearchMessage } from '../../constants/producers';
import { AnimatePresence, motion } from 'framer-motion';

import './techTab.scss';

/*
<motion.div
            ref={measureRef}
            style={{ flex: 1, overflow: 'hidden', display: 'flex' }}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
          >
            <SpeciesList3D width={width} height={height} />
          </motion.div>
 */
const TechDisplay = ({ engine, tech: { name, image, flavor }, onClick, showProgress, playerInfo, index }) => {
  const producer = engine.producer(TECH);
  const { outputs: { currencies: { [TECH]: { productionTime, lastProcessed } } }, processingEnabled } = producer;
  const percentage = processingEnabled
    ? (Date.now() - lastProcessed) / productionTime || 0
    : 0;

  // const percentage = 0.5;

  return (
    <motion.li
      className={`tech-item${showProgress ? ' tech-item-unknown' : ''}`}
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="tech-bg-image" style={{ backgroundImage: `url('/images/EftSS/tech/${image}-thumb.jpg')` }}>
        {showProgress ? (
          <motion.div
            className="tech-image"
            style={{
              backgroundImage: `url('/images/EftSS/tech/${image}-thumb.jpg')`,
              // width: '50%'
            }}
            animate={{ width: `${(1 - percentage) * 100}%` }}
            // transtion={{ duration: 1, ease: 'linear' }}
          >
            <AnimatePresence>
              {percentage === 0 && (
                <motion.div
                  className="tech-item-info"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <p className="tech-item-name">Unknown Technology</p>
                  <p className="tech-item-flavor">{getResearchMessage({ leader: playerInfo.leader, index })}</p>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ): (
          <motion.div
            className="tech-item-info"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <p className="tech-item-name">{name}</p>
            <p className="tech-item-flavor">{flavor}</p>
          </motion.div>
        )}
      </div>

        {/*<div className="tech-image" style={{ backgroundImage: `url('/images/EftSS/tech/${image}-thumb.jpg')` }} />*/}
      {/*</div>*/}
      {/*<div className="tech-bg-image" style={{ backgroundImage: `url('/images/EftSS/tech/${image}-thumb.jpg')` }}>*/}
      {/*  */}
      {/*</div>*/}

        {/*<div className="tech-image" src={}  alt={name}/>*/}


      {/*{name} - {percentage}*/}
    </motion.li>
  );
};

const TechTab = ({ years, techProgress, engine, onStartResearch, playerInfo }) => {
  const displayedTechs = allTechs.slice(0, techProgress);
  const { leader: { title = 'your worship' } = {} } = playerInfo;

  return (
    <div className="tech-tab">
      <p className="tech-intro">What shall we research next, {title}?</p>
      <ul className="tech-list">
        {displayedTechs.map((tech, index) => (
          <TechDisplay engine={engine} tech={tech} key={index} />)
        )}
        {displayedTechs.length < allTechs.length && (
          <TechDisplay tech={allTechs[techProgress]} engine={engine} onClick={onStartResearch} showProgress={true} playerInfo={playerInfo} index={displayedTechs.length} />
        )}
      </ul>
    </div>
  );
};

export default connect(
  state => ({
    years: getYears(state),
    techProgress: getTechProgress(state),
    engine: getEngine(state),
    playerInfo: getPlayerInfo(state)
  }),
  {
    onStartResearch: startResearch
  }
)(TechTab);
