export const SET_SIGNUP_DATA = 'signup.setData';
export const setSignUpData = (data) => ({
  type: SET_SIGNUP_DATA,
  payload: data
});

export const RESET_SIGNUP_DATA = 'signup.resetData';
export const resetSignUpData = () => ({
  type: RESET_SIGNUP_DATA
});
