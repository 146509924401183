import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FiX as CloseIcon } from 'react-icons/fi';
import { QRCode } from 'react-qrcode-logo';
import { createCardFromData, serializeCard } from '@pixelwelders/tlh-universe-data';

import { Modal } from 'react-bootstrap';
import { getInventory, getModal, getPlayer } from '../../store/selectors';
import { clearModal } from '../../store/actions/modalActions';
import * as MODALS from '../../constants/modals';

import './idCard.scss'
import { getFirstSpecies } from '../../store/selectors/speciesSelectors';
import { getFirstLeader } from '../../store/selectors/leadersSelectors';

const IDCard = ({
  modal, onClearModal,
  species, leader, player, inventory
}) => {
  const { type, content } = modal;
  const show = type === MODALS.ID;

  let qrValue = '';
  if (show) {
    if (content) {
      qrValue = serializeCard(content);
    } else {
      // If the species doesn't have a homeworld, we find the first planet in the inventory.
      const homeworld = species.homeworld
        ? { displayName: species.homeworld }
        : inventory.find(({ subType }) => subType === 'planet' ) || { displayName: 'unknown' };

      const card = createCardFromData({ species, leader, player, homeworld });
      qrValue = serializeCard(card);
    }
  }

  return (
    <Modal show={show} className="id-card">
      <Modal.Header>
        <Modal.Title>Galactic Registration Card</Modal.Title>
        <CloseIcon className="close-btn" size={30} onClick={() => onClearModal()} />
      </Modal.Header>
      <Modal.Body>
        <QRCode value={qrValue} />
        <p>Scan this code at your nearest Galactic Registry Office to print your official ID.</p>
      </Modal.Body>
    </Modal>
  );
};

IDCard.propTypes = {
  modal: PropTypes.object.isRequired,
  onClearModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  modal: getModal(state),
  species: getFirstSpecies(state),
  leader: getFirstLeader(state),
  player: getPlayer(state),
  inventory: getInventory(state)
});

const dispatchMap = {
  onClearModal: clearModal
};

export default connect(mapStateToProps, dispatchMap)(IDCard);
