import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import queryString from 'query-string';

import {
  getFilteredRegistryList,
  getFirstDoc,
  getHasNext,
  getHasPrev,
  getLastDoc, getRegistryIsLoaded,
  getRegistryList
} from '../../store/selectors/registrySelectors';
import { fetchRegistry } from '../../store/actions/serverActions';
import { DebugButtons, Controls, setUp3D } from './3d';

import './speciesList3D.scss';
import * as ROUTES from '../../constants/routes';
import ScanButton from '../Navigation/ScanButton';
import { getAuthUser } from '../../store/selectors/authSelectors';

// TODO Error renderer.
class SpeciesList3D extends Component {
  mount;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      threeSetUp: false,
      shift: 0
    };
  }

  fetchRegistry = async (params = {}) => {
    this.setState({ loading: true });

    const { onFetchRegistry } = this.props;

    await onFetchRegistry(params);
    this.setState({ loading: false });
  }

  componentDidMount() {
    this.fetchRegistry();
  }

  componentWillUnmount() {
    this.dispose();
  }

  // TODO Improve resize. You can put anything here.
  threeSetUp = false;
  camera;
  renderer;
  render3D;
  dispose;
  ReactElements;
  setUpThree = () => {
    if (this.threeSetUp){
      return;
    }

    this.threeSetUp = true;
    const { width, height } = this.props;

    console.log('--- setUpThree ---')
    const { camera, renderer, render3D, dispose, ReactElements } = setUp3D(width, height, this.mount);
    this.camera = camera;
    this.renderer = renderer;
    this.render3D = render3D;
    this.dispose = dispose;
    this.ReactElements = ReactElements;
  }

  render() {
    const {
      width, height, registryList, isLoaded, history, location, authUser
    } = this.props;
    const { shift } = this.state;

    console.log(`SpeciesList3D render | width: ${width} | height: ${height}, items: ${registryList.length}, loaded: ${isLoaded}`);
    if (!this.threeSetUp) {
      if (width && height && isLoaded) {
        this.setUpThree();
      }
    } else {
      // console.log('updating THREE size');
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);
    }

    const { mode } = queryString.parse(location.search);
    return (
      <div className="species-list-3d" style={{ overflow: 'hidden' }}>
        <div ref={ref => (this.mount = ref)} />
        <Controls
          onShift={() => {
            // this.setState(this.state);
            this.forceUpdate();
          }}
        />
        {mode === 'debug' && (
          <DebugButtons
            onShift={shift => {
              // this.setState({ shift });
              this.forceUpdate()
            }}
          />
        )}
        {this.ReactElements && (
          <this.ReactElements
            items={registryList}
            // shift={shift}
            onDisplay={element => console.log('onDisplay', element)}
          />
        )}

        {!registryList.length && isLoaded && (
          <div id="search-message">
            <p>No Species Found</p>
          </div>
        )}

        {!isLoaded && (
          <div id="search-message">
            <p>Loading All Known Species</p>
          </div>
        )}

        {!authUser && (
          <ScanButton
            style={{
              position: 'absolute',
              bottom: 21,
              right: 27
            }}
            onClick={() => history.push(ROUTES.SIGN_UP)}
          />
        )}
      </div>
    );
  }
}

SpeciesList3D.whyDidYouRender = true;
SpeciesList3D.propTypes = {
  registryList: PropTypes.array.isRequired,
  hasPrev: PropTypes.bool.isRequired,
  hasNext: PropTypes.bool.isRequired,
  firstDoc: PropTypes.object,
  lastDoc: PropTypes.object,
  isLoaded: PropTypes.bool.isRequired,
  authUser: PropTypes.object,
  location: PropTypes.object.isRequired
}
SpeciesList3D.defaultProps = {};

// TODO Is it better to simplify my selectors?
const mapState = state => ({
  registryList: getFilteredRegistryList(state),
  firstDoc: getFirstDoc(state),
  lastDoc: getLastDoc(state),
  hasPrev: getHasPrev(state),
  hasNext: getHasNext(state),
  isLoaded: getRegistryIsLoaded(state),
  authUser: getAuthUser(state)
});
const dispatchMap = {
  onFetchRegistry: fetchRegistry
};

export default compose(
  connect(mapState, dispatchMap),
  withRouter
)(SpeciesList3D);
