import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Image as BootstrapImage } from 'react-bootstrap';
import anime from 'animejs/lib/anime.es';
import ReactAnime from 'react-anime';
import * as CURRENCIES from '../constants/currencies';
import {
  getEngine,
  getPrimaryPerClick,
  getYears
} from '../store/selectors/spaceRaceSelectors';
import { astronaut } from './icons';
import Tutorial from './Tutorial';

import './planet.scss';

const img = new Image();
img.src = astronaut;

function setParticleDirection(p) {
  var angle = anime.random(-10, 10) * Math.PI / 180;
  var value = anime.random(50, 180);
  var radius = [-1, 1][anime.random(0, 1)] * value;
  // var radius = value;
  return {
    x: p.x + radius * Math.cos(angle),
    y: p.y + radius * Math.sin(angle)
  }
}

function createParticle(ctx, x, y) {
  const p = {};
  p.x = x;
  p.y = y;
  p.color = '#ffffff';
  p.radius = anime.random(5, 10);
  p.endPos = setParticleDirection(p);
  p.draw = function() {
    ctx.beginPath();
    ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, true);
    ctx.fillStyle = p.color;
    ctx.fill();
  }
  return p;
}

function createCircle(ctx, x, y) {
  const p = {};
  // TODO Pulse the planet.
  p.x = x;
  p.y = y;
  p.color = '#FFF';
  p.radius = 0.1;
  p.alpha = .5;
  p.lineWidth = 6;
  p.draw = function() {
    ctx.globalAlpha = p.alpha;
    ctx.beginPath();
    ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, true);
    ctx.lineWidth = p.lineWidth;
    ctx.strokeStyle = p.color;
    ctx.stroke();
    ctx.globalAlpha = 1;
  }
  return p;
}

function createLabel(ctx, x, y, num) {
  const p = {};
  // TODO Pulse the planet.
  p.x = x;
  p.y = y;
  p.color = '#FF0000';
  p.alpha = 1;
  p.draw = function() {
    ctx.globalAlpha = p.alpha;
    ctx.font = '14px input-sans-compressed';
    if (num > 1) {
      ctx.fillText(`x${num}`, p.x + 8, p.y);
    }
    ctx.drawImage(img, p.x - 3, p.y - 15);
    ctx.globalAlpha = 1;
  }
  return p;
}

function createLabel2(ctx, x, y) {
  const p = {};
  // TODO Pulse the planet.
  p.x = x;
  p.y = y;
  p.color = '#FF0000';
  p.alpha = 1;
  p.draw = function() {
    ctx.globalAlpha = p.alpha;
    ctx.font = '10px rhode';
    // ctx.fillText('LAUNCH!', p.x - 20, p.y);
    ctx.globalAlpha = 1;
  }
  return p;
}

function renderParticle(anim) {
  for (var i = 0; i < anim.animatables.length; i++) {
    anim.animatables[i].target.draw();
  }
}

let isSetup = false;
const setup = (engine, selector, onUpdate) => {
  var canvasEl = document.querySelector(selector);
  if (!canvasEl) {
    console.log('no canvas!');
    return;
  }

  var ctx = canvasEl.getContext('2d');
  var numberOfParticles = 30;
  var tap = ('ontouchstart' in window || navigator.msMaxTouchPoints) ? 'touchstart' : 'mousedown';

  function setCanvasSize() {
    canvasEl.width = window.innerWidth * 2;
    canvasEl.height = window.innerHeight * 2;
    canvasEl.style.width = window.innerWidth + 'px';
    canvasEl.style.height = window.innerHeight + 'px';
    canvasEl.getContext('2d').scale(2, 2);
  }

  const getCoords = (e) => ({
    pointerX: e.clientX || e.touches[0].clientX,
    pointerY: e.clientY || e.touches[0].clientY
  });

  function animateParticles(x, y, num) {
    const circle = createCircle(ctx, x, y);
    const particles = [];
    for (var i = 0; i < numberOfParticles; i++) {
      particles.push(createParticle(ctx, x, y));
    }
    const label = createLabel(ctx, x, y, num);
    const label2 = createLabel2(ctx, x, y);

    const particleParams = {
      targets: particles,
      x: function(p) { return p.endPos.x; },
      y: function(p) { return p.endPos.y; },
      radius: 0.1,
      duration: anime.random(1200, 1800),
      easing: 'easeOutExpo',
      update: renderParticle
    };

    const circleParams = {
      targets: circle,
      radius: anime.random(80, 160),
      lineWidth: 0,
      alpha: {
        value: 0,
        easing: 'linear',
        duration: anime.random(600, 800),
      },
      duration: anime.random(1200, 1800),
      easing: 'easeOutExpo',
      update: renderParticle
    };

    const astronaut1Params = {
      targets: label,
      y: y - 30,
      duration: 700,
      easing: 'easeOutExpo',
      update: renderParticle
    };

    const alphaParams = {
      targets: label2,
      alpha: {
        value: 0,
        easing: 'linear',
        duration: 1000
      },
      duration: 700,
      easing: 'easeOutExpo',
      update: renderParticle
    };

    const alphaParams2 = {
      targets: label,
      x: 65,
      y: 135,
      alpha: {
        value: 0,
        easing: 'linear',
        duration: 500
      },
      duration: 700,
      easing: 'easeOutExpo',
      update: renderParticle
    };


    anime.timeline()
      .add(particleParams)
      .add(circleParams, 0)
      .add(astronaut1Params, 0)
      .add(alphaParams, 0)
      .add(alphaParams2, 1000)
      .finished.then(() => {
        onUpdate();
      });
  }

  var render = anime({
    duration: Infinity,
    update: function() {
      ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);
    }
  });

  const onClick = (e) => {
    render.play();
    const { pointerX, pointerY } = getCoords(e);
    let type = ''
    if (['planet-image', 'hand-container', 'hand-image'].includes(e.target.id)) {
      type = 'launch';
      animateParticles(pointerX, pointerY, engine.currency(CURRENCIES.PRIMARY_PER_CLICK).value, type);
    }
  }

  document.addEventListener(tap, onClick);

  setCanvasSize();
  // window.addEventListener('resize', setCanvasSize, false);
  isSetup = true;

  return { onClick };
};

// eslint-disable-next-line no-unused-vars
let onClick;
const Planet = ({ engine, years }) => {

  // Runs at the end of an animation.
  const onUpdate = () => {
    engine.currency(CURRENCIES.PRIMARY).incrementBy(engine.currency(CURRENCIES.PRIMARY_PER_CLICK).value);
    // forceUpdate(update + 1);
  };

  if (!isSetup) {
    const result = setup(engine,'.particle-layer', onUpdate);
    if (result) {
      ({ onClick } = result);
    }
  }

  // const [update, forceUpdate] = useState(0);
  // useInterval(() => forceUpdate(update + 1), 1000);

  // const [dimensions, setDimensions] = useState({ height: window.innerHeight, width: window.innerWidth });
  // useEffect(() => {
  //   const handleResize = () => {
  //     setDimensions({ height: window.innerHeight, width: window.innerWidth });
  //   }
  //
  //   window.addEventListener('resize', handleResize);
  //
  //   return () => window.removeEventListener('resize', handleResize);
  // });

  const year = engine.currency(CURRENCIES.YEARS).value;
  const yearString = year.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  const [isHandVisible, setIsHandVisible] = useState(true);
  const onTouch = (e) => {
    e.persist();

    if (isHandVisible) setIsHandVisible(false);
    // onClick(e);
  };

  return (
    <div className="planet-main">
      <canvas className="particle-layer" />
      <div className="planet-container">
        <div>
          <p className="year-label">Year {yearString}</p>
        </div>
        <BootstrapImage
          id="planet-image"
          src={'/images/TEMP_Planet.png'}
          onClick={onTouch}
        />
        {isHandVisible && (
          <div id="hand-container" onClick={onTouch}>
            <ReactAnime scale={[1, 0.8, 1]} easing="easeOutQuad" duration={1000}>
              <BootstrapImage id="hand-image" src="/images/EftSS/hand-small.png" />
            </ReactAnime>
          </div>
        )}

        <Tutorial />
      </div>
    </div>
  );
};

export default connect(
  state => ({
    years: getYears(state),
    primaryPerClick: getPrimaryPerClick(state),
    engine: getEngine(state)
  }),
  {}
)(Planet);
