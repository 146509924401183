import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'antd/dist/antd.css';

import App from './js/components/App/App';
import store from './js/store/store';

import './css/global.scss';

// TODO Decide if production.
// const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js');
// whyDidYouRender(React);

/**
 * Shamelessly stolen from https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 * Full-screen on mobile requires some kerjiggery.
 * This enables the following css, which may be set on any element:
 * height: calc(var(--vh, 1vh) * 100);
 */
const setVH = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
window.addEventListener('resize', setVH);
setVH();

ReactDOM.render(
  <Provider store={store}>
    {/*<FirebaseContext.Provider value={new Firebase(firebaseConfig)}>*/}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    {/*</FirebaseContext.Provider>*/}
  </Provider>,
  document.getElementById('root')
);
