import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { FiX as CloseIcon } from 'react-icons/fi';
import Measure from 'react-measure';
import { InventoryItemSubTypes } from '@pixelwelders/tlh-universe-data';

import { getInventoryUrl } from '../../util/getInventoryName';
import FirebaseImage from '../common/FirebaseImage/FirebaseImage';

import './inventoryModal.scss';
import { VesselViewer } from '../VesselViewer/VesselViewer';
import {motion} from 'framer-motion';

const InventoryItem = ({ item }) => {
  const {
    displayName,
    type,
    subType,
    description,
    image: { x1Url  }
  } = item;
  const url = getInventoryUrl(item);

  const [{ width }, setDimensions] = useState({ width: 0, height: 0});

  return (
    <Measure
      bounds
      onResize={contentRect => {
        setDimensions(contentRect.bounds)
      }}
    >
      {({measureRef}) => (
          <motion.div ref={measureRef}>
            <h2>{displayName}</h2>
            <h3>{subType}</h3>
            {subType === InventoryItemSubTypes.SHIP && (
              <>
                {width !== 0 && (
                  <VesselViewer
                    renderWidth={width}
                    renderHeight={width}
                  />
                )}
              </>
            )}
            {subType !== InventoryItemSubTypes.SHIP && (
              <FirebaseImage url={`${url}${x1Url}`} alt={`${type}: ${subType}`} />
            )}
            <p>{description}</p>
          </motion.div>
      )}
    </Measure>
  );
};

const InventoryModal = ({ item, onHide }) => {
  return (
    <Modal
      className="inventory-modal"
      show={!!item}
      onHide={onHide}
      centered
    >
      {/*<Modal.Header closeButton>*/}
      {/*  <h1>Resource</h1>*/}
      {/*</Modal.Header>*/}
      {item && (
        <Modal.Body>
          <InventoryItem item={item} />
          <Button className="close-button" onClick={onHide}>
            <CloseIcon size={30} />
          </Button>
        </Modal.Body>
      )}
    </Modal>
  );
};

InventoryModal.propTypes = {
  item: PropTypes.object,
  onHide: PropTypes.func.isRequired
};
InventoryModal.defaultProps = {
  item: {}
};

export default InventoryModal;
