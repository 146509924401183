import { combineReducers } from 'redux';

import auth from './authReducer';
import player from './playerReducer';
import ui from './uiReducer';
import registry from './registryReducer';
import playerSpecies from './playerSpeciesReducer';
import playerLeaders from './playerLeadersReducer';
import inventory from './inventoryReducer';
import claim from './claimReducer';
import modal from './modalReducer';
import env from './envReducer';
import signUpData from './signUpDataReducer';
import search from './searchReducer';
import firebase from './firebaseReducer';

import spaceRace from '../../features/SpaceRace/store/reducers/spaceRaceReducer';

export default combineReducers({
  auth,
  ui,
  player,
  playerSpecies,
  playerLeaders,
  registry,
  inventory,
  claim,
  modal,
  env,
  signUpData,
  search,
  firebase,

  // TODO This sub-app should add its own reducer.
  spaceRace
});
