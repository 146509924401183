
Object.defineProperty(exports, "__esModule", { value: true });
var THREE = require("three");
var ORIGIN = new THREE.Vector3(0, 0, 0);
var SCALE = 1;
exports.THEME = {
    Red: 0,
    Blue: 1,
    Purple: 2,
    Green: 3,
    Golden: 4,
    Teal: 5
};
var colors = [];
colors[exports.THEME.Red] = {
    Primary: 0xffaa89,
    Secondary: 0xff3000,
    Tertiary: 0xff9000
};
colors[exports.THEME.Blue] = {
    Primary: 0x8AA0FF,
    Secondary: 0x0032FF,
    Tertiary: 0x00C5FF
};
colors[exports.THEME.Purple] = {
    Primary: 0x9F72FF,
    Secondary: 0xFF0090,
    Tertiary: 0x3600FF
};
colors[exports.THEME.Green] = {
    Primary: 0x72FF7B,
    Secondary: 0xFFF100,
    Tertiary: 0x00FFCE
};
colors[exports.THEME.Golden] = {
    Primary: 0xFF7500,
    Secondary: 0xFF7D00,
    Tertiary: 0xFFC000
};
colors[exports.THEME.Teal] = {
    Primary: 0x00FF94,
    Secondary: 0x00AAFF,
    Tertiary: 0x00FFCC
};
var Lighting = /** @class */ (function () {
    function Lighting() {
    }
    Lighting.prototype.StandardPlot = function (theme) {
        var curTheme = colors[theme];
        var group = new THREE.Group();
        group.add(new THREE.AmbientLight(new THREE.Color(1, 1, 1), 0.5 * SCALE));
        group.add(Lighting.Directional(new THREE.Color(curTheme.Primary), 1 * SCALE, new THREE.Vector3(-0.38, 0.88, 0.28)));
        group.add(Lighting.Directional(new THREE.Color(curTheme.Secondary), 1 * SCALE, new THREE.Vector3(-0.15, 0.00, 0.99)));
        group.add(Lighting.Directional(new THREE.Color(curTheme.Secondary), 1 * SCALE, new THREE.Vector3(0.15, 0.00, -0.99)));
        group.add(Lighting.Directional(new THREE.Color(curTheme.Secondary), 1 * SCALE, new THREE.Vector3(0.85, 0.00, -0.1)));
        group.add(Lighting.Directional(new THREE.Color(curTheme.Tertiary), 1 * SCALE, new THREE.Vector3(0.47, 0.41, 0.79)));
        group.add(Lighting.Directional(new THREE.Color(curTheme.Tertiary), 1 * SCALE, new THREE.Vector3(-0.47, 0.41, -0.79)));
        return group;
    };
    Lighting.Directional = function (color, intensity, direction) {
        var light = new THREE.DirectionalLight(color, intensity);
        light.position.sub(direction);
        light.lookAt(ORIGIN);
        return light;
    };
    return Lighting;
}());
exports.default = Lighting;
