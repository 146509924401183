import React, { useEffect } from 'react';
import { connect, useStore } from 'react-redux';

// import Planet from './components/Planet';
// import ThingsDrawer from './components/ThingsDrawer/ThingsDrawer';

import './spaceRace.scss';
// import { srResetEngine, srUpdateEngine } from './store/actions/spaceRaceActions';
import { getIsInitialized } from './store/selectors/spaceRaceSelectors';
import { initGame } from './store/actions/spaceRaceActions';
import StatsDisplay from './components/StatsDisplay';
import Planet from './components/Planet';
import ThingsDrawer from './components/ThingsDrawer/ThingsDrawer';
import ModalManager from './components/Modals/ModalManager';
// import Planet from './components/Planet';

const SpaceRace = ({ onUpdateEngine, onResetEngine, onInitGame, currencies, isInitialized }) => {
  const store = useStore();
  useEffect(() => {
    console.log('INIT!');
    onInitGame(store);
  }, [store, onInitGame]);

  if (!isInitialized) {
    return null;
  }

  // useAnimationFrame(time => {
  //   engine.onTick(time);
  // });

  console.log(currencies);
  return (
    <div className="space-race">
      <ModalManager />
      <StatsDisplay />
      {/*<Notifications />*/}
      <Planet />
      <ThingsDrawer />
    </div>
  );
};

export default connect(
  state => ({
    // engine: getEngine(state)
    isInitialized: getIsInitialized(state)
  }),
  {
    // onUpdateEngine: srUpdateEngine,
    // onResetEngine: srResetEngine,
    onInitGame: initGame
  }
)(SpaceRace);
