import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { getModal } from '../../store/selectors';
import * as MODALS from '../../constants/modals';
import * as ROUTES from '../../constants/routes';
import { clearModal } from '../../store/actions/modalActions';
import { createLeader } from '@pixelwelders/tlh-universe-data';
import { fetchItem } from '../../store/actions/serverActions';
import { beginGame, srSetPlayerInfo } from '../../features/SpaceRace/store/actions/spaceRaceActions';

const Slide1 = () => {
  return (
    <>
      <Button>Escape!</Button>
      <Button>Registry</Button>
    </>
  );
};

const Slide2 = () => {
  return (
    <p>Slide2</p>
  );
};

const NewGameModal = ({ modal, onClearModal }) => {
  const { type, content } = modal;
  const [mode, setMode] = useState(1);

  return (
    <Modal
      show={type === MODALS.NEW_SPECIES}
      centered
    >
      <Modal.Header>
        <Modal.Title>New Species</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Link className="button" to={ROUTES.SPACE_RACE} onClick={onClearModal}>Escape!</Link>
        <Link className="button" to={ROUTES.SIGN_UP} onClick={onClearModal}>Registry!</Link>
      </Modal.Body>
      {/*<Modal.Footer>*/}
      {/*  <Button onClick={onClearModal}>Never Mind</Button>*/}
      {/*  <Button>Go!</Button>*/}
      {/*</Modal.Footer>*/}
    </Modal>
  );
}

export default connect(
  state => ({
    modal: getModal(state)
  }),
  {
    onClearModal: clearModal
  }
)(NewGameModal);
