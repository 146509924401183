import { createSelector } from 'reselect';

import * as CURRENCIES from '../../constants/currencies';
import { FINAL_STRUCT, STRUCTURES, TECH } from '../../constants/producers';

export const getSpaceRace = ({ spaceRace }) => spaceRace;

export const getIsInitialized = createSelector(getSpaceRace, ({ isInitialized }) => isInitialized);

export const getCurrencies = createSelector(getSpaceRace, ({ currencies }) => currencies);
export const getPrimaryCurrency = createSelector(getCurrencies, ({ [CURRENCIES.PRIMARY]: value }) => value);
export const getSecondaryCurrency = createSelector(getCurrencies, ({ [CURRENCIES.SECONDARY]: value }) => value);
export const getYears = createSelector(getCurrencies, ({ [CURRENCIES.YEARS]: value }) => value);
export const getPrimaryPerClick = createSelector(getCurrencies, ({ [CURRENCIES.PRIMARY_PER_CLICK]: value }) => value);
export const getTechProgress = createSelector(getCurrencies, ({ [CURRENCIES.TECH_PROGRESS]: value }) => value);

export const getEntities = createSelector(getSpaceRace, ({ entities }) => entities);
export const getStructures = createSelector(getEntities, (entities) => {
  return STRUCTURES.map(producerName => entities[producerName]).filter(item => !!item);
});
export const getFinalStructure = createSelector(getEntities, (entities) => entities[FINAL_STRUCT]);
export const getTechProducer = createSelector(getEntities, (entities) => {
  return entities[TECH];
});

export const getUpgrades = createSelector(getSpaceRace, ({ upgrades }) => upgrades);

export const getEngine = createSelector(getSpaceRace, ({ engine }) => engine);

export const getNotifications = createSelector(getSpaceRace, ({ notifications }) => notifications);

export const getTutorial = createSelector(getSpaceRace, ({ tutorial }) => tutorial);

export const getUI = createSelector(getSpaceRace, ({ ui }) => ui);

export const getPlayerInfo = createSelector(getSpaceRace, ({ playerInfo }) => playerInfo);

export const getGameInfo = createSelector(getSpaceRace, ({ gameInfo }) => gameInfo);

export const srGetModal = createSelector(getSpaceRace, ({ modal }) => modal);

export const getGameIsComplete = createSelector(getFinalStructure, (structure) => {
  console.log('getGameIsComplete', structure);
  return structure.count > 0;
});
