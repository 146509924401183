
Object.defineProperty(exports, "__esModule", { value: true });
var THREE = require("three");
exports.SKYBOX_TYPE = {
    Red: 0,
    LightBlue: 1,
    DarkBlue: 2,
};
var lookup = [
    '/textures/skybox/red/512',
    '/textures/skybox/lightblue/512',
    '/textures/skybox/darkblue/512',
];
var Skybox = /** @class */ (function () {
    function Skybox() {
    }
    Skybox.prototype.LoadTexture = function (skybox) {
        var src = lookup[skybox];
        var loader = new THREE.CubeTextureLoader();
        loader.setPath(src + "/");
        return loader.load([
            'right.png', 'left.png', 'top.png', 'bottom.png', 'front.png', 'back.png'
        ]);
    };
    return Skybox;
}());
exports.Skybox = Skybox;
