import Loader from 'react-loader-spinner';
import React from 'react';

import './pageLoader.scss';

export const PageLoader = (props) => {
  return (
    <div className="page-loader">
      <Loader
        width={100}
        height={100}
        type="RevolvingDot"
        color="rgba(255, 255, 255, 0.7)"
      />
    </div>
  );
}

PageLoader.propTypes = {};
PageLoader.defaultProps = {};
