import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import './error.scss';

const Error = ({ error }) => {
  return error
    ? (
      <Form.Text className="error-text">{error}</Form.Text>
    )
    : null;
};

Error.propTypes = {
  error: PropTypes.string
};

export default Error;
