import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

// TODO Find out why I can't use createLogger without preventing 3D stuff from rendering.
import logger from 'redux-logger';

import rootReducer from './reducers';
import { systemStartup } from './actions/systemActions';

// TODO Remove logger for production. Bigger issue: separate builds for prod and dev.
const store = createStore(rootReducer, applyMiddleware(thunk));
// const store = createStore(rootReducer, applyMiddleware(thunk));
store.dispatch(systemStartup());

export default store;
