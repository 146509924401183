import * as ANALYTICS_EVENTS from '../../constants/analyticsEvents';
import * as COLLECTIONS from '../../constants/collections';
import { authClearUser, authSetUser } from './authActions';
import { addLeaders, clearLeaders } from './leaderActions';
import { addSpecies, clearSpecies } from './speciesActions';
import { setRegistry } from './registryActions';
import { clearPlayer, setPlayer } from './playerActions';
import { addInventory, clearInventory } from './inventoryActions';
import { getAuthUser } from '../selectors/authSelectors';
import { getFirebase, getPlayer, getSignUpData } from '../selectors';
import beautifyError from '../../util/beautifyError';
import getCachedRandomNumber from '../../util/getCachedRandomNumber';
import { getData } from '../../util/firebaseUtils';
import { createPlayer } from '@pixelwelders/tlh-universe-data';

const serverUrl = 'https://us-central1-species-registry.cloudfunctions.net';

export const fetchLeaders = (playerId) => async (dispatch, getState) => {
  const firebase = getFirebase(getState());
  const docs = await firebase.firestore().collection(COLLECTIONS.LEADERS)
    .where('player', '==', playerId).get();
  return getData(docs);
}

export const fetchLeaderBySpeciesId = (speciesId) => async (dispatch, getState) => {
  const firebase = getFirebase(getState());

  let docs = await firebase.firestore().collection(COLLECTIONS.LEADERS)
    .where('species', '==', speciesId).get();

  return getData(docs)[0];
};

export const fetchSpecies = (playerId) => async (dispatch, getState) => {
  const firebase = getFirebase(getState());
  const snapshot = await firebase.firestore().collection(COLLECTIONS.SPECIES)
    .where('player', '==', playerId).get();

  return getData(snapshot.docs);
}

export const fetchSpeciesByName = (speciesName) => async (dispatch, getState) => {
  const firebase = getFirebase(getState());
  const snapshot = await firebase.firestore().collection(COLLECTIONS.SPECIES)
    .where('name', '==', speciesName).get();

  return getData(snapshot)[0];
}

export const fetchInventoryBySpeciesId = (speciesId) => async (dispatch, getState) => {
  const firebase = getFirebase(getState());
  const docs = await firebase.firestore().collection(COLLECTIONS.INVENTORY)
    .where('species', '==', speciesId).get();

  return getData(docs);
};

export const fetchInventoryByPlayerId = (playerId) => async (dispatch, getState) => {
  const firebase = getFirebase(getState());
  const docs = await firebase.firestore().collection(COLLECTIONS.INVENTORY)
    .where('player', '==', playerId).get();

  return getData(docs);
};

export const fetchPlayer = (authUser) => async (dispatch, getState) => {
  const { uid: playerId } = authUser;
  const firebase = getFirebase(getState());
  const snapshot = await firebase.firestore().collection(COLLECTIONS.PLAYERS)
    .where('player', '==', playerId).get();

  if (snapshot.size > 1) {
    throw new Error('Only one player allowed per user!');
  }

  if (!snapshot.size) {
    console.log('--- No player found ---');
    const newPlayer = createPlayer({
      player: playerId,
      email: authUser.email,
      checkTOS: true, // This is for players who signed up elsewhere.
      created: firebase.firestore.FieldValue.serverTimestamp(),
      updated: firebase.firestore.FieldValue.serverTimestamp(),
    });
    await firebase.firestore().collection(COLLECTIONS.PLAYERS).add(newPlayer);
    console.log('new player', newPlayer);
  }

  return snapshot.docs.length ? snapshot.docs[0].data() : null;
}

export const fetchPosts = ({ query }) => async (dispatch, getState) => {
  const url = `${serverUrl}/posts`;
  // const url = 'http://localhost:8080';
  const result = await fetch(
    `${url}?query=${query}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  );
  const resultObj = await result.json();
  return resultObj;
}

let scanTimeout;
export const fetchScannedSpecies = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('fetchScannedSpecies');
  const url = `${serverUrl}/createPlayer`;
  // const url = 'http://localhost:8080';

  clearTimeout(scanTimeout);
  scanTimeout = setTimeout(() => {
    console.log('timeout');
    reject();
  }, 15000);

  const response = await fetch(url);
  clearTimeout(scanTimeout);

  const result = await response.json();
  console.log('result', result);
  resolve(result);
});

const createMap = (items, field = 'species') => items.reduce((accum, item) => ({ ...accum, [item[field]]: item }), {})
const createArrayMap = items => items.reduce((accum, item) => {
  const arr = accum[item.species] || [];
  arr.push(item);

  return { ...accum, [item.species]: arr };
}, {});

/**
 * This is used to explicitly start the login process. It serves as the explicit doorway, but Firebase
 * may also do it automatically. Thus, we handle the rest of the logic in another action.
 */
export const doSignIn = (email, password) => async (dispatch, getState) => {
  const firebase = getFirebase(getState());
  firebase.analytics().logEvent(ANALYTICS_EVENTS.LOGIN);
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

/**
 * Firebase could login/logout at any time. This handles the login side.
 */
export const handleSignIn = () => async (dispatch, getState) => {
  console.log('handleSignIn');
  const state = getState();
  const { signUpInProgress } = getSignUpData(state);
  if (signUpInProgress) {
    console.log('Sign up is underway. Ignoring.');
    return;
  }

  const firebase = getFirebase(state);
  const authUser = firebase.auth().currentUser;
  const { uid } = authUser;
  const player = await dispatch(fetchPlayer(authUser));
  const playerSpecies = await dispatch(fetchSpecies(uid));
  const leaders = await dispatch(fetchLeaders(uid));
  const inventory = await dispatch(fetchInventoryByPlayerId(uid));

  // const { leaders, inventory } = await fixData(firebase, player, playerSpecies, _leaders, _inventory);

  firebase.analytics().logEvent(ANALYTICS_EVENTS.LOGIN);

  dispatch(authSetUser(authUser));
  dispatch(setPlayer(player));
  dispatch(addLeaders(createMap(leaders)));
  dispatch(addSpecies(createMap(playerSpecies, 'uid')));
  dispatch(addInventory(createArrayMap(inventory)));

  // TODO Better look at this...
  // const card = createCard({
  //   name: `${leaders[0].title} ${leaders[0].displayName}`,
  //   species: playerSpecies[0].displayName,
  //   homeworld: inventory[0].displayName,
  //   tier: playerSpecies[0].tier
  // })
  //
  // dispatch(
  //   notifyEnv({ type: MessageTypes.SET_USER_CARD, payload: card })
  // )
};

export const doSignout = () => async (dispatch, getState) => {
  const firebase = getFirebase(getState());
  return firebase.auth().signOut();
};

/**
 * Firebase could login/logout at any time. This handles the logout side.
 */
export const handleSignOut = (_) => (dispatch, getState) => {
  const firebase = getFirebase(getState());
  const authUserIsSet = getAuthUser(getState()) !== null;
  if (authUserIsSet) {
    firebase.analytics().logEvent(ANALYTICS_EVENTS.LOGOUT);
    dispatch(authClearUser());
    dispatch(clearPlayer());
    dispatch(clearLeaders());
    dispatch(clearSpecies());
    dispatch(clearInventory());
  }
};

/**
 * Change the current user's email address.
 * doEmailUpdate = email => this.auth.currentUser.updateEmail(email);
 */
export const doEmailUpdate = (email) => (dispatch, getState) => {
  const firebase = getFirebase(getState());
  return firebase.auth().currentUser.updateEmail(email);
};

export const doPasswordUpdate = (password) => async (dispatch, getState) => {
  const firebase = getFirebase(getState());
  return firebase.auth().currentUser.updatePassword(password);
};

export const doPasswordReset = (email) => async (dispatch, getState) => {
  const firebase = getFirebase(getState());
  return firebase.auth().sendPasswordResetEmail(email);
};

/**
 * Sets the current screen in firebase when the route changes.
 */
export const setCurrentScreen = (currentScreen) => (dispatch, getState) => {
  const firebase = getFirebase(getState());
  firebase.analytics().setCurrentScreen(currentScreen);
}

export const fetchRegistry = ({
  limit = 5000, startBefore, startAfter, search = ''
} = {}) => async (dispatch, getState) => {
  const firebase = getFirebase(getState());

  let query = firebase.firestore().collection(COLLECTIONS.SPECIES)
    .limit(limit).orderBy('tier', 'desc');

  if (startBefore) query = query.startAfter(startBefore);
  if (startAfter) query = query.startAfter(startAfter);

  const snapshot = await query.get();

  const registry = { snapshot, dir: startBefore ? 'prev' : 'next' };
  dispatch(setRegistry(registry));
};

/**
 * Quickest possible way of faking it.
 */
export const fetchFakeRegistry = (params) => async (dispatch, getState) => {
  const url = `${serverUrl}/createPlayer`;
  // const url = 'http://localhost:8080';
  const response = await fetch(url);
  const { species } = await response.json();

  const snapshot = { docs: [], empty: false };
  for (let i = 0; i < params.limit; i += 1) {
    snapshot.docs.push({
      data: () => {
        const index = i;
        return {
          ...species,
          name: `species-${index}`,
          displayName: `${species.displayName} ${index}`
        };
      }
    });
  }

  dispatch(setRegistry({ snapshot }));
}

export const fetchList = ({ name }) => async (dispatch, getState) => {
  const url = `${serverUrl}/list`;
  // const url = 'http://localhost:8080';
  const result = await fetch(
    `${url}?name=${name}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  );
  const resultObj = await result.json();
  return resultObj;
}

export const fetchItem = ({ name }) => async (dispatch, getState) => {
  const url = `${serverUrl}/createItem`;
  // const url = 'http://localhost:8080';
  const result = await fetch(
    `${url}?name=${name}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  );
  const resultObj = await result.json();
  return resultObj;
}

export const SIGN_UP_BEGIN = 'signUp.begin';
export const SIGN_UP_END = 'signUp.end';
export const SIGN_UP_SUCCESS = 'signUp.success';
export const SIGN_UP_ERROR = 'signUp.failed';
export const signUp = ({ player: _player, species, leader, inventory }) => async (dispatch, getState) => {
  console.log('--- signUp2 begin ---');
  dispatch({ type: SIGN_UP_BEGIN });
  const state = getState();
  const firebase = getFirebase(state);
  let player;
  let email = '';
  let password = '';
  let playerId = '';
  let skipPlayer = false;

  try {
    // First of all, species name must be unique.
    const snapshot = await firebase.firestore().collection(COLLECTIONS.SPECIES)
      .where('name', '==', species.name).get();
    if (snapshot.size > 0) {
      throw new Error(`Sadly, this species name has been taken for ${getCachedRandomNumber(species.name)} million years.`)
    }
    console.log('Species name is unique. Checking auth status...');

    const authUser = firebase.auth().currentUser;
    if (!authUser) {
      // User is not logged in. That means the incoming player object is the source of truth.
      console.log('user is NOT logged in');

      // Remove the stuff we don't want attached, but cache the email and password.
      player = { ..._player };
      ({ email, password } = player);

      delete player.password;
      delete player.checkTOS;

      const result = await firebase.auth().fetchSignInMethodsForEmail(email);
      if (result.length) throw new Error(`It looks like you already have an account with us. Won't you sign in?`);

      console.log('email is new: proceed!')
      playerId = email;

      // TODO But are there already objects hanging around from a familed attempted? This should probably happen before now.
    } else {
      console.log('user is logged in');
      // TODO Grab existing player.
      player = getPlayer(state);
      playerId = authUser.uid;
      skipPlayer = true;
      // throw new Error('User already logged in: not yet implemented.');
    }

    // At this point, we make the call. It will create a new species, leader, and inventory
    // (TEMPORARILY) linked to the current player's email.
    // const url = 'http://localhost:8080';
    const url = `${serverUrl}/immortalizePlayer`;
    const body = {
      player: { ...player, player: playerId },
      species: { ...species, player: playerId },
      leader: { ...leader, player: playerId, species: species.name },
      inventory: inventory.map(item => ({ ...item, player: playerId, species: species.name })),
      skipPlayer
    };
    const headers = { 'Content-Type': 'application/json' };
    const result = await fetch(
      url,
      { headers, method: 'POST', body: JSON.stringify(body) }
    );
    const resultObj = await result.json();
    console.log('result', resultObj);
    if (resultObj.error) {
      throw new Error(resultObj.error);
    }

    // At this point we've created the user's stuff. NOW we create the user.
    if (!authUser) {
      const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);
      // Now we change our objects
      console.log('user?', user, user.uid, user.email);

      for (const collection of [COLLECTIONS.SPECIES, COLLECTIONS.PLAYERS, COLLECTIONS.INVENTORY, COLLECTIONS.LEADERS, COLLECTIONS.EVENTS]) {
        const s = await firebase.firestore().collection(collection).where('player', '==', user.email).get();
        console.log(`found ${s.size} items in ${collection} for new user ${user.uid}`);
        for (const doc of s.docs) {
          console.log('updating item', doc.data(), doc.id);
          const result = await firebase.firestore().collection(collection).doc(doc.id).update({ player: user.uid });
          console.log('result', result);
        }
      }
    }

    dispatch({ type: SIGN_UP_SUCCESS });

    // NOW we can populate objects.
    dispatch(handleSignIn());

  } catch (error) {
    console.error(error);
    dispatch({ type: SIGN_UP_ERROR, payload: beautifyError(error) });
    // Re-throw the error so the UI knows what happened.
    throw(error);

  } finally {
    dispatch({ type: SIGN_UP_END });
  }
  console.log('--- signUp2 end ---');
};

export const doRevolution = ({ player, species, leader }) => async (dispatch, getState) => {
  console.log('Beginning REVOLUTION');
  const firebase = getFirebase(getState());
  firebase.analytics().logEvent(ANALYTICS_EVENTS.REVOLUTION_ATTEMPTED);
  const url = `${serverUrl}/revolution`;
  // const url = `http://localhost:8083`;
  const headers = { 'Content-Type': 'application/json' };
  const body = JSON.stringify({ player, species, leader });
  const result = await fetch(
    url,
    { headers, method: 'POST', body }
  );
  const resultObj = await result.json();
  console.log('result', resultObj);
  if (resultObj.error) {
    firebase.analytics().logEvent(ANALYTICS_EVENTS.REVOLUTION_FAILED);
    throw new Error(resultObj.error);
  }

  firebase.analytics().logEvent(ANALYTICS_EVENTS.REVOLUTION_SUCCEEDED);
  return resultObj;
  // dispatch(setLeader(leader));
  // dispatch(addSpecies(species));
}

export const getClaimItem = ({ claimId }) => async (dispatch, getState) => {
  console.log('claiming', claimId);
  const url = `${serverUrl}/createClaim`;
  // const url = `http://localhost:8080`;
  const result = await fetch(
    `${url}?query=${claimId}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  );
  const resultObj = await result.json();
  return resultObj;
};

export const doClaimItem = ({ item }) => async (dispatch, getState) => {
  console.log('attempting claim...');
  const state = getState();
  const { uid: playerId } = getAuthUser(state);

  const url = `${serverUrl}/claim`;
  // const url = 'http://localhost:8080';
  const body = JSON.stringify({ playerId, item });
  const headers = { 'Content-Type': 'application/json' };
  const result = await fetch(
    url,
    { headers, method: 'POST', body }
  );
  const resultObj = await result.json();
  console.log('result', resultObj);
  if (resultObj.error) {
    throw new Error(resultObj.error);
  }
};

export const updatePlayer = (player) => async (dispatch, getState) => {
  console.log('updatePlayer BEGIN', player);
  const firebase = getFirebase(getState());
  const url = `${serverUrl}/updatePlayer`;
  // const url = 'http://localhost:8081';
  firebase.analytics().logEvent(ANALYTICS_EVENTS.UPDATE_PLAYER);

  const headers = { 'Content-Type': 'application/json' };
  const body = JSON.stringify({ player });
  const result = await fetch(
    url,
    { headers, method: 'POST', body }
  );
  const resultObj = await result.json();
  console.log('updatePlayer RESULT', resultObj);

  if (resultObj.error) {
    firebase.analytics().logEvent(ANALYTICS_EVENTS.UPDATE_PLAYER_FAILED);
    throw new Error(resultObj.error);
  }

  const { player: newPlayer } = resultObj;
  dispatch(setPlayer(newPlayer));

  firebase.analytics().logEvent(ANALYTICS_EVENTS.UPDATE_PLAYER_SUCCEEDED);
  return resultObj;
};
