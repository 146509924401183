import React from 'react';
import { motion } from 'framer-motion';
import { IoMdRefresh as RefreshIcon } from 'react-icons/io';

import './actionHeader.scss';

const ActionHeader = ({ title, isLoading, action }) => {
  return (
    <div className="action-header">
      <h2>{title}</h2>
      {action && (
        <div className="refresh-button">
          <motion.div
            onClick={action}
            disabled={isLoading}
            animate={
              isLoading
                ? { rotate: 360, transition: { duration: 1, yoyo: Infinity } }
                : { rotate: 0, transition: { duration: 0 } }
            }
          >
            {/*<RefreshIcon color="rgb(253, 173, 206)" size={20}/>*/}
            <RefreshIcon color="#f2a3a1" size={20}/>
          </motion.div>
        </div>
      )}
    </div>
  );
}

export default ActionHeader;
