import React, { useState } from 'react';
import { connect } from 'react-redux';

import { STRUCT_00 } from '../../constants/producers';
import Collapse from 'antd/es/collapse';
import BuildingDisplay from '../ThingDisplay/BuildingDisplay';
import UpgradeDisplay from '../ThingDisplay/UpgradeDisplay';
import { getUpgradesForBuilding } from '../../constants/upgrades';

import './structuresTab.scss';
// import { purchaseUpgrade } from '../../store/actions/spaceRaceActions';
import { getEngine, getPlayerInfo, getStructures } from '../../store/selectors/spaceRaceSelectors';

const StructuresTab = ({ engine, playerInfo, structures, onShowDetails }) => {
  const [selected, setSelected] = useState(null);
  const { leader = {} } = playerInfo;
  const { title = 'your worship' } = leader;
  const displayedStructures = structures.filter(structure => structure.key !== STRUCT_00);

  return (
    <>
      <p className="structures-intro">What shall we build next, {title}?</p>
      <Collapse
        accordion={true}
      >
        {displayedStructures.map(({ key, count }) => {
          const upgrades = getUpgradesForBuilding(key).map(name => engine.upgrade(name));
          const isSmall = key === STRUCT_00;

          return (
            <Collapse.Panel
              className={`building-panel${isSmall ? ' building-panel-small' : ''}`}
              key={key}
              showArrow={false}
              header={<BuildingDisplay
                type={key} onShowDetails={onShowDetails}
                isOpen={selected === key} onSetIsOpen={_isOpen => setSelected(_isOpen ? key : null)}
              />}
            >
              <div className="upgrade-container">
                {upgrades.map((upgrade, index) => {
                  return (
                    <UpgradeDisplay
                      upgrade={upgrade} key={index}
                    />
                  );
                })}
              </div>
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </>
  );
};

export default connect(
  state => ({
    engine: getEngine(state),
    structures: getStructures(state),
    playerInfo: getPlayerInfo(state)
  }),
  {}
)(StructuresTab);
