import React from 'react';

import './tabButton.scss';

const TabButton = ({ name, isSelected, onClick, numAlerts = 0 }) => {
  return (
    <div
      className={`tab-button${isSelected ? ' selected' : ''}`}
      onClick={onClick}
    >
      <span>{name}</span>
      {!!numAlerts && (
        <span className="small-alert">{numAlerts}</span>
      )}
    </div>
  );
};

export default TabButton;
