import { getIsUserSignedIn } from '../selectors/authSelectors';
import { doClaimItem } from './serverActions';
import * as ROUTES from '../../constants/routes';

export const STORE_CLAIM_ITEM = 'storeClaimItem';
export const storeClaimItem = ({ item }) => ({
  type: STORE_CLAIM_ITEM,
  payload: { item }
});

export const CLAIM_ITEM = 'claimItem';
export const claimItem = ({ item, history, location }) => async (dispatch, getState) => {
  // TODO Currently assumes we're not signed up.
  const state = getState();
  const isUserSignedIn = getIsUserSignedIn(state);

  console.log('claimItem');
  if (isUserSignedIn) {
    // CLAIM IT.
    dispatch(doClaimItem({ item }));
  } else {
    // Add it to the store to claim it with registration.
    dispatch(storeClaimItem({ item }));
    history.push({ ...location, pathname: ROUTES.SIGN_UP });
  }
};
