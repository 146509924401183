import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { sanitize, capitalize, displayNameToName } from '@pixelwelders/tlh-universe-util'
import * as MODES from '../../constants/modes';

import './speciesDisplay.scss';
import ActionHeader from './ActionHeader';

class SpeciesDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // error: false
      isLoading: false
    };
  }

  loadName = async () => {
    const { onFetchItem, onChange, species } = this.props;

    this.setState({ isLoading: true });
    try {
      const newName = await onFetchItem({ name: 'speciesName' });
      onChange({ ...species, displayName: newName, name: displayNameToName(newName) });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const {
      species, onChange, mode, title, onFetchItem
    } = this.props;
    const { isLoading } = this.state;
    const { displayName } = species;

    return (
      <div className="species-display">
        <ActionHeader title={title} action={onFetchItem ? this.loadName : null} isLoading={isLoading} />
        {mode === MODES.VIEWING && (
          <div>
            <p className={"text-field"}>
              {displayName}
            </p>
          </div>
        )}

        {([MODES.EDITING, MODES.SUBMITTING, MODES.HUMAN_DETECTED].includes(mode)) && (
          <Form.Group>
            <div className="edit-species">
              <input
                className={mode === MODES.HUMAN_DETECTED ? 'error' : ''}
                name="speciesName" type="text" placeholder="Enter name" value={displayName}
                onChange={({ target: { name, type, value: _value, checked }}) => {
                  let value = _value;
                  if (value.substring(0, 3).toLowerCase() === 'the') {
                    value = value.substring(3);
                  } else if (value.length < 3) {
                    value = '';
                  }

                  const sanitizedValue = capitalize(sanitize(value, { doTrim: false }), {});
                  const fullName = `The${sanitizedValue}`;

                  onChange({ ...species, displayName: fullName, name: displayNameToName(fullName) });
                }}
                disabled={mode === MODES.SUBMITTING}
              />
            </div>
          </Form.Group>
        )}
        {mode === MODES.HUMAN_DETECTED && (
          <p className="error">
            This species name is banned for all time.
          </p>
        )}
      </div>
    );
  }
}

SpeciesDisplay.propTypes = {
  species: PropTypes.object.isRequired,
  onFetchItem: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  title: PropTypes.string
};
SpeciesDisplay.defaultProps = {
  mode: MODES.VIEWING,
  title: "Species"
};

export default SpeciesDisplay;
