import { CLEAR_MODAL, SET_MODAL } from '../actions/modalActions';

const initialState = {
  type: null,
  content: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL: {
      return action.payload;
    }

    case CLEAR_MODAL: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
