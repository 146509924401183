import { STORE_CLAIM_ITEM } from '../actions/claimActions';

// Technically, we can have more than one item. At the moment the rest of the app doesn't support that.
const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_CLAIM_ITEM: {
      return [...state, action.payload.item];
    }

    default: {
      return state;
    }
  }
};
