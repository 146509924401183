import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getInventoryName, getImage, getInventoryUrl } from '../../util/getInventoryName';

import './inventoryDisplay.scss';
import InventoryModal from './InventoryModal';
import { useDownloadURL } from 'react-firebase-hooks/storage';
import { getFirebase } from '../../store/selectors';

const InventoryItemDisplay = ({ firebase, inventoryItem, onClick }) => {
  const url = getInventoryUrl(inventoryItem);
  const { thumbUrl } = getImage(inventoryItem);
  const ref = firebase.storage().ref(url + thumbUrl);
  const [downloadUrl, loading, error] = useDownloadURL(ref);

  return (
    <div
      className="inventory-item"
      style={{ backgroundImage: `url(${downloadUrl})` }}
      onClick={onClick}
    >
      <span className="inventory-title">{ getInventoryName(inventoryItem) }</span>
    </div>
  );
};

InventoryItemDisplay.propTypes = {
  inventoryItem: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}

const InventoryDisplay = ({ firebase, inventory, loading, title, emptyString }) => {
  const [focusedInventoryItem, setFocusedInventoryItem] = useState(null);

  return (
    <div className="inventory-display">
      <h2>{ title }</h2>
      {
        loading
          ? <p>Loading...</p>
          : inventory.length
          ? (
            <div className="inventory-list">
              {inventory.map((inventoryItem, index) => {
                return (
                  <InventoryItemDisplay
                    firebase={firebase}
                    inventoryItem={inventoryItem}
                    key={index}
                    onClick={() => setFocusedInventoryItem(inventoryItem)}
                  />
                );
              })}
            </div>
          )
          : <p>{emptyString}</p>
      }

      <InventoryModal
        item={focusedInventoryItem}
        onHide={() => setFocusedInventoryItem(null)}
      />
    </div>
  );
};

InventoryDisplay.propTypes = {
  inventory: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  title: PropTypes.string,
  emptyString: PropTypes.string
};
InventoryDisplay.defaultProps = {
  loading: false,
  title: 'Inventory',
  emptyString: 'This poor species has nothing to its name.'
};

export default connect(
  state => ({
    firebase: getFirebase(state)
  }),
  {}
)(InventoryDisplay);
