import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';

import Error from '../Session/Error';
import beautifyError from '../../util/beautifyError';
import { getPlayer } from '../../store/selectors';
import { updatePlayer } from '../../store/actions/serverActions';
import { compose } from 'recompose';
import { clearModal, setModal } from '../../store/actions/modalActions';
import * as MODALS from '../../constants/modals';

const initialState = {
  fields: [
    { name: 'twitter', displayName: 'Twitter', value: '', message: 'Public - So we can tag you when your species does something interesting.' },
    { name: 'instagram', displayName: 'Instagram', value: '', message: 'Public - So we can link people to your strange alien lifestyle.' }
  ],
  message: null,
  error: null,
  isLoading: false
};

const constructFields = (initialState, player) => {
  const { fields } = initialState;
  const newFields = fields.map(field => {
    const { name } = field;
    const value = player[name];

    return { ...field, value };
  });

  return {
    ...initialState,
    fields: newFields
  }
};

const ActionTypes = {
  CHANGE_FIELD: 'changeField',
  SET_ERROR: 'setError',
  SET_IS_LOADING: 'setIsLoading'
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_FIELD: {
      const { fields } = state;
      const { name, value } = action.payload;
      const fieldIndex = fields.findIndex(({ name: candidateName }) => candidateName === name);
      if (fieldIndex === -1) {
        console.error(`field '${ name }' not found.`);
      }

      const newFields = [ ...state.fields ];
      const entry = newFields[fieldIndex];
      newFields[fieldIndex] = { ...entry, value };

      return { ...state, fields: newFields };
    }

    case ActionTypes.SET_ERROR: {
      return { ...state, error: action.payload };
    }

    case ActionTypes.SET_IS_LOADING: {
      return { ...state, isLoading: action.payload }
    }

    default: {
      console.error(`Unhandled action type '${action.type}'`)
      return state;
    }
  }
};

const PlayerChangeForm = ({ player, onUpdatePlayer, onSetModal, onClearModal }) => {
  const [state, dispatch] = useReducer(reducer, constructFields(initialState, player));
  const { fields, error, message, isLoading } = state;

  const onSubmit = async (event) => {
    event.preventDefault();

    dispatch({ type: ActionTypes.SET_ERROR, payload: null });

    try {
      const newPlayer = fields.reduce((accum, { name, value }) => {
        return {
          ...accum,
          [name]: value
        };
      }, player)

      dispatch({ type: ActionTypes.SET_IS_LOADING, payload: true });
      const { news } = await onUpdatePlayer(newPlayer);
      if (news) {
        onSetModal({
          type: MODALS.NEWS,
          content: {
            title: 'Galaxy News Today',
            newsLink: 'https://twitter.com/GalaxyNewsToday',
            body: news,
            posButton: {
              title: 'Onward',
              onClick: () => onClearModal()
            },
            negButton: {
              title: '',
              onClick: () => {}
            }
          }
        });
      }
    } catch (error) {
      dispatch({ type: ActionTypes.SET_ERROR, payload: error });
      console.error(error);
    } finally {
      dispatch({ type: ActionTypes.SET_IS_LOADING, payload: false });
    }
  }

  const isValid = !isLoading && (fields[0].value !== player.twitter || fields[1].value !== player.instagram);

  return (
    <Form onSubmit={onSubmit} className="email-change-form">
      {fields.map(({ name, displayName, value, message }, index) => {
        return (
          <Form.Group key={index}>
            <Form.Label column={false}>{displayName}</Form.Label>
            <input
              name={name} type="text" value={value}
              onChange={({ target: { value: newValue }}) => {
                console.log('onChange', name);
                dispatch({ type: ActionTypes.CHANGE_FIELD, payload: { name, value: newValue } });
              }}
            />
            <Form.Text className="text-muted">
              {message}
            </Form.Text>
          </Form.Group>
        );
      })}

      {/* TODO */}
      {/*<div className="checkbox-group">*/}
      {/*  <input*/}
      {/*    type="checkbox" name="checkSubscribe" checked={checkSubscribe}*/}
      {/*    onChange={this.onChange}*/}
      {/*  />*/}
      {/*  <Form.Label column={false}>*/}
      {/*    I want to subscribe to <span onClick={this.showNewsModal} style={{ textDecoration: 'underline' }}>Galaxy News Today</span>.*/}
      {/*  </Form.Label>*/}
      {/*</div>*/}

      <Button disabled={!isValid} type="submit" className="button-small light btn btn-primary">
        Update
      </Button>

      {error && <Error error={beautifyError(error)} />}
      {message && <p>{message}</p>}
    </Form>
  );
};

PlayerChangeForm.propTypes = {
  player: PropTypes.object.isRequired,
  onUpdatePlayer: PropTypes.func.isRequired,
  onSetModal: PropTypes.func.isRequired,
  onClearModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  player: getPlayer(state)
});

const dispatchMap = {
  onUpdatePlayer: updatePlayer,
  onSetModal: setModal,
  onClearModal: clearModal
};

export default compose(
  connect(mapStateToProps, dispatchMap)
)(PlayerChangeForm);
