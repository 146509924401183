import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

import * as ROUTES from '../../constants/routes';
import { SignInLink } from '../Session/SignIn';
import * as MODES from '../../constants/modes';
import MultiModeBackground from '../common/MultiModeBackground';
import { doPasswordReset } from '../../store/actions/serverActions';

const INITIAL_STATE = {
  email: '',
  error: null,
  message: null
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  onSubmit = async (event) => {
    event.preventDefault();

    const { email } = this.state;
    const { onDoPasswordReset } = this.props;

    try {
      await onDoPasswordReset(email);
      this.setState({
        ...INITIAL_STATE,
        message: 'We\'ve sent you an email to reset your password.'
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const { email, error, message } = this.state;
    const isValid = !!email;

    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <input name="email" onChange={this.onChange} type="email" placeholder="you@youremail.com"/>
        </Form.Group>

        <Button className="button-small light" disabled={!isValid} type="submit">
          Reset My Password
        </Button>

        {error && <p>{error.message}</p>}
        {message && <p>{message} <SignInLink /></p>}
      </Form>
    );
  }
}

const PasswordForgetForm = connect(
  state => ({}),
  { onDoPasswordReset: doPasswordReset }
)(PasswordForgetFormBase);

const PasswordForget = () => (
  <MultiModeBackground mode={MODES.VIEWING} className="v-padded-page password-forget common-page">
    <div className="scroller">
      <h1>Reset Password</h1>
      <p className="intro">Forget your password? That's certainly common enough among fledgling species like yours.</p>
      <PasswordForgetForm />
    </div>
  </MultiModeBackground>
);

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForget;
export { PasswordForgetForm, PasswordForgetLink };
