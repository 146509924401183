import { InventoryItemTypes, createDefaultImage } from '@pixelwelders/tlh-universe-data';

export const getInventoryName = ({ displayName, type, subType }) => {
  let result = displayName || subType;
  // if (subType === 'station') {
  //   return 'Station';
  // }
  // if (subType) {
  //   result = `${result} (${capitalize(subType)})`
  // }

  return result;
}

const defaultPlanet = createDefaultImage(
  { name: 'Planet', fileName: 'default-planet' },
  { type: 'jpg' }
);

const defaultMap = {
  [InventoryItemTypes.REAL_ESTATE]: { planet: defaultPlanet },
  [InventoryItemTypes.NATURAL_RESOURCE]: {},
  [InventoryItemTypes.CORRIDOR]: {},
  [InventoryItemTypes.TERRITORY]: {},
  [InventoryItemTypes.RELATIONSHIP]: {},
};

export const getImage = ({ type, subType, image }) => {
  if (!image.name) {
    // It's blank for some reason (probably an old version), so load a default.
    const defaultImage = (defaultMap[type] || {})[subType];
    return defaultImage || image;
  }

  return image;
}

export const getInventoryUrl = ({ type: _type, subType: _subType }) => {
  const type = _type.replace(/\s+/g, '-');
  const subType = _subType.replace(/\s+/g, '-');

  return `/images/inventory/${type}/${subType}/`;
}
