import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { nameToDisplayName } from '@pixelwelders/tlh-universe-util';
import { duplicateSpecies, duplicateLeader, createCard, InventoryItemTypes } from '@pixelwelders/tlh-universe-data';
import { Link, withRouter } from 'react-router-dom';
import { FaAddressCard as CardIcon } from 'react-icons/fa';
import { FiArrowLeftCircle as BackIcon } from 'react-icons/fi';

import {
  doRevolution,
  fetchInventoryBySpeciesId,
  fetchLeaderBySpeciesId,
  fetchList,
  fetchSpeciesByName
} from '../../store/actions/serverActions';
import MultiModeBackground from '../common/MultiModeBackground';
import * as MODES from '../../constants/modes';
import * as ROUTES from '../../constants/routes';
import * as MODALS from '../../constants/modals';
import { getAuthUser } from '../../store/selectors/authSelectors';
import DoubleButton from '../common/DoubleButton';
import SpeciesDisplay from '../Session/SpeciesDisplay';
import TraitsDisplay from '../Session/TraitsDisplay';
import InventoryDisplay from '../Session/InventoryDisplay';
import QuirksDisplay from '../Session/SingleQuirkDisplay';
import RedditPosts from '../Session/RedditPosts';
import { Form } from 'react-bootstrap';
import LeaderDisplay from '../Session/LeaderDisplay';
import { PageLoader } from '../PageLoader/PageLoader';
import { getPlayer } from '../../store/selectors';
import { isForbidden } from '../../util/miscUtils';
import { setModal } from '../../store/actions/modalActions';

import '../common_css/species.scss';
import './speciesPageShell.scss';
import { sendCard } from '../../store/actions/cardActions';

const defaultModalState = {
  title: '',
  message: '',
  newsLink: '',
  posButton: {
    title: '',
    onClick: () => {}
  },
  negButton: {
    title: '',
    onClick: () => {}
  }
};

class SpeciesPageShell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cachedSpecies: null,
      loadingSpecies: false,
      loadingInventory: false,
      loadingLeader: false,
      inventory: [],
      leader: null,
      error: '',
      mode: MODES.VIEWING,

      tempLeader: null,
      tempSpecies: null,

      modal: defaultModalState
    }
  }

  loadSpecies = async (speciesName) => {
    const {
      onFetchSpeciesByName, onFetchInventoryBySpeciesId, onFetchLeaderBySpeciesId
    } = this.props;

    this.setState({ loadingSpecies: true, error: '' });

    try {
      const species = await onFetchSpeciesByName(speciesName);
      // console.log(' LOAD COMPLETE', species);
      if (!species) {
        this.setState({
          error: `We can't seem to find '${nameToDisplayName(speciesName)} in our database.'`,
          loadingSpecies: false
        });
        return;
      }
      this.setState({ loadingSpecies: false, cachedSpecies: species });

      const { player: playerId } = species;

      this.setState({ loadingInventory: true });
      const inventory = await onFetchInventoryBySpeciesId(species.uid);
      this.setState({ loadingInventory: false, inventory });

      this.setState({ loadingLeader: true });
      const leader = await onFetchLeaderBySpeciesId(species.uid);
      this.setState({ loadingLeader: false, leader });

    } catch (error) {
      this.setState({ error: error.toString() });
    } finally {
      this.setState({ loadingSpecies: false });
    }
  };

  maybeLoadSpecies = () => {
    const { species } = this.props;
    const { loadingSpecies, error, cachedSpecies } = this.state;

    console.log('maybeLoadSpecies');

    if (!species) {
      console.log(' SpeciesPageShell: no species');
      if (cachedSpecies) {
        console.log(' SpeciesShellShell: clearing last species');
        this.setState({ cachedSpecies: null, mode: MODES.VIEWING });
      }
      return;
    }

    if (loadingSpecies) {
      console.log(' SpeciesPageShell: already loading');
      return;
    }

    if (error) {
      console.log(' SpeciesPageShell:', error);
      return;
    }

    if (cachedSpecies && cachedSpecies.name === species.name) {
      console.log(' SpeciesPageShell: cached species is the same as new species');
      return;
    }

    console.log( ' SpeciesInfoShell: starting load');
    this.loadSpecies(species.name);
  }

  componentDidMount() {
    this.maybeLoadSpecies();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.maybeLoadSpecies();
  }

  render() {
    const {
      authUser, onFetchList, onDoRevolution, player, onSetModal, onSendCard
    } = this.props;
    const {
      cachedSpecies, loadingInventory, inventory, leader, mode,
      tempSpecies, tempLeader
    } = this.state;
    const isOwned = authUser && cachedSpecies && authUser.uid === cachedSpecies.player;
    const isExtinct = cachedSpecies && !!cachedSpecies.isExtinct;

    return (
      <MultiModeBackground
        mode={mode === MODES.EDITING ? MODES.HUMAN_DETECTED : MODES.SUBMITTING}
        className="v-padded-page species-page-shell common-page"
        display={!!cachedSpecies}
      >
        {
          mode !== MODES.SUBMITTING && cachedSpecies
            ? (<Form
              autoComplete="off"
              className="species-form"
            >
              <div className="scroller">
                {mode === MODES.EDITING
                  ? (
                    <>
                      <div className={"species-page-header"}>
                        <Link to={ROUTES.REGISTRY_ROOT}><BackIcon size={28} color="white" /></Link>
                        <h1>Revolution!</h1>
                      </div>
                      <SpeciesDisplay
                        species={tempSpecies}
                        onChange={species => {
                          this.setState({ tempSpecies: species });
                          // this.setState({ species, mode: humanWarning ? MODES.HUMAN_DETECTED : MODES.EDITING });
                        }}
                        mode={isForbidden(tempSpecies.displayName) ? MODES.HUMAN_DETECTED : mode}
                        title="New Species Name"
                      />
                      <LeaderDisplay
                        leader={tempLeader}
                        onChange={leader => this.setState({ tempLeader: leader })}
                        onFetchList={onFetchList}
                        mode={mode}
                        title="New Leader Name"
                      />
                    </>
                  )
                  : (
                    <>
                      <div className={"species-page-header"}>
                        <Link to={ROUTES.REGISTRY_ROOT}><BackIcon size={28} color="white" /></Link>
                        <h1>{cachedSpecies.displayName}{cachedSpecies.isExtinct === 'true' ? ' (Extinct)' : ''}</h1>
                      </div>
                      <div className="species-description">
                        {/*<p><span>Tier</span> {species.tier}</p>*/}
                        {/*<p>{cachedSpecies.description}</p>*/}
                      </div>

                      <h2>Tier</h2>
                      <p>{cachedSpecies.tier}</p>

                      {leader && (
                        <div className="leader-container">
                          <div className="leader-container-info">
                            <h2>Leader</h2>
                            <p>{`${leader.title} ${leader.displayName}`}</p>
                          </div>
                          <CardIcon
                            className="card-icon"
                            size="40px"
                            color="white"
                            onClick={() => {
                              const card = createCard({
                                name: `${leader.title} ${leader.displayName}`,
                                species: cachedSpecies.displayName,
                                homeworld: cachedSpecies.homeworld,
                                tier: cachedSpecies.tier
                              })
                              onSendCard(card);
                            }}
                          />
                        </div>
                      )}
                    </>
                  )
                }

                <TraitsDisplay
                  title={mode === MODES.EDITING ? 'New Species Traits' : 'Species Traits' }
                  traits={mode === MODES.EDITING ? tempSpecies.traits : cachedSpecies.traits}
                  interactive={mode === MODES.EDITING}
                  onChange={traits => this.setState({
                    tempSpecies: { ...tempSpecies, traits }
                  })}
                />
                {
                  mode !== MODES.EDITING && (
                    <>
                      <InventoryDisplay
                        title="Distinctions"
                        emptyString="This species has not yet earned any distinctions."
                        inventory={inventory.filter(({ type }) => type === InventoryItemTypes.DISTINCTION)}
                        loading={loadingInventory}
                      />
                      <InventoryDisplay
                        inventory={inventory.filter(({ type }) => type !== InventoryItemTypes.DISTINCTION)}
                        loading={loadingInventory}
                      />
                      <QuirksDisplay items={cachedSpecies.quirks} species={cachedSpecies} />
                      {/* TODO Search for inventory too. */}
                      <RedditPosts query={cachedSpecies.displayName.toLowerCase()} />
                    </>
                  )
                }
              </div>
            </Form>)
            : (
              <PageLoader />
            )
        }

        {/*{*/}
        {/*  isOwned && mode === MODES.VIEWING && (*/}
        {/*    <button className="float-button" onClick={() => this.setState({ mode: MODES.EDITING })}>*/}
        {/*      <EditIcon size={15} />*/}
        {/*    </button>*/}
        {/*  )*/}
        {/*}*/}

        {/*{*/}
        {/*  isOwned && mode === MODES.EDITING && (*/}
        {/*    <button className="float-button" onClick={() => this.setState({ mode: MODES.VIEWING })}>*/}
        {/*      <DoneIcon size={15} />*/}
        {/*    </button>*/}
        {/*  )*/}
        {/*}*/}

        <div className="button-container">
          {isOwned && mode !== MODES.EDITING && mode !== MODES.SUBMITTING && (
            <DoubleButton
              posLabel="Revolution!"
              posClick={() => this.setState({
                mode: MODES.EDITING,
                tempLeader: duplicateLeader(leader),
                tempSpecies: duplicateSpecies(cachedSpecies)
              })}
            />
          )}
          {isOwned && mode === MODES.EDITING && (
            <DoubleButton
              posLabel="Confirm!"
              posEnabled={!isForbidden(tempSpecies.displayName)}
              posClick={async () => {
                this.setState({ mode: MODES.SUBMITTING });
                try {
                  const { news, species: newSpecies } = await onDoRevolution({
                    player, species: tempSpecies, leader: tempLeader
                  });
                  onSetModal({
                    type: MODALS.NEWS,
                    content: {
                      title: 'Revolution Succeeded!',
                      newsLink: 'https://twitter.com/GalaxyNewsToday',
                      body: news,
                      posButton: {
                        title: 'Onward!',
                        onClick: () => {
                          window.location.href = `${ROUTES.REGISTRY_ROOT}/${newSpecies.name}`;
                        }
                      },
                      negButton: {
                        title: '',
                        onClick: () => {}
                      }
                    }
                  });
                } catch (error) {
                  this.setState({ mode: MODES.VIEWING });
                  onSetModal({
                    type: MODALS.NEWS,
                    content: {
                      title: 'Revolution Failed',
                      newsLink: '',
                      body: error.toString().replace(/Error: /g, ''),
                      posButton: {
                        title: 'Got It'
                      },
                      negButton: {
                        title: '',
                        onClick: () => {}
                      }
                    }
                  });
                }
              }}
              negClick={() => this.setState({ mode: MODES.VIEWING })}
              negLabel="Cancel"
            />
          )}
          {/*{!isOwned && (*/}
          {/*  <DoubleButton*/}
          {/*    posLabel="Admire"*/}
          {/*    posEnabled={true}*/}
          {/*    posClick={() => {}}*/}
          {/*  />*/}
          {/*)}*/}
        </div>
      </MultiModeBackground>
    );
  }
}

SpeciesPageShell.propTypes = {
  species: PropTypes.object,

  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,

  player: PropTypes.object.isRequired,
  authUser: PropTypes.object,
  onFetchSpeciesByName: PropTypes.func.isRequired,
  onFetchInventoryBySpeciesId: PropTypes.func.isRequired,
  onFetchList: PropTypes.func.isRequired,
  onDoRevolution: PropTypes.func.isRequired,
  onSetModal: PropTypes.func.isRequired,
  onSendCard: PropTypes.func.isRequired
};
SpeciesPageShell.defaultProps = {};

const mapStateToProps = state => ({
  authUser: getAuthUser(state),
  player: getPlayer(state)
});
const dispatchMap = {
  onFetchSpeciesByName: fetchSpeciesByName,
  onFetchInventoryBySpeciesId: fetchInventoryBySpeciesId,
  onFetchLeaderBySpeciesId: fetchLeaderBySpeciesId,
  onFetchList: fetchList,
  onDoRevolution: doRevolution,
  onSendCard: sendCard,
  onSetModal: setModal
};

export default compose(
  withRouter,
  connect(mapStateToProps, dispatchMap)
)(SpeciesPageShell);
