import { Engine } from 'continuum-engine';
import throttle from 'lodash/throttle';

import * as CURRENCIES from '../../constants/currencies';
import {
  getConfigForProducer, STRUCT_00, STRUCTURES, YEAR_PRODUCER, techProducer as techProducerConfig, TECH
} from '../../constants/producers';
import { upgradeConfigs, upgradeFuncs } from '../../constants/upgrades';
import {
  getEngine,
  getGameInfo,
  getIsInitialized,
  getPlayerInfo,
  getTutorial
} from '../selectors/spaceRaceSelectors';
import { loadState, resetState, saveState } from '../localStorage';
import { getAuthUser } from '../../../../store/selectors/authSelectors';
import * as MODALS from '../../constants/modals';

let engine;
// let nextNotification;
function gameLoop() {
  const tick = Date.now();
  // if (!nextNotification) {
  //   nextNotification = tick + (5 * 1000);
  // } else if (tick > nextNotification) {
  //   console.log('NOTIFICATION');
  //   nextNotification = tick + (5 * 1000);
  // }

  window.requestAnimationFrame(gameLoop);
  // engine.producer(STRUCT_00).incrementBy(2);
  engine.onTick(tick);
}

export const SET_ENGINE = 'engine.set';
const _setEngine = (engine) => ({
  type: SET_ENGINE,
  payload: engine
});

export const SET_CURRENCY = 'currency.set';
const _setCurrency = (event) => ({
  type: SET_CURRENCY,
  payload: event
});

export const SET_ENTITY_COUNT = 'entity.count.set';
const _setEntityCount = (event) => ({
  type: SET_ENTITY_COUNT,
  payload: event
});

export const SET_ENTITY_META = 'entity.meta.set';
const _setEntityMeta = (event) => ({
  type: SET_ENTITY_META,
  payload: event
});

export const UPDATE_UPGRADE = 'upgrade.update';
const _updateUpgrade = (event) => ({
  type: UPDATE_UPGRADE,
  payload: event
});

const saveVersion = 2;
const getSavedState = (saveData = {}, version) => {
  if ([1, 2].includes(saveData.version)) {
    return saveData.savedState;
  }

  return null;
}

export const INIT_GAME_COMPLETE = 'initGame.complete';
export const initGame = (store) => (dispatch, getState) => {
  const state = getState();
  const isInitialized = getIsInitialized(state);
  if (isInitialized) {
    throw new Error('Game is already initialized');
  }

  // We save a small part of the state.
  store.subscribe(throttle(() => {
    const latestState = getState();
    const isInitialized = getIsInitialized(latestState);
    if (!isInitialized) {
      return;
    }

    const gameInfo = getGameInfo(latestState);
    if (!gameInfo.isStarted) {
      return;
    }

    const engine = getEngine(latestState);
    const engineState = engine.getState(false);
    const authUser = getAuthUser(latestState);

    /*
    outputs: {
      currencies: {
        [CURRENCIES.PRIMARY]: {
          productionTime: 30000,
          productionAmount: 0
        }
      }
    }
     */

    // We have to save producer ouputs separately because they are not included in the save file.
    const producerOverrides = Object.values(engine.producers).reduce((accum, producer) => ({
      ...accum,
      [producer.key]: {
        outputs: producer.outputs
      }
    }), {});

    const stateToSave = {
      version: saveVersion,
      savedState: {
        engine: engineState,
        tutorial: getTutorial(latestState),
        overrides: {
          producers: producerOverrides
        },
        playerInfo: getPlayerInfo(latestState),
        gameInfo
      }
    };
    saveState(stateToSave, authUser ? authUser.uid : undefined);
  }), 1000);

  // ====================================== C R E A T I O N ======================================
  // ----- ENGINE -----
  if (engine) {
    throw new Error('Creating a new engine not implemented.');
  }

  engine = new Engine();

  console.log('creating!');
  const defaultState = {
    currencies: {
      [CURRENCIES.PRIMARY]: 0,
      [CURRENCIES.SECONDARY]: 0,
      [CURRENCIES.PRIMARY_PER_CLICK]: 1,
      [CURRENCIES.YEARS]: 0,
    },
    entities: {
      [STRUCT_00]: { count: 1 }
    },
    upgrades: {},
  };

  // ----- CURRENCIES -----
  Object.values(CURRENCIES).forEach(currencyName => {
    const { currencies: { [currencyName]: initialValue = 0 } } = defaultState;
    const currency = engine.createCurrency(currencyName, initialValue);
    currency.on('CURRENCY_UPDATED', event => dispatch(_setCurrency(event)));
  });

  // ----- PRODUCERS -----
  // Don't care about events for the year producer, since there can be only one.
  // TODO Consider ONLY listening to the year producer.
  engine.createProducer(getConfigForProducer(YEAR_PRODUCER));

  STRUCTURES.forEach((producerName) => {
    const config = getConfigForProducer(producerName);
    const producer = engine.createProducer(config);

    producer.on('PRODUCER_COUNT_UPDATED', event => dispatch(_setEntityCount(event)));
    producer.on('PRODUCER_META_UPDATED', event => dispatch(_setEntityMeta(event)));
  });

  // ----- UPGRADES -----
  // For upgrades, all effects are reflected in other entities/currencies. Therefore, all we care about is isActive.
  upgradeConfigs.forEach(config => {
    const upgrade = engine.createUpgrade(config);
    upgrade.on('UPGRADE_ACTIVATED', event => dispatch(_updateUpgrade(event)));
  });

  // ----- TECH -----
  const techProducer = engine.createProducer(techProducerConfig);
  techProducer.on('PRODUCER_COUNT_UPDATED', event => dispatch(_setEntityCount(event)));
  techProducer.on('PRODUCER_OUTPUT', function({ producer, output, delta}) {
    producer.processingEnabled = false;
  });

  // Shut it off after every output.
  // const techReactor = engine.createReactor({
  //   key: 'tech reactor',
  //   entityType: 'producer',
  //   entityKey: TECH,
  //   basePrice: {
  //     currency: CURRENCIES.PRIMARY,
  //     amount: 0
  //   },
  //   count: 1,
  //   maxCount: 1
  // })
  // techReactor.on('PRODUCER_OUTPUT', (event) => {
  //   console.log('OUTPUT', event);
  //   // producer.processingEnabled = false;
  // });

  // Now overwrite with saved game, if any.
  const authUser = getAuthUser(state);
  const savedState = loadState(authUser ? authUser.uid : null);
  const convertedSavedState = getSavedState(savedState, saveVersion);
  console.log('savedState', savedState);
  console.log('convertedSavedState', convertedSavedState);

  if (convertedSavedState) {
    console.log('overwriting with saved game');
    const { engine: savedEngineState, overrides, tutorial, playerInfo, gameInfo } = convertedSavedState;
    try {
      Object.entries(overrides.producers).forEach(([name, { outputs }]) => {
        engine.producer(name).outputs = outputs;
      });

      engine.loadState(savedEngineState);
    } catch (error) {
      console.error(error);
    }

    dispatch(setTutorial(tutorial));
    dispatch(srSetPlayerInfo(playerInfo));
    dispatch(srSetGameInfo(gameInfo));
  } else {
    console.log('no saved game');
    dispatch(srSetModal({ type: MODALS.WELCOME }));
  }

  dispatch(_setEngine(engine));

  // ----- NOTIFICATIONS ---
  // const createNotificationEngine = () => {
  //   const createNotification = () => {
  //     const { currentId } = getNotifications(state);
  //     const notification = {
  //       id: currentId,
  //       message: Math.random().toString()
  //     };
  //     dispatch(addNotification(notification, 6000));
  //   };
  //
  //   const scheduler = ({ skip = false } = {}) => {
  //     console.log('SCHEDULER', skip);
  //     createNotification();
  //     setTimeout(scheduler, 5000);
  //   }
  //
  //   scheduler({ skip: true });
  // }
  // createNotificationEngine();

  dispatch({ type: INIT_GAME_COMPLETE });

  if (convertedSavedState) {
    dispatch(beginGame());
  }
};

export const beginGame = () => (dispatch, getState) => {
  engine.producer(YEAR_PRODUCER).resetTimers();
  gameLoop();

  dispatch(srSetGameInfo({ isStarted: true }));
}

export const unlockProducer = (key) => (dispatch, getState) => {
  const state = getState();
  const engine = getEngine(state);
  const producer = engine.producer(key);
  const { meta } = producer;
  const { unlock } = meta;
  const currency = engine.currency(unlock.currency);

  currency.incrementBy(-unlock.amount);
  producer.meta = { ...meta, unlock: { ...meta.unlock, isLocked: false } };
};

export const buyProducer = (key) => (dispatch, getState) => {
  const state = getState();
  const engine = getEngine(state);
  const producer = engine.producer(key);
  const currency = engine.currency(producer.baseCost.currency); // TODO
  const cost = producer.calculateCost(1);

  console.log('changing by', -cost.price);
  currency.incrementBy(-cost.price);
  // producer.processingEnabled = true;
  producer.resetTimers();
  producer.incrementBy(1);
};

export const startResearch = () => (dispatch, getState) => {
  const state = getState();
  const engine = getEngine(state);
  const producer = engine.producer(TECH);
  if (producer.processingEnabled) {
    return;
  }

  console.log('startResearch');

  // const { time } = tech;
  const time = 1;

  producer.outputs.currencies[CURRENCIES.SECONDARY].productionTime = time * 1000;
  producer.outputs.currencies[CURRENCIES.TECH_PROGRESS].productionTime = time * 1000;
  producer.outputs.currencies[CURRENCIES.PRIMARY_PER_CLICK].productionTime = time * 1000;
  producer.resetTimers();
  producer.processingEnabled = true;
};

export const activateUpgrade = (key) => (dispatch, getState) => {
  console.log('ACTIVATE UPGRADE', key);
  const state = getState();
  const engine = getEngine(state);
  const upgrade = engine.upgrade(key);
  const primaryCurrency = engine.currency(CURRENCIES.PRIMARY);
  const secondaryCurrency = engine.currency(CURRENCIES.SECONDARY);
  const { cost } = upgrade;

  if (cost[CURRENCIES.PRIMARY]) primaryCurrency.incrementBy(-cost[CURRENCIES.PRIMARY]);
  if (cost[CURRENCIES.SECONDARY]) secondaryCurrency.incrementBy(-cost[CURRENCIES.SECONDARY]);
  engine.activateUpgrade(key, upgradeFuncs);
};

export const ADD_NOTIFICATION = 'notification.add';
export const REMOVE_NOTIFICATION = 'notification.remove';
// export const addNotification = (notification, lifespanMS) => (dispatch, getState) => {
//    dispatch({ type: ADD_NOTIFICATION, payload: notification });
//    setTimeout(() => {
//      dispatch({ type: REMOVE_NOTIFICATION, payload: notification });
//    }, lifespanMS);
// };

export const RESET_GAME = 'game.reset';
export const resetGame = () => (dispatch, getState) => {
  console.log('resetGame');
  resetState();
  window.location.reload();
  // dispatch({ type: RESET_GAME });
  // dispatch(initGame({ startLoop: false }));
};

export const executeDebugAction = (actionName, param = 1) => (dispatch, getState) => {
  console.log('executeDebugAction', actionName, param);
  switch (actionName) {
    case 'debug.addTech': {
      const state = getState();
      const engine = getEngine(state);
      engine.currency(CURRENCIES.SECONDARY).incrementBy(param);
      break;
    }

    default: {
      console.error('Unknown debug action', actionName);
    }
  }
};

export const SET_TUTORIAL = 'tutorial.set';
export const setTutorial = payload => ({
  type: SET_TUTORIAL,
  payload
});

export const UI_SET_EXPANDED = 'spaceRace.expanded.set';
export const uiSetExpanded = isExpanded => ({
  type: UI_SET_EXPANDED,
  payload: isExpanded
});

export const UI_SET_TAB = 'spaceRace.tab.set';
export const uiSetTab = tab => ({
  type: UI_SET_TAB,
  payload: tab
});

export const UI_SET_MODAL = 'spaceRace.ui.modal.set';
export const uiSetModal = item => ({
  type: UI_SET_MODAL,
  payload: item
});

export const SR_SET_PLAYER_INFO = 'spaceRace.playerInfo.set';
export const srSetPlayerInfo = (info) => ({
  type: SR_SET_PLAYER_INFO,
  payload: info
});

export const SR_SET_GAME_INFO = 'spaceRace.gameInfo.set';
export const srSetGameInfo = (info) => ({
  type: SR_SET_GAME_INFO,
  payload: info
});

export const SR_SET_MODAL = 'spaceRace.modal.set';
export const srSetModal = (modal) => ({
  type: SR_SET_MODAL,
  payload: modal
});

export const SR_CLEAR_MODAL = 'spaceRace.modal.clear';
export const srClearModal = () => ({
  type: SR_CLEAR_MODAL
});
