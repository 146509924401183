import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';

import Error from '../Session/Error';
import beautifyError from '../../util/beautifyError';
import { doPasswordUpdate } from '../../store/actions/serverActions';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
  message: ''
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  onSubmit = async (event) => {
    event.preventDefault();

    const { onDoPasswordUpdate } = this.props;
    const { passwordOne } = this.state;

    try {
      this.setState({ message: '', error: null })
      await onDoPasswordUpdate(passwordOne);
      this.setState({ ...INITIAL_STATE, message: 'Password changed!' })
    } catch (error) {
      this.setState({ error });
    }
  };

  // TODO Password form elements are reusable.
  render() {
    const {
      passwordOne, passwordTwo, error, message
    } = this.state;
    const isValid = !!passwordOne && (passwordOne === passwordTwo);

    return (
      <Form onSubmit={this.onSubmit} className="password-change-form">

        <Form.Group>
          <Form.Label>New Password</Form.Label>
          <input name="passwordOne" onChange={this.onChange} type="password" placeholder="Enter password"/>
        </Form.Group>

        <Form.Group>
          <Form.Label>Repeat New Password</Form.Label>
          <input name="passwordTwo" onChange={this.onChange} type="password" placeholder="Enter password again"/>
        </Form.Group>

        <Button disabled={!isValid} type="submit" className="button-small light btn btn-primary">
          Change My Password
        </Button>

        {error && <Error error={beautifyError(error)} />}
        {message && <p>{message}</p>}
      </Form>
    );
  }
}

export default connect(
  state => ({}),
  { onDoPasswordUpdate: doPasswordUpdate }
)(PasswordChangeForm);
