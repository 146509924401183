import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import * as ROUTES from '../../../../constants/routes';
import queryString from 'query-string';

import './modals.scss';
import { getPlayerInfo } from '../../store/selectors/spaceRaceSelectors';
import { srClearModal } from '../../store/actions/spaceRaceActions';

const Endgame = ({ show, playerInfo, onClearModal, history, location }) => {
  const { leader } = playerInfo;

  return (
    <Modal className="welcome-modal" show={show} centered>
      <Modal.Header>
        <Modal.Title>Fateful News!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>We have been invited to make first contact!</p>
        <p className="muted">This will end the game.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClearModal}>Nah</Button>
        <Button variant="primary" onClick={() => {
          const search = queryString.parse(location.search);
          search.skipScan = true
          search.leaderTitle = leader.title;
          search.leaderName = leader.displayName;
          history.push({
            ...location,
            pathname: ROUTES.SIGN_UP,
            search: queryString.stringify(search)
          });
        }}>
          Let's do it!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(
  state => ({
    playerInfo: getPlayerInfo(state)
  }),
  {
    onClearModal: srClearModal
  }
)(withRouter(Endgame));
