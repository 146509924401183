export const CLICK = 0;
export const BUILD = 1;
export const SUCCESS = -1;
export const RESEARCH = 2;
export const RESEARCH2 = 3;

export const TUTORIAL_STAGES = {
  CLICK, BUILD, SUCCESS, RESEARCH, RESEARCH2
};

export const DONE = 100;
