import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import './doubleButton.scss';

const DoubleButton = (props) => {
  const {
    posLabel, posClick, posEnabled,
    negLabel, negClick
  } = props;

  return (
    <div className="double-button">
      {
        negClick && (
          <Button
            className="double-secondary"
            size="lg"
            onClick={negClick}
          >
            {negLabel}
          </Button>
        )
      }
      <Button
        className="double-primary"
        size="lg"
        type="button"
        disabled={!posEnabled}
        onClick={posClick}
      >
        {posLabel}
      </Button>
    </div>
  );
}

DoubleButton.propTypes = {
  posLabel: PropTypes.string.isRequired,
  posClick: PropTypes.func.isRequired,
  posEnabled: PropTypes.bool,
  negLabel: PropTypes.string,
  negClick: PropTypes.func
};
DoubleButton.defaultProps = {
  posEnabled: true
};

export default DoubleButton;
