// export const SPECIES = process.env.REACT_APP_COLLECTION_SPECIES;
// export const LEADERS = process.env.REACT_APP_COLLECTION_LEADERS;
// export const PLAYERS = process.env.REACT_APP_COLLECTION_PLAYERS;
// export const INVENTORY = process.env.REACT_APP_COLLECTION_INVENTORY;
// export const EVENTS = process.env.REACT_APP_COLLECTION_EVENTS;

// export const SPECIES = 'species-alpha';
// export const LEADERS = 'leaders-alpha';
// export const PLAYERS = 'players-alpha';
// export const INVENTORY = 'inventory-alpha';
// export const EVENTS = 'events-alpha';

export const SPECIES = 'species';
export const LEADERS = 'leaders';
export const PLAYERS = 'players';
export const INVENTORY = 'inventory';
export const EVENTS = 'events';
