import { createSelector } from 'reselect';
import { getRegistry, getSearch } from './';

export const getRegistryList = createSelector(getRegistry, ({ snapshot }) => {
  const registry = [];
  snapshot.docs.forEach(doc => {
    // TODO Replace with getData
    const species = { ...doc.data(), uid: doc.id };
    if (!species.preContact) {
      registry.push(species);
    } else {
      console.log('PRE-CONTACT SPECIES', species);
    }
  });

  return registry;
});

export const getFilteredRegistryList = createSelector(
  getRegistryList,
  getSearch,
  (registryList, { filter: _filter }) => {
    if (!_filter) {
      return registryList;
    }

    const filter = _filter.replace(/\s\s+/g, ' ').trim().toLowerCase();

    return registryList.filter(({ displayName, homeworld }) => {
      return displayName.toLowerCase().search(filter) > -1 || homeworld.toLowerCase().search(filter) > -1;
    });
  }
);

export const getItemIndices = createSelector(
  getFilteredRegistryList,
  (registryList) => {
    const map = {};
    registryList.forEach((item, index) => {
      map[item.name] = index;
    });

    return map;
  }
)

export const getFirstDoc = createSelector(
  getRegistry,
  ({ snapshot }) => {
    const { docs } = snapshot;
    return docs.length ? docs[0] : null;
  }
)

export const getLastDoc = createSelector(
  getRegistry,
  ({ snapshot }) => {
    const { docs } = snapshot;
    return docs.length ? docs[docs.length - 1] : null;
  }
);

export const getHasPrev = createSelector(
  getRegistry,
  ({ hasPrev }) => hasPrev
);

export const getHasNext = createSelector(
  getRegistry,
  ({ hasNext }) => hasNext
);

export const getRegistryIsLoaded = createSelector(
  getRegistry,
  ({ loaded }) => loaded
);
