import React from 'react';

import './store.scss';

const Store = () => {
  return (
    <div>
      <h1>Store</h1>
    </div>
  );
};

Store.propTypes = {};

export default Store;
