import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { Button } from 'react-bootstrap';

import * as ROUTES from '../../constants/routes';
import { doSignout } from '../../store/actions/serverActions';

const SignOutButton = ({ history, onDoSignOut, onClick }) => {
  return (
    <Button
      className="button-small light"
      type="button"
      onClick={async () => {
        if (onClick) {
          onClick();
        }
        await onDoSignOut();
        history.push(ROUTES.START);
      }}
    >
      Sign Out
    </Button>
  );
}

SignOutButton.propTypes = {};
SignOutButton.defaultProps = {};

export default compose(
  withRouter,
  connect(
    state => ({}),
    { onDoSignOut: doSignout }
  )
)(SignOutButton)
