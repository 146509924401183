import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import * as MODES from '../../constants/modes';

import './leaderDisplay.scss';
import ActionHeader from './ActionHeader';

class LeaderDisplay extends Component {
  constructor(props) {
    super(props);

    console.log('LeaderDisplay.constructor');

    const { leader: { title } } = props;
    this.state = {
      titles: [title],
      isLoading: false
    };
  }

  async loadTitles() {
    const { titles } = this.state;
    const { mode, onFetchList } = this.props;

    console.log('LeaderDisplay.componentDidUpdate');
    if (mode === MODES.EDITING && titles.length === 1) {
      let newTitles = [];
      try {
        console.log('LeaderDisplay.loading');
        newTitles = await onFetchList({ name: 'titles' });
      } catch (error) {
        console.error(error);
        newTitles = ['President'];
      } finally {
        this.setState({ titles: newTitles });
      }
    }
  }

  async componentDidMount() {
    this.loadTitles();
  }

  async componentDidUpdate(prevProps) {
    this.loadTitles();
  }

  loadLeader = async () => {
    const { onFetchItem, onChange } = this.props;
    this.setState({ isLoading: true });

    try {
      const newLeader = await onFetchItem({ name: 'leader' });
      onChange(newLeader);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const {
      leader, onChange, mode, title: componentTitle, onFetchItem
    } = this.props;
    const { titles, isLoading } = this.state;
    const { title = '', displayName = '' } = leader;

    return (
      <div className="leader-display">
        <ActionHeader title={componentTitle} action={onFetchItem ? this.loadLeader : null} isLoading={isLoading} />
        {
          (mode === MODES.EDITING || mode === MODES.HUMAN_DETECTED)
            ? (
              <Form.Group className="edit-leader">
                <select
                  name="leaderTitle"
                  className="leader-title"
                  // as="select"
                  value={title}
                  onChange={({ target: { name, type, value, checked }}) => {
                    onChange({ ...leader, title: value });
                  }}
                >
                  {titles.map((title, index) => (
                    <option value={title} key={index}>{title}</option>
                  ))}
                </select>
                <input
                  name="leaderName" type="text" placeholder="Enter name" value={displayName} className="leader-name"
                  // onChange={this.onChange}
                  onChange={({ target: { name, type, value, checked }}) => {
                    onChange({ ...leader, displayName: value });
                  }}
                />
              </Form.Group>
            )
            : (
              <p className="text-field">
                {`${title} ${displayName}`}
              </p>
            )
        }
      </div>
    );
  }
}

LeaderDisplay.propTypes = {
  leader: PropTypes.object.isRequired,
  onFetchItem: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFetchList: PropTypes.func.isRequired,
  mode: PropTypes.string,
  title: PropTypes.string
};
LeaderDisplay.defaultProps = {
  isEditing: false,
  mode: MODES.VIEWING,
  title: 'Leader'
};

export default LeaderDisplay;
