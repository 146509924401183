import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion, useMotionValue } from "framer-motion";
import Measure from 'react-measure';
import { clamp } from '@pixelwelders/tlh-universe-util';

import './dragger.scss';

const Dragger = ({ value, onChange, interactive }) => {
  // TODO This should be using a ref but I can't seem to get it to work.
  const [{ width }, setDimensions] = useState({ width: 0, height: 0 });
  const x = useMotionValue(0);
  // const background = useTransform(
  //   x,
  //   [0, width / 2, width],
  //   // ['#1624FC', '#F2F0EA', '#FD4E42']
  //   // ['#ffffff', '#ffffff', '#ffffff']
  //   // ['rgba(253, 197, 146, 0.7)', 'rgba(253, 197, 146, 0.7)', 'rgba(253, 197, 146, 0.7)']
  // );

  const percentage = value * 100;
  const color1 = '#FDADCE';
  const color2 = '#ffffff';
  const indicatorWidth = 1;
  const background = `linear-gradient(to right, ${color1} 0%, ${color1} ${percentage}%, ${color2} ${percentage + indicatorWidth}%, ${color2} 100%)`;

  const handleWidth = 20;

  return (
    <div className={`dragger${interactive ? ' interactive' : ' non-interactive'}`}>
      <Measure
        bounds
        onResize={contentRect => {
          x.set(value * contentRect.bounds.width);
          setDimensions(contentRect.bounds);
        }}
      >
        {({ measureRef }) => (
          <motion.div
            ref={measureRef}
            className="dragger-track"
            style={{ background }}
          >
            {width > 0 && (
              <motion.div
                drag={interactive ? "x" : false}
                dragConstraints={{ left: 0, right: width - handleWidth }}
                style={{ x }}
                dragMomentum={false}
                onDragEnd={(event, info) => {
                  const value = clamp(info.point.x / width, 0, 1);
                  onChange(value);
                }}
                className="dragger-handle"
              >

              </motion.div>
            )}
          </motion.div>
        )}
      </Measure>
    </div>
  );
};

Dragger.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  interactive: PropTypes.bool
};
Dragger.defaultProps = {
  interactive: true
};

export default Dragger;
