export const LOGIN = 'login';
export const SIGN_UP = 'sign_up';
export const SELECT_CONTENT = 'select_content';

export const UPDATE_PLAYER = 'update_player';
export const UPDATE_PLAYER_SUCCEEDED = 'update_player_succeeded';
export const UPDATE_PLAYER_FAILED = 'update_player_failed';

export const LOGOUT = 'logout';
export const SIGN_UP_ATTEMPTED = 'sign_up_attempted';
export const SIGN_UP_FAILED = 'sign_up_failed';

export const SPECIES_CREATED = 'species_created';
// export const SCREEN_VIEW = 'screen_view';

export const REVOLUTION_ATTEMPTED = 'revolution_attempted';
export const REVOLUTION_FAILED = 'revolution_failed';
export const REVOLUTION_SUCCEEDED = 'revolution_succeeded';
