import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { Form, Image } from 'react-bootstrap';
import { FiCheck as DoneIcon, FiEdit as EditIcon } from 'react-icons/fi';
import queryString from 'query-string';

import * as ROUTES from '../../constants/routes';
import QuirksDisplay from './SingleQuirkDisplay';
import TraitsDisplay from './TraitsDisplay';
import {
  fetchItem, fetchList, fetchScannedSpecies, signUp
} from '../../store/actions/serverActions';
import LeaderDisplay from './LeaderDisplay';
import SpeciesDisplay from './SpeciesDisplay';
import EmailForm from './EmailForm';
import DoubleButton from '../common/DoubleButton';
import { PageLoader } from '../PageLoader/PageLoader';
import Scanner from './Scanner';
import { getClaim, getSignUpData } from '../../store/selectors';
import InventoryDisplay from './InventoryDisplay';
import * as MODES from '../../constants/modes';
import * as SIGN_UP_DATA_TYPES from '../../constants/signUpDataTypes'
import ErrorDisplay from './Error';
import MultiModeBackground from '../common/MultiModeBackground';
import { isForbidden } from '../../util/miscUtils';

import '../common_css/species.scss';
import './signUp.scss';
import { resetSignUpData, setSignUpData } from '../../store/actions/signUpActions';
import beautifyError from '../../util/beautifyError';
import { getAuthUser } from '../../store/selectors/authSelectors';
import { getPlayerSpeciesArray } from '../../store/selectors/speciesSelectors';

const ScannerModes = {
  WAITING: MODES.WAITING,
  SCANNING: MODES.SCANNING,
  PAUSED: MODES.PAUSED,
  HUMAN_DETECTED: MODES.HUMAN_DETECTED,
  FAKE_ERRORING: MODES.FAKE_ERRORING
}

// TODO EARTHLINGS
class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scannerMode: ScannerModes.WAITING
    };
  }

  onFetchScannedSpecies = async (overrides) => {
    const { signUpData, onSetSignUpData, onFetchScannedSpecies } = this.props;
    console.log('onFetchScannedSpecies', signUpData);

    onSetSignUpData({
      [SIGN_UP_DATA_TYPES.FETCH_COMPLETE]: false,
      [SIGN_UP_DATA_TYPES.MODE]: MODES.SCANNING
    });

    try {
      const { species, leader, inventory } = await onFetchScannedSpecies();
      if (overrides) {
        leader.title = overrides.leader.title;
        leader.displayName = overrides.leader.displayName;
      }
      onSetSignUpData({
        [SIGN_UP_DATA_TYPES.FETCH_COMPLETE]: true,
        [SIGN_UP_DATA_TYPES.ERROR]: null,
        [SIGN_UP_DATA_TYPES.SPECIES]: species,
        [SIGN_UP_DATA_TYPES.LEADER]: leader,
        [SIGN_UP_DATA_TYPES.INVENTORY]: inventory
      });
      this.onComplete();
    } catch (error) {
      console.log('error', error);
      onSetSignUpData({ [SIGN_UP_DATA_TYPES.ERROR]: beautifyError(error) });
      this.setState({ scannerMode: ScannerModes.WAITING });
    }
  }

  /**
   * This function runs twice: when the animation is done, and when the fetch is done.
   */
  onScanEnd = () => {
    const { onSetSignUpData } = this.props;

    onSetSignUpData({ [SIGN_UP_DATA_TYPES.SCAN_COMPLETE]: true });
    this.onComplete();
  }

  onComplete = () => {
    const { signUpData, onSetSignUpData } = this.props;
    const { scanComplete, fetchComplete } = signUpData;

    if (!(scanComplete && fetchComplete)) {
      return;
    }

    onSetSignUpData({
      [SIGN_UP_DATA_TYPES.MODE]: MODES.VIEWING,
      [SIGN_UP_DATA_TYPES.ERROR]: null
    });
  }

  onClaimSpecies = () => {
    const { onSetSignUpData, playerSpecies } = this.props;

    console.log('onClaimSpecies', playerSpecies);
    if (playerSpecies.length > 0) {
      // console.error('SAVE DATA HERE');
      // history.push({ ...location, pathname: ROUTES.SPACE_RACE });
      this.onSubmit();
    } else {
      onSetSignUpData({ [SIGN_UP_DATA_TYPES.MODE]: MODES.REVIEWING });
    }

    this.setState({ scannerMode: ScannerModes.WAITING });
  }

  onSubmit = async () => {
    const { onSignUp, claim, signUpData, onSetSignUpData, history, location } = this.props;
    const { player, species, leader, inventory } = signUpData;

    onSetSignUpData({ [SIGN_UP_DATA_TYPES.MODE]: MODES.SUBMITTING });
    try {
      await onSignUp(
        { player, species, leader, inventory: [...inventory, ...claim] }
      );
      // TODO Or back!
      // TODO Welcome message!
      // history.push({ ...location, pathname: ROUTES.HOME });
      history.push({ ...location, pathname: ROUTES.REGISTRY_ROOT });
    } catch (error) {
      onSetSignUpData({
        [SIGN_UP_DATA_TYPES.MODE]: MODES.REVIEWING
      });
    }
  };

  onSetScannerMode = mode => this.setState({ scannerMode: mode });

  async componentDidMount() {
    console.log('SignUp.componentDidMount');
    const { location, onResetSignUpData } = this.props;
    const search = queryString.parse(location.search);
    const { skipScan, leaderTitle, leaderName } = search;

    onResetSignUpData();

    if (skipScan) {
      console.log('SKIP SCAN');
      await this.onFetchScannedSpecies({ leader: { title: leaderTitle, displayName: leaderName } });
      this.onScanEnd();
    }
  }

  render() {
    const {
      onFetchItem, onFetchList, claim, signUpData, onSetSignUpData
    } = this.props;
    const { mode, player, species, leader, inventory, error } = signUpData;
    const { scannerMode } = this.state;

    // TODO Real validation (formik?)
    const isValid = !!player.email && !!player.password && player.checkTOS;

    const toDeclare = [...inventory, ...claim];

    return (
      <MultiModeBackground mode={mode} className="v-padded-page sign-up common-page">
        {mode === MODES.SCANNING && (
          <>
            <Scanner
              mode={scannerMode}
              onSetMode={this.onSetScannerMode}
              onBegin={this.onFetchScannedSpecies}
              onComplete={this.onScanEnd}
              duration={1}
            />
            <ErrorDisplay error={error} display={{ opacity: error !== '' ? 1 : 0 }}/>
          </>
        )}

        {/*<div className="adding-secondary-species">*/}
        {/*  Adding Secondary Species*/}
        {/*</div>*/}

        {([MODES.VIEWING, MODES.EDITING, MODES.HUMAN_DETECTED].includes(mode)) && (
          <Form
            autoComplete="off"
            className="species-form"
          >
            <div className="scroller">
              <LeaderDisplay
                leader={leader}
                onFetchItem={onFetchItem}
                onChange={leader => onSetSignUpData({ [SIGN_UP_DATA_TYPES.LEADER]: leader })}
                onFetchList={onFetchList}
                mode={mode}
                title="You Are"
              />

              <SpeciesDisplay
                species={species}
                onFetchItem={onFetchItem}
                onChange={species => {
                  const newState = { [SIGN_UP_DATA_TYPES.SPECIES]: species };
                  if (isForbidden(species.displayName)) {
                    newState[SIGN_UP_DATA_TYPES.MODE] = MODES.HUMAN_DETECTED;
                  }
                  onSetSignUpData(newState);
                }}
                mode={mode}
                title="Your Species Is"
              />

              <InventoryDisplay inventory={toDeclare} title="Homeworld" />

              <QuirksDisplay
                items={species.quirks}
                species={species}
                onFetchItem={onFetchItem}
                onChange={quirks => onSetSignUpData({
                  [SIGN_UP_DATA_TYPES.SPECIES]: { ...species, quirks }
                })}
              />

              <TraitsDisplay
                traits={species.traits}
                interactive={mode === MODES.EDITING || mode === MODES.HUMAN_DETECTED}
                onFetchItem={onFetchItem}
                onChange={traits => onSetSignUpData({
                  [SIGN_UP_DATA_TYPES.SPECIES]: { ...species, traits }
                })}
              />

              {/*<div className="flex-spacer" />*/}
              {error && <ErrorDisplay error={error}/>}
            </div>

            <div className="button-container">
              <DoubleButton
                posLabel="That's me!"
                posClick={this.onClaimSpecies}
                posEnabled={!isForbidden(species.displayName)}
                // negLabel={"Rescan"}
                // negClick={() => this.setState({ mode: MODES.SCANNING })}
              />
            </div>

          </Form>
        )}

        {/* Currently hiding the edit button. */}
        {
          mode === MODES.VIEWING && false && (
            <button
              className="float-button"
              onClick={() => onSetSignUpData({ [SIGN_UP_DATA_TYPES.MODE]: MODES.EDITING })}
            >
              <EditIcon size={15} />
            </button>
          )
        }

        {
          mode === MODES.EDITING && (
            <button
              className="float-button"
              onClick={() => onSetSignUpData({ [SIGN_UP_DATA_TYPES.MODE]: MODES.VIEWING })}>
              <DoneIcon size={15} />
            </button>
          )
        }

        {mode === MODES.REVIEWING && (
          <Form
            autoComplete="off"
            className="species-form"
          >
            <div className="scroller">
              {/*<h2>{`${leader.title} ${leader.displayName}:`}</h2>*/}
              <h2 className="welcome">The Galaxy Welcomes You</h2>
              <div className="book-container">
                <Image className="book-image" src={'/images/TLHCover_75.jpg'} />
                <p>Congratulations! <span className="emphasis">{species.displayName}</span>, from the planet <span className="emphasis">{inventory[0].displayName}</span> under the leadership of <span className="emphasis">{leader.displayName}</span>, are about to become a permanent part of the universe of <i>The Last Human</i>.</p>
              </div>

              {error && <ErrorDisplay error={error} />}

              {/*<Form.Check*/}
              {/*  type="checkbox" name="qualifiedToRepresent" checked={true} readOnly*/}
              {/*  className="species-checkbox"*/}
              {/*  label={`I am qualified to represent my entire species, ${species.displayName}`}*/}
              {/*/>*/}

              <div className="flex-spacer" />

              <EmailForm
                player={player}
                onChange={player => onSetSignUpData({ [SIGN_UP_DATA_TYPES.PLAYER]: player })}
              />
            </div>{/* .scroller */}

            <div className="button-container">
              <DoubleButton
                posLabel="Submit!"
                posEnabled={isValid}
                posClick={this.onSubmit}
                negLabel={"Back"}
                negClick={() => onSetSignUpData({
                  [SIGN_UP_DATA_TYPES.MODE]: MODES.VIEWING,
                  [SIGN_UP_DATA_TYPES.ERROR]: null
                })}
              />
            </div>
          </Form>
        )}

        {mode === MODES.SUBMITTING && (
          <PageLoader />
        )}
      </MultiModeBackground>
    );
  }
}

SignUp.propTypes = {
  signUpData: PropTypes.object.isRequired,
  claim: PropTypes.array,
  authUser: PropTypes.object,
  playerSpecies: PropTypes.array,
  onSetSignUpData: PropTypes.func.isRequired,
  onResetSignUpData: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired,
  onFetchScannedSpecies: PropTypes.func.isRequired,
  onFetchList: PropTypes.func.isRequired,
  onFetchItem: PropTypes.func.isRequired
};

SignUp.defaultProps = {};

const mapState = state => ({
  signUpData: getSignUpData(state),
  claim: getClaim(state),
  authUser: getAuthUser(state),
  playerSpecies: getPlayerSpeciesArray(state)
});

const dispatchMap = {
  onSetSignUpData: setSignUpData,
  onResetSignUpData: resetSignUpData,
  onSignUp: signUp,
  onFetchScannedSpecies: fetchScannedSpecies,
  onFetchList: fetchList,
  onFetchItem: fetchItem
};

export default compose(
  withRouter,
  connect(mapState, dispatchMap)
)(SignUp);

const SignUpLink = () => (
  <Link to={ROUTES.SIGN_UP}>Register My Species</Link>
);

export { SignUpLink }
