import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';

import './emailForm.scss';
import * as MODALS from '../../constants/modals';
import { connect } from 'react-redux';
import { getModal } from '../../store/selectors';
import { setModal } from '../../store/actions/modalActions';

class EmailForm extends Component {
  onChange = ({ target: { name, type, value, checked }}) => {
    const { player, onChange } = this.props;
    onChange({
      ...player,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  showNewsModal = () => {
    console.log('show news modal');
    const { onSetModal } = this.props;

    onSetModal({
      type: MODALS.TEXT,
      content: {
        title: 'Galaxy News Today',
        body: <p><span className="italic">Galaxy News Today</span> is the galaxy's premiere publication. It contains thrilling tales of the exploits of <span className="italic">your</span> species, as well as those of your neighbors. Though it strives to remain a reliable news source, you may also hear tales of Human sightings around the galaxy.</p>
      }
    });
  };

  render() {
    const { player } = this.props;
    const {
      twitter, email, password, checkTOS, checkSubscribe
    } = player;

    return (
      <div className="email-form">
        <Form.Group>
          <Form.Label>Your Twitter handle</Form.Label>
          <input
            name="twitter" value={twitter} type="twitter" placeholder="@GalaxyNewsToday"
            onChange={this.onChange}
          />
          <Form.Text className="text-muted">
            Optional. So we can tag you when your species <a href="https://twitter.com/GalaxyNewsToday" target="nkank" rel="noopener noreferrer">does something interesting</a>.
          </Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label>Your Login</Form.Label>
          <input
            id="email" name="email" value={email} type="email" placeholder="you@youremail.com"
            onChange={this.onChange}
          />
          <input
            name="password" type="password" value={password} placeholder="super secret p4ssw0rd"
            onChange={this.onChange}
          />
          <Form.Text className="text-muted">
            You'll need this in order to make any changes to your species.
          </Form.Text>
        </Form.Group>

        {/*<Form.Group>*/}
        {/*  <Form.Label>Password</Form.Label>*/}
        {/*  <input*/}
        {/*    name="password" type="password" value={password}*/}
        {/*    onChange={this.onChange}*/}
        {/*  />*/}
        {/*  <Form.Text className="text-muted">*/}
        {/*    This one should be obvious.*/}
        {/*  </Form.Text>*/}
        {/*</Form.Group>*/}

        <div className="checkbox-group">
          <input
            type="checkbox" name="checkTOS" checked={checkTOS}
            onChange={this.onChange}
          />
          <Form.Label>
            I agree to the <Link to={ROUTES.PRIVACY} style={{ textDecoration: 'underline' }}>terms and conditions</Link>.
          </Form.Label>
        </div>

        <div className="checkbox-group">
          <input
            type="checkbox" name="checkSubscribe" checked={checkSubscribe}
            onChange={this.onChange}
          />
          <Form.Label>
            I want to subscribe to <span onClick={this.showNewsModal} style={{ textDecoration: 'underline' }}>Galaxy News Today</span>.
          </Form.Label>
        </div>
      </div>
    );
  }
}

EmailForm.propTypes = {
  player: PropTypes.object.isRequired,
  modal: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSetModal: PropTypes.func.isRequired,
};

EmailForm.defaultProps = {
  onSetModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  modal: getModal(state)
});

const dispatchMap = {
  onSetModal: setModal
};

export default connect(
  mapStateToProps,
  dispatchMap
)(EmailForm);
