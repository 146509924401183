/**
 * A reducer with the current page of species.
 */
import { SET_REGISTRY } from '../actions/registryActions';

const initialState = {
  snapshot: {
    docs: []
  },
  loaded: false,
  hasPrev: true,
  hasNext: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_REGISTRY: {
      const { snapshot, dir } = action.payload;
      const hasPrev = !(snapshot.empty && dir === 'prev');
      const hasNext = !(snapshot.empty && dir === 'next')
      return {
        snapshot: snapshot.empty ? state.snapshot : snapshot,
        hasPrev,
        hasNext,
        loaded: true
      }
    }

    default: {
      return state;
    }
  }
};
