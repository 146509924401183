import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useDownloadURL } from 'react-firebase-hooks/storage';
import Loader from "react-spinners/RingLoader";
import { FaExclamationTriangle as ErrorIcon } from 'react-icons/fa';
import { getFirebase } from '../../../store/selectors';

import './firebaseImage.scss';

const FirebaseImage = ({ firebase, url, width, height, alt }) => {
  const ref = firebase.storage().ref(url);
  const [downloadUrl, loading, error] = useDownloadURL(ref);

  return (
    <>
      {(!downloadUrl || loading) && (
        <div className="firebase-image-loading">
          <Loader />
        </div>
      )}

      {error && (
        <div className="firebase-image-error">
          <ErrorIcon color="#ff3333" />
        </div>
      )}

      {!loading && !error && (
        <img className="firebase-image" src={downloadUrl} alt={alt}/>
      )}
    </>
  );
};

FirebaseImage.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  firebase: PropTypes.object,
  alt: PropTypes.string
};

export default connect(
  state => ({
    firebase: getFirebase(state)
  }),
  {}
)(FirebaseImage);
