import React from 'react';

import PasswordChangeForm from './PasswordChangeForm';
import * as MODES from '../../constants/modes';
import MultiModeBackground from '../common/MultiModeBackground';
import EmailChangeForm from './EmailChangeForm';
import PlayerChangeForm from './PlayerChangeForm';

import './account.scss';

const AccountPage = () => (
  <MultiModeBackground mode={MODES.REVIEWING} className="v-padded-page account common-page">
    <div className="scroller">
      <h1>Account Page</h1>
      <PlayerChangeForm />
      <PasswordChangeForm />
      <EmailChangeForm />
    </div>
  </MultiModeBackground>
);

export default AccountPage;

