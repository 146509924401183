/**
 * Modes used by various components in the application.
 */
export const UNINITIALIZED = 'mode.unitialized';
export const SCANNING = 'mode.scanning';
export const HUMAN_DETECTED = 'mode.humanDetected';
export const FAKE_ERRORING = 'mode.fakeErroring';
export const VIEWING = 'mode.viewing';
export const EDITING = 'mode.editing';
export const SUBMITTING = 'mode.submitting';
export const REVIEWING = 'mode.reviewing';
export const COMPLETE = 'mode.complete';
export const WAITING = 'mode.waiting';
export const PAUSED = 'mode.paused';
export const HIDDEN = 'mode.hidden';
