import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FiMenu as MenuIcon } from 'react-icons/fi';

import Logo from './Logo';
import * as ROUTES from '../../constants/routes';

import './header.scss';

const Header = ({ onMenuClick, location: { pathname }, match }) => {
  let variant;
  if ([ROUTES.REGISTRY_ROOT, ROUTES.PRIVACY, ROUTES.SPACE_RACE].includes(pathname)) {
    variant = ''
  } else {
    variant = 'logo-mono'
  }

  return (
    <div
      className={`header-navbar ${pathname.replace(/\//g, '')}-page ${variant}`}
      onClick={onMenuClick}
    >
      <div className="spacer">
        <MenuIcon size={32} color="white"/>
      </div>
      <Logo variant={variant}/>
      <div className="spacer"></div>
    </div>
  );
};

Header.propTypes = {
  onMenuClick: PropTypes.func.isRequired
}

Header.defaultProps = {};

export default withRouter(Header);
