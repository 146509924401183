import { ADD_INVENTORY, CLEAR_INVENTORY } from '../actions/inventoryActions';

/**
 * Stores inventories as arrays keyed to species names/ids.
 * @type {{[p: string]: Array}}
 */
const initialState = {
  // species-name: []
};

export const reduceInventory = (map, state) => {
  return Object.entries(map).reduce((accum, [key, inv]) => {
    const currentInv = accum[key] || [];
    return { ...accum, [key]: [...currentInv, ...inv] };
  }, state)
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_INVENTORY: {
      const { payload: map } = action;
      const newState = reduceInventory(map, state);

      return newState;
    }

    case CLEAR_INVENTORY: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
