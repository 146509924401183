import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal } from 'react-bootstrap';
import { FiRefreshCw as RefreshIcon } from 'react-icons/fi';
import { createLeader } from '@pixelwelders/tlh-universe-data';

import './modals.scss';
import { fetchItem } from '../../../../store/actions/serverActions';
import {
  beginGame,
  srClearModal,
  srSetPlayerInfo
} from '../../store/actions/spaceRaceActions';
import { getGameInfo, getPlayerInfo } from '../../store/selectors/spaceRaceSelectors';

const Welcome = ({
  show, gameInfo, playerInfo,
  onFetchItem, onSetPlayerInfo, onBeginGame, onClearModal
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [leader, setLeader] = useState(createLeader());

  const doFetch = async () => {
    try {
      setIsLoading(true);
      setError('');
      const leader = await onFetchItem({ name: 'leader' });
      setLeader(leader);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  const finalize = () => {
    onSetPlayerInfo({ leader });
    onClearModal();
    onBeginGame();
  };

  // TODO This makes me super nervous because I'm lying about dependencies.
  useEffect(() => {
    doFetch();
  }, [onFetchItem]);

  return (
    <Modal className="welcome-modal" show={show} centered>
      <Modal.Header>
        <Modal.Title>Good News, Your Worship!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Your coup was successful! All world governments have submitted to your dream of reaching the stars. At whose name shall I tell them to tremble?</p>
        <Form className="welcome-form">
          <Form.Control type="text" id="welcome-name" value={`${leader.title} ${leader.displayName}`} readOnly/>
          <Button onClick={doFetch} disabled={isLoading}><RefreshIcon/></Button>
        </Form>
        {error && (
          <p className="dialog-error">{error}</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={finalize}>That's Me!</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(
  state => ({
    gameInfo: getGameInfo(state),
    playerInfo: getPlayerInfo(state)
  }),
  {
    onFetchItem: fetchItem,
    onSetPlayerInfo: srSetPlayerInfo,
    onBeginGame: beginGame,
    onClearModal: srClearModal
  }
)(Welcome);
