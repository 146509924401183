import { SET_UI } from '../actions/uiActions';

const initialState = {
  isMenuOpen: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_UI: {
      return {
        ...state,
        ...action.payload
      }
    }

    default: {
      return state;
    }
  }
}
