import React from 'react';
import { connect } from 'react-redux';

import './actionPanel.scss';
import { resetGame } from '../../store/actions/spaceRaceActions';

const ActionPanel = ({ onResetGame }) => {
  return (
    <div className="action-panel">
      {/*<button className="action-button" onClick={onResetGame}>Reset</button>*/}
      <div style={{ flex: 1 }} />
      <button
        className="action-button"
        onClick={onResetGame}
      >
        RESET
      </button>
    </div>
  );
};

export default connect(
  state => ({}),
  {
    onResetGame: resetGame
  }
)(ActionPanel);
