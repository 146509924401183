import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import ActionHeader from './ActionHeader';
import './singleQuirkDisplay.scss';

const renderItem = ({ displayName }, species) => {
  const phrase = `${displayName.charAt(0).toLowerCase()}${displayName.slice(1)}.`
  return `${species.displayName} ${phrase}`;
};

class SingleQuirkDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  loadQuirk = async (index) => {
    const { onFetchItem, onChange, items } = this.props;
    this.setState({ isLoading: true });

    try {
      const newQuirk = await onFetchItem({ name: 'quirk' });
      const newQuirks = [...items];
      newQuirks[index] = newQuirk;
      onChange(newQuirks);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false })
    }
  }

  render() {
    const {
      items, species, onFetchItem, title
    } = this.props;
    const { isLoading } = this.state;

    return (
      <div className="single-quirk-display">
        <ActionHeader title={title} action={onFetchItem ? () => this.loadQuirk(0) : null} isLoading={isLoading} />

        <div className="quirks-list">
          <Form.Group>
            <div className="quirk-container">
              <p className="quirk-text">
                {renderItem(items[0], species)}
              </p>
            </div>
          </Form.Group>
        </div>
      </div>
    );
  }
}

SingleQuirkDisplay.propTypes = {
  title: PropTypes.string,
  species: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFetchItem: PropTypes.func,
  onChange: PropTypes.func
};

SingleQuirkDisplay.defaultProps = {
  title: 'Fun Fact'
}

export default SingleQuirkDisplay;
