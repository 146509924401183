import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { randomFloatBetween } from '@pixelwelders/tlh-universe-util';

import Dragger from '../common/Dragger';

import './traitsDisplay.scss';
import ActionHeader from './ActionHeader';

let fakeLoadTimeout;

class TraitsDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  componentWillUnmount() {
    clearTimeout(fakeLoadTimeout);
  }

  loadTraits = () => {
    this.setState({ isLoading: true });

    clearTimeout(fakeLoadTimeout);
    fakeLoadTimeout = setTimeout(() => {
      const { onChange, traits } = this.props;
      const newTraits = traits.map(trait => ({ ...trait, value: randomFloatBetween(0, 1, 2) }));
      onChange(newTraits);
      this.setState({ isLoading: false });
    }, 500)
  }

  loadTraitsAsync = async () => {
    const { onChange, onFetchItem } = this.props;
    this.setState({ isLoading: true });

    try {
      const newTraits = await onFetchItem({ name: 'traits' });
      onChange(newTraits);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onChange = (index, value) => {
    const { traits, onChange } = this.props;
    const newTraits = [ ...traits ];
    const newTrait = { ...newTraits[index], value };
    newTraits[index] = newTrait;

    onChange(newTraits);
  }

  render() {
    const {
      traits, interactive, title, onFetchItem
    } = this.props;
    const { isLoading } = this.state;

    const mode = interactive ? 'interactive' : 'non-interactive'
    const labelVariants = {
      initial: { opacity: 0, top: 20 },
      interactive: { top: 0, opacity: 1 },
      'non-interactive': { top: 0, opacity: 1 },
    };

    return (
      <div
        className={`traits-display td-${mode}`}
      >
        <ActionHeader isLoading={isLoading} action={onFetchItem ? this.loadTraits : null} title={title} />
        <div style={{ flex: 1 }}>
          {traits.map((trait, index) => {
            const { low, high, value: traitValue } = trait;
            return (
              <Form.Group
                key={index}
              >
                <motion.div
                  className="trait-name-container"
                  variants={labelVariants}
                  initial="initial"
                  animate={mode}
                >
                  <p className="dragger-label">{low}</p>
                  <p className="dragger-label">{high}</p>
                </motion.div>
                <Dragger
                  value={traitValue} interactive={interactive}
                  onChange={value => this.onChange(index, value)}
                />
              </Form.Group>
            )
          })}
        </div>
      </div>
    );
  }
}

TraitsDisplay.propTypes = {
  traits: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFetchItem: PropTypes.func,
  onChange: PropTypes.func,
  interactive: PropTypes.bool,
  title: PropTypes.string
};
TraitsDisplay.defaultProps = {
  onChange: () => {},
  interactive: false,
  title: 'Species Traits'
};

export default TraitsDisplay;
