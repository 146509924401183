import { createLeader, createPlayer, createSpecies } from '@pixelwelders/tlh-universe-data';
import * as MODES from '../../constants/modes';

import { RESET_SIGNUP_DATA, SET_SIGNUP_DATA } from '../actions/signUpActions';
import { SIGN_UP_BEGIN, SIGN_UP_ERROR, SIGN_UP_SUCCESS } from '../actions/serverActions';

const initialState = {
  // Actual call.
  signUpInProgress: false,

  player: createPlayer(),
  species: createSpecies(),
  leader: createLeader(),
  inventory: [],

  mode: MODES.SCANNING,
  error: null,
  scanComplete: false,
  fetchComplete: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SIGNUP_DATA: {
      const { payload: data } = action;
      const dataArray = Object.entries(data);
      const newState = dataArray.reduce((accum, [name, value]) => {
        return { ...accum, [name]: value }
      }, state)

      return newState;
    }

    case RESET_SIGNUP_DATA: {
      return initialState;
    }

    case SIGN_UP_BEGIN: {
      return { ...state, signUpInProgress: true };
    }

    case SIGN_UP_SUCCESS: {
      return { ...state, signUpInProgress: false };
    }

    case SIGN_UP_ERROR: {
      return { ...state, error: action.payload };
    }

    default: {
      return state;
    }
  }
};
