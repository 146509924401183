import { Modal } from 'react-bootstrap';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FiX as CloseIcon } from 'react-icons/fi';

import { getModal } from '../../store/selectors';
import { clearModal } from '../../store/actions/modalActions';
import * as MODALS from '../../constants/modals';

const GeneralModal = ({ modal, onClearModal }) => {
  console.log('modal', modal);
  const { type, content } = modal;

  const show = type === MODALS.TEXT;

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>{content && content.title}</Modal.Title>
        <CloseIcon className="close-btn" size={30} onClick={() => onClearModal()} />
      </Modal.Header>
      <Modal.Body>
        {content && content.body}
      </Modal.Body>
    </Modal>
  );
};

GeneralModal.propTypes = {
  onClearModal: PropTypes.func.isRequired
};

GeneralModal.defaultProps = {};

const mapStateToProps = state => ({
  modal: getModal(state),
});

const dispatchMap = {
  onClearModal: clearModal
};

export default connect(
  mapStateToProps,
  dispatchMap
)(GeneralModal);
