import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { fetchPosts } from '../../store/actions/serverActions';

import './redditPosts.scss';

class RedditPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      posts: [],
      error: '',
      cachedQuery: ''
    };
  }

  load = async () => {
    const { query, onFetchPosts } = this.props;
    const { cachedQuery, isLoading } = this.state;

    if (isLoading) {
      return;
    }

    if (cachedQuery === query) {
      return;
    }

    this.setState({ isLoading: true });
    try {
      console.log('RedditPosts: loading posts');
      const posts = await onFetchPosts({ query });
      this.setState({ posts })
    } catch (error) {
      this.setState({ error: error.toString() })
    } finally {
      this.setState({ isLoading: false, cachedQuery: query })
    }
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate() {
    this.load();
  }

  render() {
    const { isLoading, posts, error } = this.state;

    return (
      <>
        <h2>Articles</h2>
        {
          isLoading
            ? <p>Loading...</p>
            : error
              ? <p>{ error }</p>
                : posts.length
                ? (
                  <ul className="reddit-posts">
                    {posts.map(({ title, url }, index) => (
                      <li key={index}>
                        <img alt="reddit" className="img-icon" src="/images/reddit-50.png" width={20} height={20} />
                        <a href={url} target="_blank" rel="noopener noreferrer">{ title }</a>
                      </li>
                    ))}
                  </ul>
                )
                : (
                  <p className="reddit-posts">
                    <img alt="reddit" className="img-icon" src="/images/reddit-50.png" width={20} height={20} />
                    <a href="https://reddit.com/r/GalaxyNewsToday">Nothing yet!</a>
                  </p>
                )
        }
      </>
    );
  }
}

RedditPosts.propTypes = {
  query: PropTypes.string.isRequired,
  onFetchPosts: PropTypes.func.isRequired
};
RedditPosts.defaultProps = {};

const mapStateToProps = state => ({});
const dispatchMap = {
  onFetchPosts: fetchPosts
};

export default compose(
  connect(mapStateToProps, dispatchMap)
)(RedditPosts);
