import React from 'react';
import PropTypes from 'prop-types';
import * as MODES from '../../constants/modes';
import { AnimatePresence, motion } from 'framer-motion';

const gradients = {
  gray: { opacity: 1, background: 'linear-gradient(to bottom, #333333 0%, #333333 100%)' },
  bluegreen: { opacity: 1, background: 'linear-gradient(to bottom, #65BCC0 0%, #A5E6A9 100%)' },
  pink: { opacity: 1, background: 'linear-gradient(to bottom, #df6ebf 0%, #d6a683 100%)' },
  gold: { opacity: 1, background: 'linear-gradient(to bottom, #ff8fa6 0%, #F7D07A 100%)' },
  red: { opacity: 1, background: 'linear-gradient(to bottom, rgb(252, 86, 86) 0%, rgb(252, 86, 86) 100%)' }
};

const MultiModeBackground = ({ mode, children, className, display, transition }) => {
  return (
    <AnimatePresence>
      {
        display && (
          <motion.div
            className={className}
            variants={{
              [MODES.SCANNING]: gradients.gray,
              [MODES.VIEWING]: gradients.bluegreen,
              [MODES.EDITING]: gradients.bluegreen,
              [MODES.REVIEWING]: gradients.gold,
              [MODES.SUBMITTING]: gradients.pink,
              [MODES.HUMAN_DETECTED]: gradients.red,
              [MODES.HIDDEN]: { opacity: 0 }
            }}
            initial={{ background: 'linear-gradient(to bottom, #333333 0%, #333333 100%)' }}
            exit={{ opacity: 0, transitionEnd: { display: 'none' } }}
            transition={transition}
            animate={mode}
          >
            {children}
          </motion.div>
        )
      }
    </AnimatePresence>
  );
}

MultiModeBackground.propTypes = {
  mode: PropTypes.string.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  display: PropTypes.bool,
  transition: PropTypes.object
};
MultiModeBackground.defaultProps = {
  className: '',
  display: true,
  transition: { duration: 0.5 }
};

export default MultiModeBackground;
