import React from 'react';
import { connect } from 'react-redux';

import * as CURRENCIES from '../constants/currencies';
import { getCurrencyPerInterval } from '../util/getCurrencyPerInterval';
import { formatDictionaryNumber } from 'continuum-engine';
import { getEngine, getPrimaryCurrency, getSecondaryCurrency } from '../store/selectors/spaceRaceSelectors';

import './statsDisplay.scss';

const getStats = (producers, type, value, { skipRate = false } = {}) => {
  const currencyPerInterval = skipRate ? 0 : getCurrencyPerInterval({ producers, type });
  const valueStr = value > 999 ? formatDictionaryNumber(value, 2) : Math.floor(value);
  const rateStr = currencyPerInterval > 999
    ? formatDictionaryNumber(currencyPerInterval, 2)
    : currencyPerInterval < 1
      ? '< 1'
      : Math.floor(currencyPerInterval);

  return { value: valueStr, rate: rateStr };
};

const StatsDisplay = ({ primaryCurrency, secondaryCurrency, engine }) => {
  const { producers } = engine;
  const primaryCurrencyStats = getStats(producers, CURRENCIES.PRIMARY, primaryCurrency);
  const secondaryCurrencyStats = getStats(producers, CURRENCIES.SECONDARY, secondaryCurrency, { skipRate: true });

  return (
    <div className="stats-display">
      <div className="currency-display primary-currency">
        <span className="currency-label">Astronauts</span>
        <img className="currency-image" src="/images/EftSS/astronaut-circle-40.png"  alt="Astronauts"/>
        <span className="currency-container">
          <span className="currency-value">{primaryCurrencyStats.value}</span>
          <span className="currency-rate muted">{primaryCurrencyStats.rate} / year</span>
        </span>
      </div>

      <div className="flex-spacer" />

      <div className="currency-display secondary-currency">
        <span className="currency-label">Tech</span>
        <img className="currency-image" src="/images/EftSS/tech-circle-40.png"  alt="Tech"/>
        <span className="currency-container">
          <span className="currency-value">{secondaryCurrencyStats.value}</span>
        </span>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    engine: getEngine(state),
    primaryCurrency: getPrimaryCurrency(state),
    secondaryCurrency: getSecondaryCurrency(state)
  }),
  {}
)(StatsDisplay);
