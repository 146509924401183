import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { Form, Button } from 'react-bootstrap';

import * as ROUTES from '../../constants/routes';
import { SignUpLink } from './SignUp';
import { PasswordForgetLink } from '../Account/PasswordForget';
import MultiModeBackground from '../common/MultiModeBackground';
import * as MODES from '../../constants/modes';

import './signIn.scss';
import Error from './Error';
import beautifyError from '../../util/beautifyError';
import { doSignIn } from '../../store/actions/serverActions';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  }

  onSubmit = async (event) => {
    event.preventDefault();

    const { onDoSignIn } = this.props;
    const { email, password } = this.state;

    try {
      await onDoSignIn(email, password);
      this.setState({ ...INITIAL_STATE });
    } catch (error) {
      this.setState({ error });
    }
  }

  render() {
    // TODO Real validation.
    const { email, password, error } = this.state;
    const isValid = !!email && !!password;

    return (
      <Form className="sign-in-form" onSubmit={this.onSubmit}>

        {error && <Error error={beautifyError(error)} />}

        <Form.Group>
          <Form.Label>Email</Form.Label>
          <input
            name="email" autoComplete="email" onChange={this.onChange} type="email" placeholder="you@youremail.com"
            autoFocus
          />
        </Form.Group>

        <Form.Group className="password">
          <Form.Label>Password</Form.Label>
          <input name="password" autoComplete="current-password" onChange={this.onChange} type="password" placeholder="******"/>
        </Form.Group>

        <Button className="button-small light" type="submit" disabled={!isValid}>
          Submit
        </Button>

      </Form>
    );
  }
}

SignInFormBase.propTypes = {
  onDoSignIn: PropTypes.func.isRequired
};

const SignInForm = compose(
  connect(
    state => ({}),
    { onDoSignIn: doSignIn }
  )
)(SignInFormBase);

const SignInLink = () => (
  <Link
    to={ROUTES.SIGN_IN}
    style={{ textAlign: 'center', marginTop: 15, color: 'rgba(255, 255, 255. 0.7' }}
  >
    I've already registered my species!
  </Link>
);

const SignIn = (props) => {
  return (
    <MultiModeBackground mode={MODES.REVIEWING} className="v-padded-page sign-in common-page">
      <div className="scroller">
        <h1>Sign In</h1>
        <p className="intro">Welcome back! The galaxy has been a busy place in your absence.</p>
        <SignInForm />
        <SignUpLink />
        <PasswordForgetLink />
      </div>
    </MultiModeBackground>
  );
}

SignIn.propTypes = {};
SignIn.defaultProps = {};

export default SignIn;
export { SignInForm, SignInLink };
