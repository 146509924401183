export const getCurrencyPerInterval = ({ producers, type }) => {
  return Object.entries(producers).reduce((accum, [_, producer]) => {
    if (!producer.processingEnabled) {
      return accum;
    }

    const { outputs, count } = producer;
    if (!outputs.currencies[type]) {
      return accum;
    }

    const { productionTime, productionAmount } = outputs.currencies[type];
    const amountPerInterval = ((1000 / productionTime) * productionAmount) * count;

    return accum + amountPerInterval;
  }, 0);
};
