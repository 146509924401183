import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  RESET_GAME,
  SET_ENGINE,
  SET_ENTITY_COUNT,
  SET_ENTITY_META,
  SET_TUTORIAL, SR_CLEAR_MODAL,
  SR_SET_GAME_INFO, SR_SET_MODAL,
  SR_SET_PLAYER_INFO,
  UI_SET_EXPANDED,
  UI_SET_MODAL,
  UI_SET_TAB,
  UPDATE_UPGRADE
} from '../actions/spaceRaceActions';
import { INIT_GAME_COMPLETE, SET_CURRENCY } from '../actions/spaceRaceActions';
import * as CURRENCIES from '../../constants/currencies';
import * as TUTORIAL_STAGES from '../../constants/tutorialStages';
import { TABS } from '../../constants/ui';

/**
 * Gets the values of the params specified in one object, from another object.
 *
 * @param fromObj
 * @param inObj
 * @returns {{}}
 */
const getAllValues = (fromObj, inObj) => {
  return Object.keys(fromObj).reduce((accum, k) => ({ ...accum, [k]: inObj[k] }), {});
}

// let _engine;
// try {
//   _engine = createEngine();
// } catch (error) {
//   console.error('ERROR', error);
//   // TODO This is super dangerous.
//   _engine = createEngine({ reset: true });
// }

const initialState = {
  isInitialized: false,
  currencies: {
    // map of type: value
  },
  entities: {
    // key: {
    //   key, count, meta
    // }
  },
  upgrades: {
    // key: { isActive }
  },
  engine: null,
  notifications: {
    currentId: 0,
    all: []
  },
  ui: {
    tab: TABS.STRUCTURES,
    isExpanded: false,
    modal: undefined
  },
  tutorial: {
    stage: TUTORIAL_STAGES.CLICK
  },
  playerInfo: {
    leader: undefined
  },
  gameInfo: {
    isStarted: false
  },
  modal: {
    type: undefined
  }
};

// const entityInitialState = {
//   key: null,
//   count: 0,
//   meta: {},
//   outputs: {
//     // currencies: {
//     //   [CURRENCIES.PRIMARY]:   { productionTime, productionAmount },
//     //   [CURRENCIES.SECONDARY]: { productionTime, productionAmount }
//     // }
//   }
// };

const upgradeInitialState = {
  isActive: false
};

const isInitialized = (state = initialState.isInitialized, action) => {
  switch (action.type) {
    case INIT_GAME_COMPLETE: {
      return true;
    }

    case RESET_GAME: {
      return initialState.isInitialized;
    }

    default: {
      return state;
    }
  }
};

const currencies = (state = initialState.currencies, action) => {
  switch (action.type) {
    case SET_ENGINE: {
      // Get the currencies out of the engine.
      const engine = action.payload;
      const newState = Object.values(engine.currencies).reduce((accum, { type, value }) => ({
        ...accum, [type]: value
      }), {});
      return newState;
    }

    // case ADD_CURRENCY:
    case SET_CURRENCY: {
      const { type, value } = action.payload;
      return { ...state, [type]: value };
    }

    case RESET_GAME: {
      return initialState.currencies;
    }

    default: {
      return state;
    }
  }
};

const entities = (state = initialState.entities, action) => {
  switch (action.type) {
    case SET_ENGINE: {
      // TODO For now, this only does producers.
      const engine = action.payload;
      const newState = Object.values(engine.producers).reduce((accum, { key, count, meta, outputs }) => ({
        ...accum,
        [key]: {
          key, count, meta, outputs
        }
      }), {});
      return newState;
    }

    // case ADD_ENTITY: {
    //   const { key, meta, count } = action.payload;
    //   return {
    //     ...state,
    //     [key]: {
    //       ...entityInitialState,
    //       count,
    //       key,
    //       meta
    //     }
    //   }
    // }

    case SET_ENTITY_COUNT: {
      // eslint-disable-next-line no-unused-vars
      const { obj, key, count, delta } = action.payload;
      const entityInfo = state[key];
      return { ...state, [key]: { ...entityInfo, count }};
    }

    case SET_ENTITY_META: {
      // eslint-disable-next-line no-unused-vars
      const { obj, delta, key } = action.payload;
      const entityInfo = state[key];

      return { ...state, [key]: { ...entityInfo, meta: obj.meta }};
    }

    case RESET_GAME: {
      return initialState.entities;
    }

    default: {
      return state;
    }
  }
};

const upgrades = (state = initialState.upgrades, action) => {
  switch (action.type) {
    case SET_ENGINE: {
      const engine = action.payload;
      const newState = Object.values(engine.upgrades).reduce((accum, upgrade) => ({
        ...accum,
        [upgrade.key]: getAllValues(upgradeInitialState, upgrade)
      }), {});
      return newState;
    }
    // case ADD_UPGRADE: {
    //   const upgrade = action.payload;
    //   return {
    //     ...state,
    //     [upgrade.key]: getAllValues(upgradeInitialState, upgrade)
    //   };
    // }

    case UPDATE_UPGRADE: {
      const { obj, key } = action.payload;
      return {
        ...state,
        [key]: getAllValues(upgradeInitialState, obj)
      };
    }

    case RESET_GAME: {
      return initialState.upgrades;
    }

    default: {
      return state;
    }
  }
};

const engine = (state = initialState.engine, action) => {
  switch (action.type) {
    case SET_ENGINE: {
      return action.payload;
    }

    case RESET_GAME: {
      return initialState.engine;
    }

    default: {
      return state;
    }
  }
};

const notifications = (state = initialState.notifications, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const { currentId, all } = state;
      console.log('adding notification', action.payload);
      return {
        ...state,
        currentId: currentId + 1,
        all: [ ...all, action.payload ]
      };
    }

    case REMOVE_NOTIFICATION: {
      const { id: removedId } = action.payload;
      const { all: oldAll } = state;
      const all = oldAll.filter(({ id }) => id !== removedId);
      return { ...state, all };
    };

    // Notifications don't reset entirely.
    case RESET_GAME: {
      return { ...initialState.notifications, currentId: state.currentId };
    }

    default: {
      return state;
    }
  }
};

/**
 * @param state
 * @param action
 * @param state - the full state
 * @returns {{isExpanded: boolean, tab: *}|{isExpanded: boolean}|initialState.ui|{isExpanded, tab, modal}|{isExpanded: *}|{modal: *}}
 */
const ui = (state = initialState.ui, action, completeState) => {
  switch (action.type) {
    case UI_SET_EXPANDED: {
      return { ...state, isExpanded: action.payload };
    }

    case UI_SET_TAB: {
      return { ...state, tab: action.payload, isExpanded: true };
    }

    case UI_SET_MODAL: {
      return { ...state, modal: action.payload };
    }

    // TUTORIAL TRIGGERS
    case SET_ENTITY_COUNT: {
      if (completeState.tutorial.stage === TUTORIAL_STAGES.BUILD) {
        // Close the drawer so we can see what's next.
        return { ...state, isExpanded: false };
        // const { type, value, obj: producer } = action.payload;
      }
      console.log('ACTION', action);
      return state;
    }

    default: {
      return state;
    }
  }
};

const tutorial = (state = initialState.tutorial, action) => {
  switch (action.type) {
    case SET_TUTORIAL: {
      return action.payload;
    }

    case SET_CURRENCY: {
      // Stage 0 ends when triggered by currency.
      if (state.stage === TUTORIAL_STAGES.CLICK) {
        // eslint-disable-next-line no-unused-vars
        const { type, value, obj: currency } = action.payload;
        if (type === CURRENCIES.PRIMARY && value >= 15) {
          return { ...state, stage: state.stage + 1 };
        }
      }

      // if (state.stage === TUTORIAL_STAGES.SUCCESS) {
      //   const { type, value, obj: currency } = action.payload;
      //   if (type === CURRENCIES.PRIMARY && value >= 50) {
      //     return { ...state, stage: state.stage + 1 };
      //   }
      // }

      if (state.stage === TUTORIAL_STAGES.RESEARCH) {
        // eslint-disable-next-line no-unused-vars
        const { type, value, obj: currency } = action.payload;
        if (type === CURRENCIES.TECH_PROGRESS) {
          return { ...state, stage: state.stage + 1 };
        }
      }

      if (state.stage === TUTORIAL_STAGES.RESEARCH2) {
        // eslint-disable-next-line no-unused-vars
        const { type, value, obj: currency } = action.payload;
        if (type === CURRENCIES.PRIMARY && value >= 100) {
          return { ...state, stage: state.stage + 1 };
        }
      }

      // FINAL ESCAPE VALVE
      // eslint-disable-next-line no-unused-vars
      const { type, value, obj: currency } = action.payload;
      if (type === CURRENCIES.PRIMARY && value >= 200) {
        return { ...state, stage: TUTORIAL_STAGES.DONE };
      }

      return state;
    }

    case SET_ENTITY_COUNT: {
      // Stage 1 ends when triggered by a purchase.
      if (state.stage === TUTORIAL_STAGES.BUILD) {
        return { ...state, stage: state.stage + 1 };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

const playerInfo = (state = initialState.playerInfo, action) => {
  switch (action.type) {
    case SR_SET_PLAYER_INFO: {
      return {
        ...state,
        ...action.payload
      };
    }

    default: {
      return state;
    }
  }
};

const gameInfo = (state = initialState.gameInfo, action) => {
  switch (action.type) {
    case SR_SET_GAME_INFO: {
      return { ...state, ...action.payload };
    }

    default: {
      return state;
    }
  }
};

const modal = (state = initialState.modal, action, completeState) => {
  switch (action.type) {
    case SR_SET_MODAL: {
      return action.payload;
    }

    case SR_CLEAR_MODAL: {
      return initialState.modal;
    }

    default: return state;
  }
};

export default (state = initialState, action) => {
  return {
    isInitialized: isInitialized(state.isInitialized, action),
    currencies: currencies(state.currencies, action),
    entities: entities(state.entities, action),
    upgrades: upgrades(state.upgrades, action),
    engine: engine(state.engine, action),
    notifications: notifications(state.notifications, action),
    ui: ui(state.ui, action, state),
    tutorial: tutorial(state.tutorial, action),
    playerInfo: playerInfo(state.playerInfo, action),
    gameInfo: gameInfo(state.gameInfo, action, state),
    modal: modal(state.modal, action, state)
  };
};
