import { SET_SEARCH } from '../actions/searchActions';

const initialState = {
  filter: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};
