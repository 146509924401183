const ROOT_KEY = 'spaceRace';
const DEFAULT_USER_KEY = 'defaultUser';
const DEFAULT_GAME_KEY = 'defaultGame';

const getKey = (userId, gameKey) => {
  return `${ROOT_KEY}.${userId || DEFAULT_USER_KEY}.${gameKey || DEFAULT_GAME_KEY}`;
}

const loadState = (userId, gameKey) => {
  const key = getKey(userId, gameKey);
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

const saveState = (state, userId, gameKey) => {
  const key = getKey(userId, gameKey);
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (error) {
    console.error(error);
  }
};

const resetState = (userId, gameKey) => {
  const key = getKey(userId, gameKey);
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
}

export { loadState, saveState, resetState };
