import * as THREE from 'three/build/three.module';

const ARRANGEMENT_TYPES = {
  SPHERE: 'sphere',
  HELIX: 'helix',
  CYLINDER: 'cylinder',
  GRID: 'grid',
  MESS: 'mess',
  MESS_UP: 'messUp',
  MESS_DOWN: 'messDown'
};

const createRadialArrangement = (objects, params, getY) => {
  const { radius, numPerLevel } = params;
  const thetaPerItem = Math.PI / (numPerLevel / 2);
  const arrangement = [];
  let vector = new THREE.Vector3();

  for ( let i = 0, l = objects.length; i < l; i ++ ) {
    const theta = (i * thetaPerItem + Math.PI) - Math.PI;
    const y = getY(i);
    const object = new THREE.Object3D();
    object.position.setFromCylindricalCoords(radius, theta, y);
    vector.x = object.position.x * 2;
    vector.y = object.position.y;
    vector.z = object.position.z * 2;
    object.lookAt( vector );
    arrangement.push( object );
  }

  return arrangement;
}

const createArrangement = (objects, type, params) => {
  let arrangement = [];
  let vector = new THREE.Vector3();

  switch (type) {
    case ARRANGEMENT_TYPES.SPHERE: {
      vector = new THREE.Vector3();
      for (let i = 0, l = objects.length; i < l; i ++) {
        const phi = Math.acos( - 1 + ( 2 * i ) / l );
        const theta = Math.sqrt( l * Math.PI ) * phi;
        const object = new THREE.Object3D();
        object.position.setFromSphericalCoords( 800, phi, theta );
        vector.copy( object.position ).multiplyScalar( 2 );
        object.lookAt( vector );
        arrangement.push( object );
      }

      break;
    }

    case ARRANGEMENT_TYPES.HELIX: {
      const { dropPerItem } = params;
      arrangement = createRadialArrangement(
        objects, params, index => -(index * dropPerItem) + 0
      )
      console.log('arrangement', arrangement);

      break;
    }

    case ARRANGEMENT_TYPES.CYLINDER: {
      vector = new THREE.Vector3();
      const { numPerLevel, dropPerLevel } = params;
      arrangement = createRadialArrangement(
        objects, params, index => Math.floor(index / numPerLevel) * -dropPerLevel
      )

      break;
    }

    case ARRANGEMENT_TYPES.GRID: {
      for ( let i = 0; i < objects.length; i ++ ) {
        const object = new THREE.Object3D();
        object.position.x = ( ( i % 5 ) * 400 ) - 800;
        object.position.y = ( - ( Math.floor( i / 5 ) % 5 ) * 400 ) + 800;
        object.position.z = ( Math.floor( i / 25 ) ) * 1000 - 2000;
        arrangement.push( object );
      }

      break;
    }

    case ARRANGEMENT_TYPES.MESS: {
      for ( let i = 0; i < objects.length; i ++ ) {
        const object = new THREE.Object3D();
        object.position.x = Math.random() * 2000 - 1000;
        object.position.y = Math.random() * 2000 - 1000;
        object.position.z = Math.random() * 2000 - 1000;
        object.rotation.x = Math.random() * (2 * Math.PI);
        object.rotation.y = Math.random() * (2 * Math.PI);
        object.rotation.z = Math.random() * (2 * Math.PI);
        arrangement.push(object);
      }

      break;
    }

    case ARRANGEMENT_TYPES.MESS_UP: {
      for ( let i = 0; i < objects.length; i ++ ) {
        const object = new THREE.Object3D();
        object.position.x = Math.random() * 2000 - 1000;
        object.position.y = Math.random() * 1000;
        object.position.z = Math.random() * 2000 - 1000;
        object.rotation.x = Math.random() * (2 * Math.PI);
        object.rotation.y = Math.random() * (2 * Math.PI);
        object.rotation.z = Math.random() * (2 * Math.PI);
        arrangement.push(object);
      }

      break;
    }

    case ARRANGEMENT_TYPES.MESS_DOWN: {
      for ( let i = 0; i < objects.length; i ++ ) {
        const object = new THREE.Object3D();
        object.position.x = Math.random() * 2000 - 1000;
        object.position.y = Math.random() * -1000 - 1000;
        object.position.z = Math.random() * 2000 - 1000;
        object.rotation.x = Math.random() * (2 * Math.PI);
        object.rotation.y = Math.random() * (2 * Math.PI);
        object.rotation.z = Math.random() * (2 * Math.PI);
        arrangement.push(object);
      }

      break;
    }

    default: {

    }
  }

  return arrangement;
}

export { createArrangement, ARRANGEMENT_TYPES };
