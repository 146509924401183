import { createPlayer } from '@pixelwelders/tlh-universe-data';

import { CLEAR_PLAYER, SET_PLAYER } from '../actions/playerActions';

// TODO Consider a larger containing object so we can specify if it's been loaded or not.
const initialState = createPlayer();

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAYER: {
      const { player } = action.payload;
      return player ? player : initialState;
    }

    case CLEAR_PLAYER: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
