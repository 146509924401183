import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { getAuthUser } from '../../store/selectors/authSelectors';
import { setModal } from '../../store/actions/modalActions';

import './intro.scss';
import * as MODALS from '../../constants/modals';

const Slide1 = ({ authUser, onNext }) => {
  return (
    <div className="intro-content">
      <img className="gr-logo" src="/images/gr-logo.png" alt="Galactic Registry Logo"/>
      <p className="intro-subtitle">
        Every intelligent species in the galaxy.
        One handy interactive reference.
      </p>
      <Link className="button" to={ROUTES.REGISTRY_ROOT}>Explore</Link>
      {authUser
        ? (<Link className="button" to={ROUTES.HOME}>Your Species</Link>)
        : (<Link className="button" to={ROUTES.SIGN_UP}>Add Your Species!</Link>)
      }

      {!authUser && (<Link className="sign-in-link" to={ROUTES.SIGN_IN}>Sign In</Link>)}
    </div>
  );
};

const Slide2 = ({ onSetModal }) => {
  return (
    <div className="intro-content">
      <img className="gr-logo" src="/images/gr-logo.png" alt="Galactic Registry Logo"/>
      <Link className="button" to={ROUTES.SPACE_RACE}>Play the Game!</Link>
      <Link className="button" to={ROUTES.SIGN_UP}>Just Make Contact</Link>
    </div>
  );
}

const getBackground = (numBackgrounds = 5) => {
  const backgroundNum = Math.floor(Math.random() * numBackgrounds);
  const filename = `/images/backgrounds/background-${backgroundNum < 9 ? '0' : ''}${backgroundNum}.jpg`;
  return filename;
}

const Intro = ({ authUser, onSetModal }) => {
  const [mode, setMode] = useState(1);
  const [background] = useState(getBackground());

  return (
    <div className="intro-page">
      <div className="background-image" style={{ backgroundImage: `url(${background})` }}/>
      <div className="intro-container">
        {mode === 1 && (
          <Slide1 authUser={authUser} onNext={() => onSetModal({ type: MODALS.NEW_SPECIES })}/>
        )}
        {mode === 2 && (<Slide2 />)}
      </div>
    </div>
  );
}

export default connect(
  state => ({
    authUser: getAuthUser(state)
  }),
  {
    onSetModal: setModal
  }
)(Intro);
