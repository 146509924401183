/**
 * Creates a random number but remembers it.
 * @param speciesName - the species name to key it to.
 */
const cache = {};
export default (key, { min = 0, max = 100 } = {}) => {
  if (!cache[key]) {
    const range = max - min;
    cache[key] = Math.floor(Math.random() * range) + min;
  }

  return cache[key];
};
