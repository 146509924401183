import {
  AUTH_CLEAR_USER,
  AUTH_SET_IS_CREATING_PLAYER,
  AUTH_SET_LAST_ATTEMPT_FAILED,
  AUTH_SET_USER
} from '../actions/authActions';

const initialState = {
  authUser: null,
  isCreatingPlayer: false,
  lastAttemptFailed: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SET_USER: {
      return {
        ...state,
        authUser: action.payload.authUser
      }
    }

    case AUTH_CLEAR_USER: {
      return initialState;
    }

    case AUTH_SET_IS_CREATING_PLAYER: {
      return {
        ...state,
        isCreatingPlayer: action.payload.isCreatingPlayer
      }
    }

    case AUTH_SET_LAST_ATTEMPT_FAILED: {
      return {
        ...state,
        lastAttemptFailed: action.payload
      }
    }

    default: {
      return state;
    }
  }
};
