import { createLeader } from '@pixelwelders/tlh-universe-data';

import { ADD_LEADERS, CLEAR_LEADERS, SET_LEADER } from '../actions/leaderActions';
import { DEFAULT_UID, DEFAULT_SPECIES } from '../../constants/DefaultValues';

const initialState = {
  // [DEFAULT_SPECIES]: createLeader({ player: DEFAULT_UID })
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_LEADERS: {
      const { payload } = action;
      return { ...state, ...payload };
    }

    case CLEAR_LEADERS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
