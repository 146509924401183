export const SET_MODAL = 'modal.set';
export const setModal = ({ type, content }) => ({
  type: SET_MODAL,
  payload: { type, content }
});

export const CLEAR_MODAL = 'modal.clear';
export const clearModal = () => ({
  type: CLEAR_MODAL
});
