import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import * as THREE from 'three/build/three.module';
// import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { CSS3DRenderer, CSS3DObject } from '../../third-party/three/renderers/CSS3DRenderer';
import { motion } from 'framer-motion';
import { clamp } from '@pixelwelders/tlh-universe-util'
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { FaCaretUp as UpIcon, FaCaretDown as DownIcon } from 'react-icons/fa';
import { FiSearch as SearchIcon, FiXCircle as ClearIcon } from 'react-icons/fi';
import SwipeListener from 'swipe-listener';

import { OrbitControls as CustomControls } from '../../third-party/three/controls/Controls';
import { createArrangement, ARRANGEMENT_TYPES } from './3dArrangements';
import * as ROUTES from '../../constants/routes';
import SpeciesPageShell from '../SpeciesPage/SpeciesPageShell';
import { getPlayer, getSearch } from '../../store/selectors';
import { setSearch } from '../../store/actions/searchActions';
import { getItemIndices } from '../../store/selectors/registrySelectors';

//                 0     1      2     3     4  5     6  7   8   9  10    11 12 13 14 15
//    0: matrix3d( 1,    0,     0.00, 0, 0.00, 1, 0.00, 0,  0,  0,  1,    0, 0, 0, 0, 1)
//   90: matrix3d( 0,    0,     1.00, 0, 0.00, 1, 0.00, 0, -1,  0,  0,    0, 0, 0, 0, 1)
//   91: matrix3d(-0.02, 0,     1.00, 0, 0.00, 1, 0.00, 0, -1,  0, -0.02, 0, 0, 0, 0, 1)
//  180: matrix3d(-1,    0,     0.00, 0, 0.00, 1, 0.00, 0,  0,  0, -1,    0, 0, 0, 0, 1)
// -180: matrix3d(-1,    0,    -0.00, 0, 0.00, 1, 0.00, 0,  0,  0, -1,    0, 0, 0, 0, 1)
//  269: matrix3d(-0.02, 0,    -1.00, 0, 0.00, 1, 0.00, 0,  1,  0, -0.02, 0, 0, 0, 0, 1)
//  270: matrix3d( 0,    0,    -1.00, 0, 0.00, 1, 0.00, 0,  1,  0,  0,    0, 0, 0, 0, 1)
//  271: matrix3d( 0.02, 0,    -1.00, 0, 0.00, 1, 0.00, 0,  1,  0,  0.02, 0, 0, 0, 0, 1)

// -90: matrix3d( 0,    0, -1.00, 0, 0.00, 1, 0.00, 0,  1, 0,  0, 0, 0, 0, 0,   1)
//  FR: matrix3d(... 0.707107, ...) // 10
//  BA: matrix3d(... -0.707107, ...) // 10
//  controls forwards: matrix3d(0.92388,   0,  0.382683, 0, 0, -1, 0, 0, -0.382683, 0, 0.92388,   0, -191.342, 12,   461.94, 1)
// controls backwards: matrix3d(-0.382683, 0, -0.92388,  0, 0, -1, 0, 0,  0.92388,  0, -0.382683, 0, 461.94,   -60, -191.342, 1)

//  controls forwards: matrix3d(0,         0, -1,        0, 0, -1, 0, 0,    1,      0,  0,        0,  500,      -48,    0,     1)
// controls backwards: matrix3d(0,         0,  1,        0, 0, -1, 0, 0,   -1,      0,  0,        0, -500,       48,    0,     1)

let renderer;
let scene;
let camera;
let controls;
let structure;
let itemRenderers;
let reserveItemRenderers;
let animationTargets;

let items;
let itemIndices;
let history;
// let onClose;

// Must match element css in speciesList3D.scss
const itemHeight = 126;
const itemWidth = 120;
const pageSize = 16; //32;
const numDisplayedPages = 4;
const numDisplayed = pageSize * numDisplayedPages;
const radius = 500; //900;
const dropPerLevel = itemHeight * 1.2;
const dropPerItem = dropPerLevel / pageSize;

const maxWidth = 414;
const _inDistance = radius * 2;//1.34;
const _outDistance = radius * 4;//5;
const getRatio = () => window.innerWidth / maxWidth;
const getInverseRatio = () => maxWidth / window.innerWidth;
const getInDistance = () => _inDistance * getRatio();
const getOutDistance = () => _outDistance * getInverseRatio();

const domNodes = [];
let currentLevel = 0;
let currentIndex = 0;
let currentShift = 0;
let shiftTarget = currentShift;
let zoomed = false;

const render = () => {
  renderer.render(scene, camera);
}

const animate = () => {
  requestAnimationFrame(animate);
  TWEEN.update();
  controls.update();
}

const fade = ({ objects, opacity, duration = 500, easing = TWEEN.Easing.Linear.None, delay = 0 }) => {
  return new Promise((resolve, reject) => {
    objects.forEach(object => {
      if (object.element.style.opacity === '') {
        object.element.style.opacity = 1;
      }
      new TWEEN.Tween(object.element.style)
        .to({ opacity: opacity })
        .duration(duration)
        .delay(delay)
        .easing(easing)
        .onComplete(resolve)
        .start();
    })
  });
};

const transform = ({
  objects, targets,
  duration = 1000, delay = 0,
  easing = TWEEN.Easing.Exponential.InOut,
  opacity
}) => {
  return new Promise((resolve, reject) => {
    TWEEN.removeAll();
    const durationRange = duration * 0.3;
    const minDuration = duration * 0.5;
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      const target = targets[i];
      const randomDuration = Math.random() * durationRange + minDuration;
      new TWEEN.Tween(object.position)
        .to({ x: target.position.x, y: target.position.y, z: target.position.z }, randomDuration)
        .easing(easing)
        .delay(delay)
        .start();
      new TWEEN.Tween(object.rotation)
        .to({ x: target.rotation.x, y: target.rotation.y, z: target.rotation.z }, randomDuration)
        .delay(delay)
        .easing(easing)
        .start();
      if (opacity) {
        new TWEEN.Tween({ opacity: opacity[0] })
          .to({ opacity: opacity[1] }, randomDuration)
          .delay(delay)
          .easing(easing)
          .onUpdate(({ opacity }) => object.element.style.opacity = opacity)
          .start();
      }
    }
    new TWEEN.Tween(this)
      .to({}, duration * 1.2) // NO EFFING IDEA
      .onUpdate(render)
      .onComplete(resolve)
      .start();
  });
}

// TODO Rotation can go the wrong way.
const rotate = ({ index, duration = 500, delay = 0 }) => {
  return new Promise((resolve, reject) => {
    // if (index === currentIndex) {
    //   return resolve(); // TODO What if between?
    // }
    currentIndex = index;
    let percent = currentIndex / pageSize;
    if (percent > 0.5) {
      percent -= 1;
    }

    const targetPosition = (currentLevel * dropPerLevel) + (dropPerLevel * percent);
    let targetRotation = (Math.PI * 2) * -percent;// - controls.object.rotation.y;
    const angle = controls.getAzimuthalAngle();
    targetRotation += angle;

    new TWEEN.Tween({ rotation: structure.rotation.y, position: structure.position.y })
      .to({ rotation: targetRotation, position: targetPosition }, duration)
      .delay(delay)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onUpdate(({ rotation, position }) => {
        structure.rotation.y = rotation;
        structure.position.setY(position);
        render();
      })
      .onComplete(resolve)
      .start();
  });
}

let moveTween;
const move = ({ level, duration = 500, delay = 0 }) => {
  return new Promise((resolve, reject) => {
    const lowerLimit = 0;
    const upperLimit = numDisplayedPages;
    if (level > upperLimit || level < lowerLimit || level === currentLevel) {
      return resolve();
    }
    if (moveTween) {
      moveTween.stop();
    }

    currentLevel = level;
    const start = structure.position.y;
    const target = currentLevel * dropPerLevel;
    moveTween = new TWEEN.Tween({ y: start })
      .to({ y: target }, duration)
      .delay(delay)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onUpdate(({ y }) => {
        structure.position.setY(y);
        render();
      })
      .onComplete(resolve)
      .start()
  })
};

const zoom = ({
  fromDistance = controls.getSphericalRadius(), toDistance, delay = 0, rotate: _doRotate = false, rotateTarget = 0, duration = 750,
  easing = TWEEN.Easing.Exponential.InOut
}) => {
  return new Promise((resolve, reject) => {
    if (controls.maxDistance === toDistance) {
      return resolve();
    }

    if (_doRotate) {
      const percent = structure.rotation.y / (Math.PI * 2);
      const newCurrentIndex = Math.round(pageSize * percent);
      rotate({ index: newCurrentIndex });
    }

    new TWEEN.Tween({ distance: fromDistance })
      .to({ distance: toDistance }, duration)
      .easing(easing)
      .delay(delay)
      .onUpdate(({ distance }) => {
        controls.minDistance = distance;
        controls.maxDistance = distance;
      })
      .onComplete(() => {
        zoomed = fromDistance > toDistance;
        if (!zoomed) {
          controls.minDistance = getInDistance();
          controls.maxDistance = getOutDistance();
        }
        resolve();
      })
      .start();
  });
};

const sort = async () => {
  currentShift = 0;
  // await zoom({ toDistance: getOutDistance() });
  await rotate({ index: 0 });
  await transform({ objects: itemRenderers, targets: animationTargets.grid, duration: 1000, easing: TWEEN.Easing.Exponential.Out });
  await transform({ objects: itemRenderers, targets: animationTargets.helix, duration: 1000 });
};

const Element = ({ width, height, item, player }) => {
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  };

  const selected = item && (item.player === player.player);
  const isExtinct = item && !!item.isExtinct;

  const select = (event) => {
    // const item = items[itemIndex];
    // console.log('select', itemIndex, item);
    // TODO This may be a problem since history.location could be incorrect
    if (item) {
      history.push({ ...history.location, pathname: `${ROUTES.REGISTRY_ROOT}/${item.name}` });
    } else {
      console.log('No item!');
    }
    if (zoomed) {
      // selectSpin(event);
    } else {
      // selectZoom(itemIndex);
    }
  };

  return (
    <motion.div
      className={`element${selected ? ' selected' : ''}${isExtinct ? ' extinct' : ''}`}
      style={{ width, height }}
      variants={variants}
      initial={'visible'}
      animate={item ? 'visible' : 'hidden'}
      onClick={select}
    >
      {item && (
        <>
          <div className="element-header">
            <span className="tier-display">{`Tier ${item.tier}`}</span>
            {/*<span className="inventory-display">{item.inventory.length}</span>*/}
          </div>
          <div className="element-content">
            <p className="species-name">{item.displayName}</p>
          </div>
        </>
      )}
    </motion.div>
  );
}

const createDomNode = (props, index) => {
  const domNode = document.createElement('div');
  domNodes.push(domNode);

  return domNode;
}

let firstTimeInitialized = false;
let currentSpecies;
const setFromHistory = async (location, action) => {
  const { pathname } = location;
  console.log('setting from history', pathname);
  if (pathname === ROUTES.REGISTRY_ROOT) {
    console.log('zoom out from url');
    currentSpecies = null;
    // await zoom({ toDistance: getOutDistance() });
  } else {
    const speciesName = location.pathname.split('/').pop();
    const itemIndex = itemIndices[speciesName];
    currentSpecies = items[itemIndex];
    if (itemIndex === undefined) {
      // TODO Redirect?
      console.log('item', speciesName, 'not found');
    } else {
      console.log('zoom to item', itemIndex);
      // await selectZoom(itemIndex);
    }
  }
}

const initialize = ({ _items, _itemIndices, _history }) => {
  items = _items;
  itemIndices = _itemIndices; //items.reduce((accum, item, index) => ({ ...accum, [item.name]: index }), {});
  history = _history;

  if (!firstTimeInitialized) {
    // onClose = () => history.push({ ...history.location, pathname: ROUTES.REGISTRY_ROOT });
    // TODO This could be fragile because we don't know how long setup will take.
    setTimeout(() => setFromHistory(history.location), 1000);
    firstTimeInitialized = true;
  }
};

/**
 * @param shift - -1, 0, or 1
 */
const getMaxShift = () => {
  return Math.max(0, Math.ceil((items || []).length / pageSize - numDisplayedPages));
}
const ReactElements = ({
  items: _items, itemIndices: _itemIndices, history: _history, shift: _shift = 0, onDisplay, player
}) => {
  // TODO This renders too often because it will render on a click because of history.
  // TODO Animate if we get an external link.
  initialize({ _items, _itemIndices, _history });

  // const shift = clamp(_shift, -1, 1);
  const shift = shiftTarget - currentShift;
  const maxShift = getMaxShift();
  const nextShift = clamp(currentShift + shift, 0, maxShift);

  console.log('ReactElements', _shift, currentShift, shift, maxShift, nextShift);

  // Update buttons the hard way.
  const shiftUpBtn = document.getElementById('ctrl-shift-up');
  const shiftDownBtn = document.getElementById('ctrl-shift-down');

  shiftUpBtn.style.opacity = nextShift < maxShift ? 1 : 0;
  shiftDownBtn.style.opacity = nextShift > 0 ? 1 : 0;

  let startIndex = nextShift * pageSize; // 0, 32, 64, 96...
  let displayedItems = [];
  let animTargets = [];
  let displayedItemRenderers = [];
  let animation;

  // Find the displayed items in the data.
  // Create an array of item renderers that matches the data.
  // Grab animation targets to match.
  if (shift === 0 || nextShift === currentShift) {
    // TODO Theoretically ONLY first time. Otherwise we shouldn't reach this code.
    displayedItems = items.slice(startIndex, startIndex + numDisplayed);
    displayedItemRenderers = [...itemRenderers];
  } else if (shift === 1) {
    // We should have a grand total of pageSize * (numDisplayedPages + 1), or 160.

    // Grab the data for the main item renderers
    startIndex = nextShift * pageSize;
    displayedItems = items.slice(startIndex, startIndex + numDisplayed);

    // Grab the data for the departing item renderers
    const departingItems = items.slice(startIndex - pageSize, startIndex);

    // Put the data together so we get [...departing, ...current]
    displayedItems.unshift(...departingItems);

    // console.log(`startIndex: ${startIndex} | data: ${displayedItems.length} | ${displayedItems[0].displayName} - ${displayedItems[displayedItems.length - 1].displayName}`);

    // Create an array with the correct itemRenderers, in the correct order.
    displayedItemRenderers = [...itemRenderers, ...reserveItemRenderers];

    animation = async () => {
      // Prepare animation targets for all renderers.
      // Old renderers disappear upwards, so grab some messUp renderers for targets.
      const num = Math.floor(Math.max(Math.random() * animationTargets.messUp.length - pageSize, 0));
      const reserves = animationTargets.messUp.slice(num, num + pageSize);
      animTargets.push(...reserves);
      // All other renderers go to their places in the helix.
      animTargets.push(...animationTargets.helix);
      // Some will need to be faded in.
      // const opacities = displayedItemRenderers.reduce((accum, object) => ([...accum, object.element.style.opacity]), []);
      // console.log('opacities', opacities);

      // Now rearrange itemRenderers to match.
      reserveItemRenderers = displayedItemRenderers.slice(0, pageSize);
      itemRenderers = displayedItemRenderers.slice(pageSize);

      const newItemRenderers = displayedItemRenderers.slice(-pageSize);

      // Do the transform.
      await transform({ objects: newItemRenderers, targets: animationTargets.messDown, duration: 1 });
      transform({ objects: displayedItemRenderers, targets: animTargets, easing: TWEEN.Easing.Exponential.Out });
      fade({ objects: newItemRenderers, opacity: 1, duration: 250 });
      fade({ objects: reserveItemRenderers, opacity: 0, duration: 250 });
    }
  } else if (shift === -1) {
    startIndex = nextShift * pageSize;
    displayedItems = items.slice(startIndex, startIndex + numDisplayed);

    // Grab the data for the departing item renderers
    const departingItems = items.slice(startIndex + numDisplayed, startIndex + numDisplayed + pageSize);

    // Put the data together so we get [...current, ...departing]
    displayedItems.push(...departingItems);

    // Create an array with the correct itemRenderers, in the correct order.
    displayedItemRenderers = [...reserveItemRenderers, ...itemRenderers];

    animation = async () => {
      // Prepare animation targets for all renderers.
      // Normal renderers go to their places in the helix.
      animTargets.push(...animationTargets.helix);

      // Old renderers disappear downwards, so grab some messDown renderers for targets
      const num = Math.floor(Math.max(Math.random() * animationTargets.messDown.length - pageSize, 0));
      const reserves = animationTargets.messDown.slice(num, num + pageSize)
      animTargets.push(...reserves);

      // Now rearrange itemRenderers to match.
      reserveItemRenderers = displayedItemRenderers.slice(-pageSize);
      itemRenderers = displayedItemRenderers.slice(0, numDisplayed);

      const newItemRenderers = displayedItemRenderers.slice(0, pageSize);

      // Do the transform.
      await transform({ objects: newItemRenderers, targets: animationTargets.messUp, duration: 1 });
      transform({ objects: displayedItemRenderers, targets: animTargets, easing: TWEEN.Easing.Exponential.Out });
      fade({ objects: newItemRenderers, opacity: 1, duration: 250 });
      fade({ objects: reserveItemRenderers, opacity: 0, duration: 250 });
    }
  }

  currentShift = nextShift;

  // console.log(`shift: ${shift} (${currentShift}/${maxShift}), startIndex: ${startIndex}, displayedItems: ${displayedItems.length}, ${displayedItems[0].displayName} - ${displayedItems[displayedItems.length - 1].displayName}`);

  if (animation) {
    // setTimeout(animation, 1);
    requestAnimationFrame(animation);
    // animation();
  }

  setFromHistory(_history.location);
  return (
    <>
      <SpeciesPageShell species={currentSpecies} />
      {displayedItemRenderers.map(({ element }, index) => ReactDOM.createPortal(
        <Element item={displayedItems[index]} player={player} />,
        element
      ))}
    </>
  );
}

const WrappedReactElements = compose(
  withRouter,
  connect(
    state => ({
      player: getPlayer(state),
      itemIndices: getItemIndices(state)
    })
  )
)(ReactElements);

// eslint-disable-next-line no-unused-vars
const selectZoom = async (itemIndex) => {
  // const level = Math.round(itemIndex / pageSize);
  const newIndex = (itemIndex % pageSize);

  // new TWEEN.Tween(camera.position)
  //   .to({ y: 0 }, 500)
  //   .onUpdate(({ y }) => {
  //     console.log('update', y, structure.position.y);
  //     camera.position.setY(y);
  //     render();
  //   })
  //   .start();

  // await move({ level });
  await rotate({ index: newIndex });
  // await zoom({ toDistance: getInDistance(), easing: TWEEN.Easing.Exponential.In, duration: 500 });

  // const computedStyle = window.getComputedStyle(domNode, null);
  // const matrix3d = computedStyle.getPropertyValue('transform');
  // const values = matrix3d
  //   .replace('matrix3d(', '').replace(')', '')
  //   .split(', ');
  // const index = 14;
  // const startVal = parseInt(values[index]);
  // const duration = 500;
  // console.log(startVal);

  // matrix3d(1, 0, 0, 0, 0, -1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
  // const start =
  // new TWEEN.Tween({ zoom: startVal })
  //   .to({ zoom: 0 }, duration)
  //   .onUpdate(({ zoom }) => {
  //     values[index] = zoom.toString();
  //     const newMatrix3d = `matrix3d(${values.join(', ')})`;
  //     domNode.style.transform = `translate(-50%, -50% ${newMatrix3d}`;
  //   })
  //   .start();
  // console.log(domNode.style);
}

const createItemRenderer = (itemIndex) => {
  const domNode = createDomNode({ width: itemWidth, height: itemHeight }, itemIndex);

  const itemRenderer = new CSS3DObject(domNode);
  itemRenderer.position.x = Math.random() * 4000 - 2000;
  itemRenderer.position.y = Math.random() * 4000 - 2000;
  itemRenderer.position.z = Math.random() * 4000 - 2000;
  // itemRenderer.index = itemIndex; // TODO Temporary

  // let frontFacing = true;
  // const selectSpin = (event) => {
  //   domNode.removeEventListener('click', selectSpin);
  //   frontFacing = !frontFacing;
  //   const target = frontFacing ? (itemRenderer.rotation.y + Math.PI) : (itemRenderer.rotation.y - Math.PI);
  //   new TWEEN.Tween({ rotation: itemRenderer.rotation.y })
  //     .to({ rotation: target }, 500)
  //     .onUpdate(({ rotation }) => {
  //       itemRenderer.rotation.y = rotation;
  //       render();
  //     })
  //     .easing(TWEEN.Easing.Exponential.InOut)
  //     .onComplete(() => domNode.addEventListener('click', selectSpin))
  //     .start();
  // };
  return itemRenderer;
};

const render3D = () => {
  return null;
}

let controlsSetUp = false;
let root;
// eslint-disable-next-line no-unused-vars
let swipeListener;
let onSwipe;
const NakedControls = ({ onShift, search, onSetSearch }) => {
  const shiftUp = () => {
    shiftTarget = currentShift + 1;
    onShift();
  }

  const shiftDown = () => {
    shiftTarget = currentShift - 1;
    onShift()
  }

  // Make it externally visible to remove the event listener.
  onSwipe = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { detail: { directions: { top, bottom } }} = event;
    if (top) {
      shiftUp();
    } else if (bottom) {
      shiftDown();
    }
  }

  let searchTimeout;
  const inputRef = useRef(null);
  const onSubmit = (event) => {
    event.preventDefault();
    sort();
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => onSetSearch({ filter }), 1100);
    inputRef.current.blur();
  };

  const onClear = (event) => {
    event.preventDefault();
    sort();
    setFilter('');
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => onSetSearch({ filter: '' }), 1100);
    inputRef.current.blur();
  }

  if (!controlsSetUp) {
    root = document.querySelector('body');
    swipeListener = SwipeListener(root);
    root.addEventListener('swipe', onSwipe);

    controlsSetUp = true;
  }

  const [filter, setFilter] = useState('');

  console.log('currentShift', currentShift, 'maxShift', getMaxShift());
  return (
    <>
      <div id="controls">
        <button id="ctrl-shift-down" onClick={shiftDown}><UpIcon color="#793867" size={32} /></button>
        <form onSubmit={onSubmit}>
          <motion.input
            type="text"
            ref={inputRef}
            placeholder="Find a species"
            value={filter}
            onChange={({ target: { value } }) => {
              setFilter(value);
            }}
          />

          <button className="clear-btn" type="button" onClick={onClear} disabled={!filter}>
            {filter && <ClearIcon color="#ffffff" size={14} />}
          </button>
          <button className="search-btn" type="submit" disabled={!filter}>
            <SearchIcon color="#ffffff" size={18} />
          </button>
        </form>
        <button id="ctrl-shift-up" onClick={shiftUp} disabled={currentLevel === 0}><DownIcon color="#793867" size={32} /></button>
        {/*<button id="in" onClick={zoomIn}>IN</button>*/}
        {/*<button id="out" onClick={zoomOut}>OUT</button>*/}
        {/*<button id="up" onClick={up}>UP</button>*/}
        {/*<button id="down" onClick={down}>DOWN</button>*/}
        {/*<button id="prev" onClick={prev}>PREV</button>*/}
        {/*<button id="next" onClick={next}>NEXT</button>*/}
        {/*<button id="sort" onClick={doSort}>SORT</button>*/}
      </div>
    </>
  );
}

const Controls = connect(
  state => ({ search: getSearch(state) }),
  { onSetSearch: setSearch }
)(NakedControls);

/**
 * Renders control buttons in the context of the 3D environment.
 */
const DebugButtons = ({ onShift }) => {
  const zoomOut = event => {
    history.push({ ...history.location, pathname: ROUTES.REGISTRY_ROOT });
  };

  const zoomIn = event => {
    zoom({ toDistance: getInDistance(), rotate: true });
  };

  const down = event => {
    move({ level: currentLevel + 1 });
  };

  const up = () => {
    move({ level: currentLevel - 1 });
  };

  const next = () => {
    rotate({ index: (currentIndex + 1) % pageSize });
  };

  const prev = () => {
    rotate({ index: ((currentIndex - 1) + pageSize) % pageSize });
  };

  const doSort = () => {
    sort();
  }

  const shiftUp = () => {
    onShift(1);
  }

  const shiftDown = () => {
    onShift(-1)
  }

  return (
    <>
      <div id="menu">
        <button id="in" onClick={zoomIn}>IN</button>
        <button id="out" onClick={zoomOut}>OUT</button>
        <button id="up" onClick={up}>UP</button>
        <button id="down" onClick={down}>DOWN</button>
        <button id="prev" onClick={prev}>PREV</button>
        <button id="next" onClick={next}>NEXT</button>
        <button id="sort" onClick={doSort}>SORT</button>
        <button id="shiftUp" onClick={shiftUp}>+</button>
        <button id="shiftDown" onClick={shiftDown}>-</button>
      </div>
      {/*<div id="menu-2">*/}
      {/*  <input type="number" value={pageSize}></input>*/}
      {/*</div>*/}
    </>
  );
}

// TODO Find out if this actually disposes of anything.
const dispose = () => {
  itemRenderers = [];
  reserveItemRenderers = [];
  animationTargets = { table: [], sphere: [], cylinder: [], helix: [], grid: [], mess: [] };
  scene.dispose();
  controls.dispose();
  root.removeEventListener('swipe', onSwipe);
}

const setUp3D = (width, height, mount) => {
  itemRenderers = [];
  reserveItemRenderers = [];
  animationTargets = { table: [], sphere: [], cylinder: [], helix: [], grid: [], mess: [], messUp: [], messDown: [] };

  const init = () => {
    camera = new THREE.PerspectiveCamera(40, width / height, 1, 10000);
    camera.position.z = 5000;
    scene = new THREE.Scene();

    renderer = new CSS3DRenderer({ doFakeBacks: false });
    renderer.setSize(width, height);
    mount.appendChild(renderer.domElement);

    structure = new THREE.Group();
    scene.add(structure);

    controls = new CustomControls(camera, renderer.domElement);
    controls.enabled = true;
    controls.enableZoom = true; // false
    controls.minDistance = getInDistance(); // outDistance
    controls.maxDistance = getOutDistance();
    controls.enableDamping = true;
    controls.enablePan = true;
    controls.enableVerticalPan = false;
    controls.screenSpacePanning = true;
    controls.minPolarAngle = Math.PI / 2;
    controls.maxPolarAngle = Math.PI / 2;
    controls.enableKeys = true;
    controls.addEventListener('change', render);
    controls.addEventListener('start', event => console.log('start', event));
    controls.addEventListener('end', event => console.log('end', event));

    let index = 0;
    for (let i = 0; i < numDisplayedPages * pageSize; i += 1) {
      const itemRenderer = createItemRenderer(index);
      itemRenderers.push(itemRenderer);
      structure.add(itemRenderer);
      index += 1;
    }

    // Create an extra page for animations.
    for (let i = 0; i < pageSize; i += 1) {
      const itemRenderer = createItemRenderer(index);
      itemRenderer.element.style.opacity = 0;
      reserveItemRenderers.push(itemRenderer);
      structure.add(itemRenderer);
      index += 1;
    }

    animationTargets.mess = createArrangement(itemRenderers, ARRANGEMENT_TYPES.MESS);
    animationTargets.messUp = createArrangement(itemRenderers, ARRANGEMENT_TYPES.MESS_UP);
    animationTargets.messDown = createArrangement(itemRenderers, ARRANGEMENT_TYPES.MESS_DOWN);
    // targets.sphere = createArrangement(itemRenderers, ARRANGEMENT_TYPES.SPHERE);
    animationTargets.helix = createArrangement(itemRenderers, ARRANGEMENT_TYPES.HELIX, { numPerLevel: pageSize, dropPerItem, radius });
    // targets.cylinder = createArrangement(itemRenderers, ARRANGEMENT_TYPES.CYLINDER, { numPerLevel: pageSize, dropPerLevel, radius });
    animationTargets.grid = createArrangement(itemRenderers, ARRANGEMENT_TYPES.GRID);

    transform({ objects: itemRenderers, targets: animationTargets.helix, duration: 2000, delay: 1000 });
    move({ level: 1 });
    // zoom({ fromDistance: controls.minDistance, toDistance: inDistance, delay: 4000 });
  }

  init();
  animate();

  return { camera, renderer, render3D, dispose, ReactElements: WrappedReactElements };
}

export { setUp3D, DebugButtons, Controls };
