export const getAuth = ({ auth }) => auth;
export const getUI = ({ ui }) => ui;
export const getPlayer = ({ player }) => player;
export const getRegistry = ({ registry }) => registry;
export const getPlayerLeaders = ({ playerLeaders }) => playerLeaders;
export const getPlayerSpecies = ({ playerSpecies }) => playerSpecies;
export const getInventory = ({ inventory }) => inventory;
export const getClaim = ({ claim }) => claim;
export const getModal = ({ modal }) => modal;
export const getEnv = ({ env }) => env;
export const getSignUpData = ({ signUpData }) => signUpData;
export const getSearch = ({ search }) => search;
export const getFirebase = ({ firebase }) => firebase;
