import React from 'react';
import { connect } from 'react-redux';
import { TUTORIAL_STAGES } from '../constants/tutorialStages';
import {
  getGameIsComplete,
  getPlayerInfo,
  getPrimaryCurrency,
  getTutorial
} from '../store/selectors/spaceRaceSelectors';
import { srSetModal } from '../store/actions/spaceRaceActions';
import * as MODALS from '../constants/modals';

import './tutorial.scss';

// TODO This should not decide when the game is complete.
const Tutorial = ({ tutorial, primaryCurrency, techProgress, gameIsComplete, playerInfo, onSetModal }) => {
  const { leader: { title = 'your worship' } = {} } = playerInfo;
  return (
    <>
      {(Object.values(TUTORIAL_STAGES).includes(tutorial.stage)) && (
        <div className="float-dialog" id="instructions">
          {tutorial.stage === TUTORIAL_STAGES.CLICK && (
            <>Tap your planet to launch a fearless astronaut! ({15 - primaryCurrency} to go!)</>
          )}
          {tutorial.stage === TUTORIAL_STAGES.BUILD && (
            <>Good news, {title}! We've invented a structure for automatic launching!</>
          )}
          {tutorial.stage === TUTORIAL_STAGES.SUCCESS && (
            <>Look at it go, {title}! You can build as many as we can afford!</>
          )}
          {tutorial.stage === TUTORIAL_STAGES.RESEARCH && (
            <>Of course, if we <em>really</em> want to escape, we'll need some tech.</>
          )}
          {tutorial.stage === TUTORIAL_STAGES.RESEARCH2 && (
            <>Wondefully done, {title}! I cannot wait to see what the next century brings us!</>
          )}
        </div>
      )}
      {gameIsComplete && (
        <div className="float-dialog" id="final-dialog">
          <p>{title}! Our Dyson Sphere has attracted some sort of communication! Shall we respond?</p>
          <button
            className="action-button"
            onClick={() => {
              onSetModal({ type: MODALS.ENDGAME })
            }}
          >
            First Contact
          </button>
        </div>
      )}
    </>
  );
};

export default connect(
  state => ({
    tutorial: getTutorial(state),
    primaryCurrency: getPrimaryCurrency(state),
    gameIsComplete: getGameIsComplete(state),
    playerInfo: getPlayerInfo(state)
  }),
  {
    onSetModal: srSetModal
  }
)(Tutorial);
