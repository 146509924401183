import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import Sidebar from 'react-sidebar';

import Navigation from '../Navigation/Navigation';
import SignUp from '../Session/SignUp';
import SignInPage from '../Session/SignIn';
import PasswordForget from '../Account/PasswordForget';
import HomePage from '../Home/Home';
import AccountPage from '../Account/Account';
import Privacy from '../Privacy/Privacy';
import RestrictedRoute from '../Navigation/RestrictedRoute';
import Header from '../Navigation/Header';
import * as ROUTES from '../../constants/routes';
import { userIsAuthenticated, userIsUnauthenticated } from '../Session/userConditions';
import { getIsMenuOpen } from '../../store/selectors/uiSelectors';
import { setIsMenuOpen } from '../../store/actions/uiActions';
import Registry from '../Registry/Registry';
import Claim from '../Claim/Claim';
import IDCard from '../Session/IDCard';
import GeneralModal from '../Modals/GeneralModal';
import NewsModal from '../Modals/NewsModal';
import Store from '../Store/Store';
import SpaceRace from '../../features/SpaceRace';
import Intro from '../Intro/Intro';
import NewGameModal from '../Modals/NewGameModal';

import './app.scss';

const App = (props) => {
  const { isMenuOpen, onSetIsMenuOpen } = props;

  return (
    <Sidebar
      rootClassName="full-height"
      sidebar={<Navigation />}
      open={isMenuOpen}
      onSetOpen={onSetIsMenuOpen}
      sidebarClassName="registry-sidebar"
      contentClassName="registry-sidebar-content"
      overlayClassName="registry-sidebar-overlay"
    >
        <Header onMenuClick={() => onSetIsMenuOpen(!isMenuOpen)}/>
        <div className="app">
          <IDCard />
          {/* TODO Need a modal manager */}
          <GeneralModal />
          <NewsModal />
          <NewGameModal />
          <Switch>
            <RestrictedRoute path={ROUTES.INTRO} exact component={Intro} redirect={ROUTES.HOME}/>
            <RestrictedRoute path={ROUTES.SIGN_UP} component={SignUp} />
            <RestrictedRoute path={ROUTES.SIGN_IN} component={SignInPage} requirement={userIsUnauthenticated} redirect={ROUTES.HOME} />
            <RestrictedRoute path={ROUTES.PASSWORD_FORGET} component={PasswordForget} requirement={userIsUnauthenticated} redirect={ROUTES.HOME} />
            <RestrictedRoute path={ROUTES.HOME} requirement={userIsAuthenticated} redirect={ROUTES.START} component={HomePage} />
            <RestrictedRoute path={ROUTES.ACCOUNT} component={AccountPage} requirement={userIsAuthenticated} />
            <RestrictedRoute path={ROUTES.REGISTRY} component={Registry} />
            <RestrictedRoute path={ROUTES.REGISTRY_ROOT} component={Registry} />
            <RestrictedRoute path={ROUTES.CLAIM} component={Claim} />
            <RestrictedRoute path={ROUTES.PRIVACY} component={Privacy} />
            <RestrictedRoute path={ROUTES.STORE} component={Store} />
            <RestrictedRoute path={ROUTES.SPACE_RACE} component={SpaceRace} />
            <RestrictedRoute render={() => (<Redirect to={ROUTES.START} />)} />
          </Switch>
        </div>
      {/*</div>*/}
    </Sidebar>
  );
}

App.propTypes = {};
App.defaultProps = {};

export default connect(
  state => ({ isMenuOpen: getIsMenuOpen(state) }),
  { onSetIsMenuOpen: setIsMenuOpen }
)(App);
