import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ image: { name, x1Url }, path, className }) => (
  <img
    className={`pxw-image ${className}`}
    alt={name}
    src={`${path}${x1Url}`}
  />
);

Image.propTypes = {
  image: PropTypes.object.isRequired,
  path: PropTypes.string,
  className: PropTypes.string,
};
Image.defaultProps = {
  path: '',
  className: '',
};

export default Image;
