
Object.defineProperty(exports, "__esModule", { value: true });
var THREE = require("three");
window.THREE = THREE; // Dumb. Why do I have to do this?
require("three/examples/js/loaders/GLTFLoader");
var SHIP_TYPE;
(function (SHIP_TYPE) {
    SHIP_TYPE["Cube"] = "models/cube";
    SHIP_TYPE["Transcruiser"] = "/models/transcruiser";
})(SHIP_TYPE = exports.SHIP_TYPE || (exports.SHIP_TYPE = {}));
var Ship = /** @class */ (function () {
    function Ship() {
    }
    Ship.prototype.LoadShip = function (src) {
        // @ts-ignore
        var loader = new THREE.GLTFLoader();
        return new Promise(function (resolve, reject) {
            loader.load(src + "/model.glb", function (model) { return resolve(model.scene); }, function (_) { }, reject);
        });
    };
    return Ship;
}());
exports.Ship = Ship;
